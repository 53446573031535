<template>
    <div class="bg-gray-100 flex flex-col justify-start items-center min-h-screen">
      <div class="bg-white container mx-auto max-w-lg p-6 border rounded-lg shadow-md mt-10 text-center">
        <h1 class="text-3xl font-semibold mb-4 text-gray-800">Senlik Buddy</h1>
        <p class="mb-1 text-gray-700">Built by machine learning,</p>
        <p class="mb-1 text-gray-700">Improved by your feedbacks.</p>
        <p class="mb-4 text-gray-700">Sign up with your Google account, <br> verify by your METU EDU email.</p>
        <img src="@/assets/Logos/logoSenlikBuddy.png" alt="Date Buddy Logo" class="segment-image mx-auto">
  
        <!-- Google Sign-In Button Container -->
        <div class="google-btn-container">
          <button id="g_id_signin" class="button w-full text-white font-bold py-2 px-4 rounded">
            Register
          </button>
        </div>
      
      </div>
  
      <div class="bg-white container mx-auto max-w-lg p-6 border rounded-lg shadow-md mt-10 text-center">
        <h1 class="text-lg font-semibold mb-4 text-gray-700">"Dating is not competition but experience."</h1>
        <h1 class="text-lg font-semibold mb-4 text-gray-300">34% of our user did not use dating app.(2023 January)</h1>
        <p class="mb-1 text-gray-700">We have been working since 2021 and got over 2600 applications. And now, we are starting again with our new website. We are so proud to have your trust!</p>
      </div>
    </div>
  </template>
  
  <style scoped>
  body {
    background-color: #f3f4f6 !important;
  }
  
  .container {
    text-align: center;
  }
  
  .button {
    display: inline-block;
    text-align: center;
    width: auto;
  }
  
  .google-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .segment-image {
    width: 5rem;
    height: 5rem;
    margin-top: 20px;
  }
  </style>
  
  <script>
  import { onMounted, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import config from '@/config';
  
  export default {
    setup() {
      const router = useRouter();
      const store = useStore();
      const backendUrl = ref(config.apiBaseUrl);
  
      onMounted(() => {
        function initializeGoogleSignIn() {
          if (window.google) {
            window.google.accounts.id.initialize({
              client_id: config.googleClientId,
              callback: handleCredentialResponse,
            });
  
            window.google.accounts.id.renderButton(
              document.getElementById("g_id_signin"),
              { theme: "outline", size: "large" }
            );
          } else {
            setTimeout(initializeGoogleSignIn, 100);
          }
        }
  
        initializeGoogleSignIn();
      });
  
      const handleCredentialResponse = async (response) => {
        const tokenBearer = `Bearer ${response.credential}`;
  
        try {
          const result = await axios.post(`${backendUrl.value}/register1Consent/`, {}, {
            headers: { 'Authorization': tokenBearer }
          });
  
          if (result.status === 200) {
            store.dispatch('login', { token: result.data.token, termsofservice_status: result.data.termsofservice_status });
  
            if (result.data.termsofservice_status === "needed") {
              router.push('/TermsOfService');
            } else {
              router.push('/Welcome_Router_Main');
            }
          } else {
            console.error('Authentication failed');
          }
        } catch (error) {
          console.error('Error during Google authentication:', error);
          if (error.response && error.response.status === 401) {
            alert('Unauthorized access attempt.');
          }
        }
      };
  
      return {
        handleCredentialResponse,
      };
    },
  };
  </script>
  