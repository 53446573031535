<template>
    <div class="bg-white overflow-hidden shadow rounded-lg border mt-5 mx-auto max-w-md">
      <div class="px-4 py-5 sm:px-6 text-center">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Check Your Email</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500 mx-auto">You can check your email to login.</p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-6 text-center">
        <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox" 
           class="px-4 py-2 bg-custom-purple text-white rounded hover:bg-gray-700" 
           target="_blank" 
           rel="noopener noreferrer">
          Go to Gmail
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'RegisterCXGoogle',
    
  };
  </script>
  
  <style scoped>
  /* Add component-specific styles here */
  .bg-custom-purple {
    background-color: #df790c; /* Example custom purple, replace or remove as needed */
  }
  </style>
