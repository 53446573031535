<template>
  <div class="flex bg-gray-100">
  
    <!-- Sidebar -->
    <Sidebar/>

    <div class="card h-full content-grid">
      <h1 class="section-title">Current Status</h1>
      <div v-if="profile" class="info-container">
        <h1 class="title text-center font-bold">Social Media</h1>
        <div class="info-grid">
            <!-- Instagram -->
            <div v-if="profile.ex_instagram && profile.ex_instagram.length">
                <span><strong>Instagram:</strong> {{ profile.ex_instagram.join(', ') }}</span>
            </div>
            
            <!-- Twitter -->
            <div v-if="profile.ex_twitter && profile.ex_twitter.length">
                <span><strong>Twitter:</strong> {{ profile.ex_twitter.join(', ') }}</span>
            </div>
            
            <!-- snapchat -->
            <div v-if="profile.ex_snapchat && profile.ex_snapchat.length">
                <span><strong>snapchat:</strong> {{ profile.ex_snapchat.join(', ') }}</span>
            </div>

            <div v-if="profile.ex_email && profile.ex_email.length">
                <span><strong>Email:</strong> {{ profile.ex_email.join(', ') }}</span>
            </div>
            
            <!-- Show 'Missing' if no snapchat -->
            <div v-else class="loading-container">
              <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span class="sr-only">Loading...</span>
            </div>  

        </div>
      </div>
    </div>

    <div class="card h-full content-grid">
      <h1 class="section-title">Update Social Ex</h1>
      <div class="input-group mb-4">
          <label for="platform" class="block text-gray-700 text-sm font-bold mb-2">Select Discard Social Media to Update:</label>
          <select id="platform" v-model="selectedPlatform" class="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option value="ex_instagram">Ex Instagram</option>
              <option value="ex_twitter">Ex Twitter</option>
              <option value="ex_snapchat">Ex Snapchat</option>
              <option value="ex_email">Ex Email</option> 
          </select>
      </div>
      <div class="input-group">
          <label :for="selectedPlatform" class="block text-gray-700 text-sm font-bold mb-2">Enter username to discard:</label>
          <input type="text" :id="selectedPlatform" v-model="NewAttribute" placeholder="Enter Your New Value" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
          <div class="flex justify-center">
          <button class="btn mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="updateNewAttribute">Update</button>
        </div>
      </div>

      <div class="input-group">
        <label for="itemToDelete" class="block text-gray-700 text-sm font-bold mb-2">Select username to delete:</label>
        <select id="itemToDelete" v-model="itemToDelete" class="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option v-for="item in profile[selectedPlatform]" :key="item" :value="item">{{ item }}</option>
        </select>
        <div class="flex justify-center">
        <button class="btn mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="deleteSocialMediaItem">Delete</button>
      </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; /* Adjust height as needed */
  }

@media (max-width: 768px) {
.flex {
  flex-direction: column;
  align-items: center; /* Center child elements horizontally */
}
.card, .content-grid {
  max-width: 100%;
  margin: 20px;
}
.info-grid, .input-group {
  display: block;
}
}

.section-title {
font-weight: 700;
font-size: 2.5rem;
text-align: center;
margin-bottom: 1rem;
}

.content-grid {
display: grid;
grid-template-columns: 1fr; /* Keep it single column for stacking */
gap: 0rem;
width: 100%;
max-width: 500px; /* Control the max width of the content grid */
margin: 0 auto; /* Center the grid horizontally */
}

.card {
background-color: white;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
padding: 20px;
max-width: 400px;
margin: 50px auto;
}
.input-group {
margin-bottom: 20px;
}
.input-group label {
display: block;
margin-bottom: 5px;
font-weight: bold;
}
.input-group input, .input-group select {
width: 100%;
padding: 8px;
border: 1px solid #ddd;
border-radius: 4px;
}
.btn {
display: block;
width: 100%;
padding: 10px;
background-color: #dd7c13;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
transition: background-color 0.3s;
}
.btn:hover {
background-color: #854709;
}
</style>



<style scoped>

.section-title {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content-grid {
display: grid;
grid-template-columns: 1fr; /* Keep it single column for stacking */
gap: 0rem;
width: 100%;
max-width: 500px; /* Control the max width of the content grid */
margin: 0 auto; /* Center the grid horizontally */
}


.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 50px auto;
}
.input-group {
  margin-bottom: 20px;
}
.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.btn {
  display: block;
  width: 30%;
  padding: 10px;
  background-color: #dd7c13;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn:hover {
  background-color: #854709;
}
</style>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import Sidebar from '@/components/Default/Sidebar.vue';

export default {
  components: {
    Sidebar
  },
  setup() {
    const selectedPlatform = ref('ex_instagram');
    const NewAttribute = ref('');
    const itemToDelete = ref('');
    const backendUrl = ref(config.apiBaseUrl);
    const profile = ref([]);

    const updateNewAttribute = async () => {
      try {
        const url = `${backendUrl.value}/update_profile_social_ex/`;
        const token = localStorage.getItem('token');
        const headers = { 'Authorization': `Bearer ${token}` };
        const payload = { [selectedPlatform.value]: [NewAttribute.value] };
        const response = await axios.put(url, payload, { headers: headers });
        alert(response.data.message);
      } catch (error) {
        console.error('Failed to update NewAttribute:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to update NewAttribute'));
      }
    };

    const deleteSocialMediaItem = async () => {
      try {
        const url = `${backendUrl.value}/delete_social_media_item_ex/`;
        const token = localStorage.getItem('token');
        const headers = { 'Authorization': `Bearer ${token}` };
        const payload = { platform: selectedPlatform.value, item: itemToDelete.value };
        const response = await axios.delete(url, { data: payload, headers: headers });
        alert(response.data.message);
      } catch (error) {
        console.error('Failed to delete social media item:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to delete social media item'));
      }
    };

    onMounted(async () => {
      try {
        const response = await axios.get(`${backendUrl.value}/profile_displayer_social_ex/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        profile.value = response.data;
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    });

    return { profile, selectedPlatform, NewAttribute, itemToDelete, updateNewAttribute, deleteSocialMediaItem };
  },
};
</script>
