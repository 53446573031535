<template>
  <div class="flex bg-gray-100">
    <!-- Sidebar -->
    <Sidebar class="hide-item-sidebar"/>
    
    <!-- Main Content -->
    <div class="flex flex-col justify-start items-center w-full min-h-screen mt-10 bg-gray-100">
      <div class="card h-full content-grid"> 
        <h1 class="section-title">Profile Settings</h1>

        <div v-if="profile" class="info-container mt-5">
          <h1 class="title text-center font-bold">User Profile</h1>
          <div class="info-grid">
              <p v-if="profile.full_Name_DB"><strong>Full Name:</strong> {{ profile.full_Name_DB }}</p>
              <p v-if="profile.email_DB"><strong>Email:</strong> {{ profile.email_DB }}</p>
              <p v-if="profile.edu_email_DB"><strong>EDU Email:</strong> {{ profile.edu_email_DB }}</p>
              <p v-if="profile && profile.email_DB"><strong>University:</strong> {{ formattedUniversity }}</p>
              <p v-if="profile && profile.email_DB"><strong>City:</strong> {{ formattedCity }}</p>

              <div v-if="profile.status_DB" class="status-container">
                  <p><strong>Status:</strong> {{ profile.status_DB }}</p>
                  <button @click="toggleStatus" class="button toggle-status">
                      Toggle Status
                  </button>
              </div>
          </div>
          <div class="edu-email-section">
              <p v-if="profile.edu_email"><strong>Edu Email:</strong> {{ profile.edu_email }}</p>
              <button v-if="profile.edu_email === 'Missing'" @click="verifyEduEmail" class="button verify-email">
                  Verify Edu Email
              </button>
          </div>
        </div>

        <div v-else class="loading-container">
          <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>

        <h1 class="title text-center font-bold hide-item">You Can Update</h1>
        <div class="buddy-container-profile hide-item">
          <router-link to="/UpdateProfileBasic" class="buddy-item">
              <img src="@/assets/Profiles/nameCard.png" alt="Name Card Icon" class="icon-container">
              <h2 class="segment-logo-title text-center text-gray-700 font-bold">Basic</h2>
          </router-link>
          <router-link to="/UpdateProfileSocial" class="buddy-item">
              <img src="@/assets/Profiles/social.png" alt="Social Icon" class="icon-container">
              <h2 class="segment-logo-title text-center text-gray-700 font-bold">Social</h2>
          </router-link>
          <router-link to="/UpdateProfileSocialEx" class="buddy-item">
              <img src="@/assets/Profiles/shield.png" alt="Shield Icon" class="icon-container">
              <h2 class="segment-logo-title text-center text-gray-700 font-bold">Ex/Friendzone</h2>
          </router-link>
          <router-link to="/UpdateProfileUniversity" class="buddy-item">
              <img src="@/assets/Profiles/university.png" alt="University Icon" class="icon-container">
              <h2 class="segment-logo-title text-center text-gray-700 font-bold">University</h2>
          </router-link>
          <router-link to="/UpdateProfileCity" class="buddy-item">
              <img src="@/assets/Profiles/city.png" alt="City Icon" class="icon-container">
              <h2 class="segment-logo-title text-center text-gray-700 font-bold">Country/City</h2>
          </router-link>
        </div>

        <div class="buddy-container-profile-bottom hide-item mt-5">
          <router-link to="/EduVerify" class="buddy-item-bottom">
              <img src="@/assets/Profiles/verification.png" alt="Name Card Icon" class="icon-container-bottom-small">
              <h2 class="segment-logo-title-bottom text-center text-gray-700 font-bold">Student Verification</h2>
          </router-link>


          <router-link to="/DeleteProfile" class="buddy-item-bottom">
              <img src="@/assets/Profiles/delete.png" alt="Name Card Icon" class="icon-container-bottom-small">
              <h2 class="segment-logo-title-bottom text-center text-gray-700 font-bold">Delete</h2>
          </router-link>

          <router-link to="/managepassword" class="buddy-item-bottom">
              <img src="@/assets/Profiles/changePassword.png" alt="Name Card Icon" class="icon-container-bottom-small">
              <h2 class="segment-logo-title-bottom text-center text-gray-700 font-bold">Manage Password</h2>
          </router-link>


          <router-link to="/GraduateVerify" class="buddy-item-bottom">
              <img src="@/assets/Profiles/verification.png" alt="Name Card Icon" class="icon-container-bottom-small">
              <h2 class="segment-logo-title-bottom text-center text-gray-700 font-bold">Graduate Verification</h2>
          </router-link>

        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

.hide-item-sidebar {
  display: none;
} 

/* Global styles (e.g., in main.css) */
.icon-container img {
  width: 100px;
  height: auto;
}
.icon-container-bottom img {
  width: 100px;
  height: auto;
}
.icon-container-bottom-small {
  width: 40px;
  height: auto;
}
.segment-logo-title-bottom {
  margin-top: 5px;
  font-size: 13px;
} 


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.buddy-container-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.buddy-container-profile-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.buddy-item-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 600px) {

.hide-item-sidebar {
  display: block;
} 


  .hide-item {
    display: none;
  }
  .buddy-container-profile {
    flex-direction: column;
  }
  .buddy-container-profile-bottom {
    flex-direction: column;
  }
}
@media (max-width: 768px) {

.hide-item-sidebar {
  display: block;
} 

  .icon-container {
    width: 60px;
    height: auto;
  }
  .segment-logo-title {
    font-size: 12px;
  }
  .buddy-item {
    padding: 10px;
  }
  .icon-container-bottom-small {
    width: 50px;
    height: auto;
  }
  .buddy-item-bottom {
    padding: 10px;
  }
}
</style>

<style scoped>
.hide-item-sidebar {
  display: none;
} 

.card {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.flex.flex-col.justify-start.items-center.w-full.min-h-screen {
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  height: 100%;
}

.section-title {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 10px;
}

.social-media-container {
  background-color: rgb(246, 246, 246);
  padding: 1rem;
  border-radius: 0.75rem;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.title, .info-grid, .edu-email-section, .status-container {
  margin-bottom: 0.5rem;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.button {
  padding: 0.5rem 1rem;
  border: none;
  font-size: 0.875rem;
  font-weight: medium;
  border-radius: 0.375rem;
  color: white;
  cursor: pointer;
  background-color: #47505e;
}

.button:hover {
  background-color: #ed850f;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.status-container p {
  margin: 0;
  flex-grow: 1;
}

@media (max-width: 768px) {

.hide-item-sidebar {
  display: block;
} 

  .card {
    padding: 10px;
    width: 90%;
    margin: 10px auto;
  }
  .section-title {
    font-size: 1.25rem;
  }
  .social-media-container, .info-grid, .status-container, .button {
    /* Additional adjustments can be made here if needed */
  }
}
</style>




<script>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import Sidebar from '@/components/Default/Sidebar.vue';


export default {
  components: {
    Sidebar
  },
  setup() {
    const profile = ref(null);
    const backendUrl = ref(config.apiBaseUrl);
    const isLoading = ref(true); // manages loading state of the component

    console.log("Environment VUE BACKEND URL:", backendUrl.value);

    onMounted(async () => {
      try {
        const response = await axios.get(`${backendUrl.value}/profile_displayer/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        profile.value = response.data;
        isLoading.value = false;  // Set loading state to false after data is loaded
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    });

    const toggleStatus = async () => {
      try {
        const response = await axios.put(`${backendUrl.value}/update_status/`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (response.data.message === "User updated successfully") {
          profile.value.status_DB = response.data.new_status;
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

      const formattedUniversity = computed(() => {
        if (profile.value && profile.value.university_DB) {
          if (Array.isArray(profile.value.university_DB)) {
            return profile.value.university_DB.map(uni => {
              if (uni.includes('_')) {
                const parts = uni.split('_');
                return `${parts[0]} (${parts[1]})`;
              }
              return uni;
            }).join(', ');
          } else if (profile.value.university_DB.includes('_')) {
            const parts = profile.value.university_DB.split('_');
            return `${parts[0]} (${parts[1]})`;
          }
          return profile.value.university_DB; // In case it's already a string or another format
        }
        return 'Not Available'; // Default message if university_DB is missing
      });

      const formattedCity = computed(() => {
        if (profile.value && profile.value.current_city_DB) {
          if (Array.isArray(profile.value.current_city_DB)) {
            return profile.value.current_city_DB.map(uni => {
              if (uni.includes('_')) {
                const parts = uni.split('_');
                return `${parts[0]} (${parts[1]})`;
              }
              return uni;
            }).join(', ');
          } else if (profile.value.current_city_DB.includes('_')) {
            const parts = profile.value.current_city_DB.split('_');
            return `${parts[0]} (${parts[1]})`;
          }
          return profile.value.current_city_DB; // In case it's already a string or another format
        }
        return 'Not Available'; // Default message if current_city_DB is missing
      });


    return { profile, toggleStatus, formattedUniversity, formattedCity };
  },
};
</script>
