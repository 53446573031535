<template>
  <div class="card-game21">
    <h2 class="text-l font-bold mb-1">Your Answers</h2>
    <div v-for="(question, index) in userQuestions" :key="index">
      <p class="text-gray-800">{{ question.question }}</p>
      <div class="flex justify-center">
        <button
          :disabled="isComparisonDisabled || areAnswerButtonsDisabled"
          :class="getButtonClass(question.userAnswer, 'True', comparisonResults[`current_person_${index + 1}`])"
          @click="setUserAnswer(index, 'True')">
          <img :src="getIconSrc(question.userAnswer, 'True', comparisonResults[`current_person_${index + 1}`])" alt="True Icon" class="segment-image-answer">
        </button>
        <button
          :disabled="isComparisonDisabled || areAnswerButtonsDisabled"
          :class="getButtonClass(question.userAnswer, 'Lie', comparisonResults[`current_person_${index + 1}`])"
          @click="setUserAnswer(index, 'Lie')">
          <img :src="getIconSrc(question.userAnswer, 'Lie', comparisonResults[`current_person_${index + 1}`])" alt="Lie Icon" class="segment-image-answer">
        </button>
      </div>
    </div>
    <div class="flex justify-center gap-3 mb-3 mt-2">
      <div class="flex justify-center gap-5 mt-1">
        <img
          :src="isSubmitLocked ? require('@/assets/Game21/lock.png') : require('@/assets/Game21/submit.png')"
          alt="Submit"
          class="segment-image-submit"
          :class="{ 'disabled-submit': isSubmitLocked }"
          @click="handleSubmit"
          :style="{ cursor: isSubmitLocked ? 'not-allowed' : 'pointer' }"
        >
      </div>
      <div class="flex justify-center gap-5 mt-1">
        <img
          src="@/assets/Game21/eyes.png"
          alt="Get Results"
          class="segment-image-submit"
          :class="{ 'disabled-submit': isGetResultsDisabled }"
          @click="handleFetchComparison"
          :style="{ cursor: isGetResultsDisabled ? 'not-allowed' : 'pointer' }"
        >
      </div>
      <div v-if="isGetResultsDisabled" class="countdown-game21">
        Get Results button will be enabled in {{ getResultsCountdown }} seconds
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userQuestions', 'comparisonResults', 'isUserButtonDisabled', 'isSubmitLocked', 'areAnswerButtonsDisabled', 'countdown', 'isComparisonDisabled', 'isGetResultsDisabled', 'getResultsCountdown'],
  methods: {
    setUserAnswer(index, answer) {
      if (!this.areAnswerButtonsDisabled) {
        this.$emit('set-user-answer', index, answer);
      }
    },
    handleSubmit() {
      if (this.isSubmitLocked) return;
      this.$emit('submit-user-answers');
      this.lockSubmitButton();
      this.disableAnswerButtons();
    },
    handleFetchComparison() {
      this.$emit('fetch-comparison');
    },
    lockSubmitButton() {
      this.$emit('lock-submit'); // Emit event to lock the submit button
    },
    disableAnswerButtons() {
      this.$emit('disable-answer-buttons'); // Emit event to disable answer buttons
    },
    getButtonClass(userAnswer, answerType, comparisonResult) {
      if (userAnswer === answerType) {
        if (comparisonResult !== undefined) {
          if (comparisonResult && answerType === 'True') {
            return 'correct-true';
          } else if (!comparisonResult && answerType === 'True') {
            return 'wrong-false';
          } else if (comparisonResult && answerType === 'Lie') {
            return 'correct-false';
          } else if (!comparisonResult && answerType === 'Lie') {
            return 'wrong-true';
          }
        } else {
          return 'selected';
        }
      }
      return '';
    },
    getIconSrc(userAnswer, answerType, comparisonResult) {
      if (userAnswer === answerType) {
        if (comparisonResult !== undefined) {
          if (comparisonResult && answerType === 'True') {
            return require('@/assets/Game21/trueGreen.png');
          } else if (!comparisonResult && answerType === 'True') {
            return require('@/assets/Game21/trueRed.png');
          } else if (comparisonResult && answerType === 'Lie') {
            return require('@/assets/Game21/wrongGreen.png');
          } else if (!comparisonResult && answerType === 'Lie') {
            return require('@/assets/Game21/wrongRed.png');
          }
        } else {
          return answerType === 'True' ? require('@/assets/Game21/trueOrange.png') : require('@/assets/Game21/wrongOrange.png');
        }
      } else {
        return answerType === 'True' ? require('@/assets/Game21/trueBlack.png') : require('@/assets/Game21/wrongBlack.png');
      }
    }
  }
};
</script>

<style scoped>
.segment-image-answer {
  width: 2.5rem;
  height: 2.5rem;
}

.segment-image-submit {
  width: 3rem;
  height: 3rem;
  transition: transform 0.2s;
}

.segment-image-submit:hover {
  transform: translateY(-5px);
}

.disabled-submit {
  opacity: 0.5;
  pointer-events: none;
}

.countdown-game21 {
  text-align: center;
  margin-top: 1rem;
}
</style>
