<template>
  <div class="parent-container">
    <div class="icebreaker-container">
      <div class="text-center text-gray-800 mb-1 text-xl" v-if="currentQuestion">{{ currentQuestion }}</div>
      
      <div class="navigation-buttons">
        <button class="" @click="decreaseBarcode">
          <img src="@/assets/Icebreaker/previous.png" alt="Previous" class="segment-image-prev-next">
        </button>
        <button class="icebreaker-button-center" @click="fetchCurrentQuestion">{{ questionBarcode }}</button>
        <button class="" @click="increaseBarcode">
          <img src="@/assets/Icebreaker/next.png" alt="Next" class="segment-image-prev-next">
        </button>
      </div>
    </div>
  </div>
</template>

  
<script>
import axios from 'axios';
import config from '@/config';  // Adjust this path to correctly point to your configuration file

export default {
    name: 'Icebreaker',
    data() {
        return {
            currentQuestion: '',
            questionBarcode: '',
        };
    },
    methods: {
        async fetchCurrentQuestion() {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Authentication token not found');
                return;
            }
            try {
                const response = await axios.get(`${config.apiBaseUrl}/get_icebreaker_question/`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                this.currentQuestion = response.data.question;
                this.questionBarcode = response.data.question_barcode;
            } catch (error) {
                console.error('Error fetching the current question:', error);
            }
        },
        async increaseBarcode() {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Authentication token not found');
                return;
            }
            try {
                await axios.post(`${config.apiBaseUrl}/increase_barcode_by_1/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                this.fetchCurrentQuestion();
            } catch (error) {
                console.error('Error increasing the barcode:', error);
            }
        },
        async decreaseBarcode() {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Authentication token not found');
                return;
            }
            try {
                await axios.post(`${config.apiBaseUrl}/decrease_barcode_by_1/`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                this.fetchCurrentQuestion();
            } catch (error) {
                console.error('Error decreasing the barcode:', error);
            }
        }
    },
    mounted() {
        this.fetchCurrentQuestion();
    }
};
</script>


<style scoped>


</style>