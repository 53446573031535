<template>
    <div class="content-container bg-gray-100">
      
      <div class="main-content-area bg-gray-100">
        
        
        <div class="segment-main">
          <h1 class="section-title">Senlik Buddy</h1>
          <div class="buddy-cards-container">

            <!-- Must Survey -->
            <router-link to="/Panel_MustSurvey" class="buddy-item">
              <img src="@/assets/Logos/DateBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Meet Buddy</h2>
            </router-link>
            <!-- Date Survey -->
            <router-link to="/Panel_DateBuddy" class="buddy-item">
              <img src="@/assets/Logos/DateBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Date Buddy</h2>
            </router-link>
            <!-- Coffee Survey -->
            <router-link to="/Panel_CoffeeBuddy" class="buddy-item">
              <img src="@/assets/Logos/CoffeeBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Coffee Buddy</h2>
            </router-link>
            <!-- Study Survey -->
            <router-link to="/Panel_StudyBuddy" class="buddy-item">
              <img src="@/assets/Logos/StudyBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Study Buddy</h2>
            </router-link>

          </div>
        </div>
        
                
        <div class="segment-main">
          <h1 class="section-title">Event Buddy</h1>
          <div class="buddy-cards-container">

            <!-- METU Fest 2024 -->
            <router-link to="/Panel_METUFest2024Buddy" class="buddy-item-2">
              <img src="@/assets/Logos/EventBuddy.png" alt="Date Buddy Logo" class="segment-image-2">
              <h2 class="segment-logo-title">METU Fest 2024</h2>
            </router-link>

          </div>
        </div>

        <div class="segment-main">
          <h1 class="section-title">Fictional Buddy</h1>
          <div class="buddy-cards-container">

            <!-- Ezel Buddy -->
            <router-link to="/Panel_EzelBuddy" class="buddy-item">
              <img src="@/assets/Logos/FictionalBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Ezel</h2>
            </router-link>

            <!-- Friends -->
            <router-link to="/Panel_FriendsBuddy" class="buddy-item">
              <img src="@/assets/Logos/FictionalBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Friends</h2>
            </router-link>

            <!-- Ezel Buddy -->
            <router-link to="/Panel_LeylaIleMecnunBuddy" class="buddy-item">
              <img src="@/assets/Logos/FictionalBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Leyla İle Mecnun</h2>
            </router-link>

            <!-- Yedi Tepe İstanbul -->
            <router-link to="/Panel_YediTepeBuddy" class="buddy-item">
              <img src="@/assets/Logos/FictionalBuddy.png" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title">Yedi Tepe İstanbul</h2>
            </router-link>

          </div>
        </div>

      </div>
    </div>
  </template>
  

  <style scoped>
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column;
    }
  
    .main-content-area, .segment-main {
      width: 100%; /* Ensures full width utilization */
      padding: 20px; /* Padding for smaller screens */
      align-items: center; /* Center aligns the content */
    }
  
    .buddy-cards-container {
      justify-content: center; /* Centers the buddy cards within the container */
      gap: 1rem; /* Reduced gap between items */
    }
  
    .buddy-item, .buddy-item-2 {
      width: 100%; /* Full width for each item to better utilize space */
      max-width: 160px; /* Sets a max width for each buddy item */
      height: auto; /* Adjusts height automatically based on content */
    }
  
    .segment-image, .segment-image-2 {
      width: 50%; /* Reduces image size to fit the reduced width of buddy items */
      height: auto; /* Keeps aspect ratio of images intact */
    }
  
    .segment-logo-title {
      font-size: 0.9rem; /* Reduces font size for titles under images */
    }
  
    .section-title {
      font-size: 1.5rem; /* Adjusts section titles to be smaller */
    }
  }
  
  /* Existing styles for larger screens can remain as is */
  
  .buddy-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }
  
  .buddy-item, .buddy-item-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 9rem; /* Adjust width as necessary */
    height: 10rem; /* Adjust height as necessary */
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item:hover, .buddy-item-2:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .segment-image, .segment-image-2 {
    width: 5rem; /* Adjust image width as necessary */
    height: 5rem; /* Adjust image height as necessary */
    margin-top: 20px;
  }
  
  .segment-logo-title {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 700;
  }
  </style>


  <style scoped>
  

  html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.content-container {
  display: flex;
  flex-direction: row; /* Aligns children (sidebar and main content) in a row */
  min-height: 100vh;
  width: 100%;
}

.main-content-area {
  flex-grow: 1; /* Takes remaining space */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}


</style>
  
<style scoped>

.segment-main {
  max-width: 68rem;
  margin: 0 auto; /* Reduced margin */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the content vertically */
}

.buddy-cards-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap in new rows */
  gap: 2rem;
  justify-content: center; /* Center cards within the main content area */
}

.buddy-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 9rem;
  height: 10rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.buddy-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.segment-image {
  width: 5rem;
  height: 5rem;
  margin-top: 20px;
  
}

.segment-logo-title {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 700;
}


.section-title {
font-weight: 700;
font-size: 2.0rem;
text-align: center;
margin-bottom: 1rem;
}

</style>

<style scoped>
.buddy-item-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 11rem;
  height: 12rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.buddy-item-2:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.segment-image-2 {
  width: 7rem;
  height: 7rem;
  margin-top: 20px;
  
}
  
</style>




  <script>
  import SidebarMatch from '@/components/Default/SidebarMatch.vue'; // Update the path as necessary
  
  export default {
    components: {
      SidebarMatch
    },
    name: 'NewSegment',
    methods: {
      selectSegment(segment) {
        console.log('Navigating to:', segment);
      }
    }
  }
  </script>