<template>
  <div class="content-container">
    <Sidebar/>

    <div class="main-content-wrapper">
      <div class="main-content-area-Icebreaker">
        <div class="card h-full content-grid">
          <h1 class="section-title">Icebreaker</h1>
          <p class="mb-2 text-center text-gray-700"> Previous ve Next butonlarımızın yanında, eğer sayıya basarsanız, ikiniz de aynı soruyu görüyor olursunuz. 
          Burada husus, birinizin soru kontrolünde olması ve soruyu seçtikten sonra karşındakine sayıya tıklayabilirsin demesi. 
            Bu sayede ikiniz de senkron olabileceksiniz.
          </p>
          
          <div class="navigation-buttons">
            <button >
              <img src="@/assets/Icebreaker/previous.png" alt="Previous" class="segment-image-prev-next">
            </button>
            <button class="icebreaker-button-center">1</button>
            <button >
              <img src="@/assets/Icebreaker/next.png" alt="Next" class="segment-image-prev-next">
            </button>
          </div>
          <p class="mb-2 text-center text-gray-700"> Asenkron erişiminiz ve etkiniz var. Diyaloğu kurup senkron olmak diyaloğunuza bağlı. </p>
        </div>
      </div>



    </div>
  </div>
</template>

<style scoped>
.icebreaker-button-center:hover {
    background-color: #606069;
    transform: translateY(0px);
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh; /* Ensure it takes full viewport height */
  margin-top: 20px;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3rem;
}

.main-content-area-Icebreaker {
  width: 300px;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
}

.main-content-area-Icebreaker {
  width: 300px;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
}

.main-content-area-Icebreaker {
  width: 300px;
  height: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.section-title {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .main-content-area-Icebreaker {
    max-width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%; /* Full width on smaller screens */
    margin: 0.5rem;
  }
}
</style>

<script>
import Sidebar from '../Leftside/Sidebar.vue';

export default {
  components: {
    Sidebar
  },
  name: 'NewSegment',
  methods: {
    selectSegment(segment) {
      console.log('Navigating to:', segment);
    }
  }
}
</script>
