<template>
  <div class="flex bg-gray-100">
    <h1 class="section-title-main mt-5 ">Information</h1> 
    
    <!-- New Flex Container for horizontal arrangement -->
    <div class="flex-row-1">
      <!-- Content 2: Remember Container -->
      <div class="card h-full content-grid remember"> 
        <h1 class="section-title">Basic Tutorial</h1> 
        <p class="mb-2 text-center">There are various surveys for different match criterias. Our standard match (Meet Buddy) happens at 18:45 of 28th of each month. 
          When you get match, you can see at related Match Room at that time.</p>
        <p class="mb-2"><strong>1-)</strong> You fill the survey. (Survey Map)</p>
        <p class="mb-2"><strong>2-)</strong> Announcement time comes. (Management Map)</p>
        <p class="mb-2"><strong>3-)</strong> You go to Chat Room (Management Map)</p> 
        <p class="mb-2">If you have any other question you can use <router-link to="./Welcome_Router_Feedback" class="router-link"><strong>feedback mechanisms</strong></router-link>.</p> 
        
      </div>

      <!-- Profile Container -->
      <div class="card h-full content-grid"> 
        <h1 class="section-title">Basic Process</h1> 
        <p class="mb-2 text-left"><strong>Survey</strong>: Questions to collect your answers. </p> 
        <p class="mb-2 text-left"><strong>Survey Map</strong>: Use to find Survey </p> 

        <p class="mb-2 text-left"><strong>Management Map</strong>: Control activity status </p> 
        <p class="mb-2 text-left"><strong>Chat Room</strong>: When you get match, Chat Room will connected with your match. You can communicate with your match from that address anonnumsly</p> 
        
        <p class="font-bold mb-1">1- Fill Survey</p>
        <div class="input-group">
          <router-link to="/NewSegment" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button mb-5" @click="navigate">
              Survey Map
            </button>
          </router-link> 
        </div> 
        <p class="font-bold mb-3 mt-2">2- Annocument Time comes & Go To Chat</p>
        <div class="input-group">
          <router-link to="/Management_Map" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button" @click="navigate">
              Match Management
            </button>
          </router-link> 
        </div>
      </div>
    </div>



    <!-- New Flex Container for horizontal arrangement -->
    <div class="flex-row-2">
      <!-- Content 2: Remember Container -->
      <div class="card h-full content-grid remember"> 
        <h1 class="section-title">Profile</h1> 
        
        <div class="input-group gap-4">
          <router-link to="/ProfileDisplayer" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button" @click="navigate">
              Settings
            </button>
          </router-link> 
        </div>
        <p class="mb-2 font-bold">You can specify:</p>
          <ul class="list-disc pl-5">
              <li><p>Your city for vacation times / graduates.</p></li>
              <li><p>Your ex/friendzone people to avoid.</p></li>
          </ul>

      </div>

      <!-- Profile Container -->
      <div class="card h-full content-grid profile"> 
        <h1 class="section-title">Remember</h1> 
        
        <p class="mb-2">To get matched, you need to verify your profile by your EDU email.</p>
        <div class="input-group gap-4 mb-3">
          <router-link to="/EDUVerify" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button" @click="navigate">
              Student
            </button>
          </router-link> 
          <router-link to="/EDUVerify" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button" @click="navigate">
              Graduate
            </button>
          </router-link> 
        </div>
        <p class="text-center">Graduate Verification is not ready yet.</p>
      </div>
    </div>
    
  </div>
</template>

<style scoped>
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%; /* Ensures it takes full available width */
}

.flex-row-1 {
  display: flex;
  justify-content: center; /* Align items at the center */
  align-items: center; /* Ensures vertical alignment */
  gap: 90px; /* Set a fixed gap between the cards */
  width: 100%;
}

.flex-row-2 {
  display: flex;
  justify-content: center; /* Align items at the center */
  align-items: center; /* Ensures vertical alignment */
  gap: 90px; /* Set a fixed gap between the cards */
  width: 100%;
  margin-bottom: 80px;
}

.section-title-main {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}



.section-title {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0rem;
  width: 100%;
  max-width: 500px; /* Control the max width of content */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px 10px; /* Reduced horizontal margin to bring them closer */
  max-width: 400px; /* Control the max width of content */
  
}

.card.remember {
  margin-right: 0px; /* Smaller margin on the right for 'Remember' */
}

.card.profile {
  margin-left: 0px;  /* Smaller margin on the left for 'Profile' */
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.segment-button {
  width: 150px; /* Example width, adjust as necessary */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.segment-button:hover {
  background-color: #b7560c;
  transform: translateY(-2px);
}
</style>

<style  scoped>
/* For tablets */
@media (max-width: 768px) {
  .flex-row-1, .flex-row-2 {
    flex-direction: column;
    gap: 15px;
  }
  .card {
    width: 80%;
    padding: 15px;
  }
  .section-title-main {
    font-size: 20px;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .flex-row-1, .flex-row-2 {
    flex-direction: column;
    gap: 10px;
  }
  .card {
    width: 100%;
    padding: 10px;
  }
  .section-title-main {
    font-size: 18px;
  }
}
.button {
  padding: 10px 20px;
  font-size: 16px;
  width: 100%; /* Full width in smaller screens */
}

@media (max-width: 480px) {
  .button {
    font-size: 14px;
    padding: 8px 16px;
  }
}


</style>


<script>

export default {
  components: {
  },
  name: 'NewSegment',
  methods: {
    selectSegment(segment) {
      console.log('Navigating to:', segment);
    }
  }
}
</script>