<template>
  <div v-if="isLoading" class="loading-container">
    <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- SVG paths for the loading spinner -->
    </svg>
    <span class="sr-only">Loading...</span>
  </div>
  <!-- Main Content -->
  <div v-else>
    <div class="flex min-h-screen bg-gray-100 text-gray-800">
      <div class="container mx-auto px-4">
        <header class="text-center py-5">
          <h1 class="text-2xl font-bold">Matchmaking Survey</h1>
          <h1 class="text-l font-bold mb-2">Survey C</h1>
          <router-link to="/Survey_MustSurvey_A" class="survey-A-have-want-matters-options bg-orange-500 mr-1">A</router-link>
          <router-link to="/Survey_MustSurvey_B" class="survey-A-have-want-matters-options bg-orange-500 mr-1">B</router-link>
          <router-link to="/Survey_MustSurvey_C" class="survey-A-have-want-matters-options bg-orange-500 mr-1">C</router-link>
          <router-link to="/Survey_MustSurvey_D" class="survey-A-have-want-matters-options bg-orange-500 mr-1">D</router-link>
        </header>

        <div class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4">
          <div v-if="backendSurveyResponse">
            <!-- Layer 1: Question -->
            <p class="text-lg text-center font-bold mb-4 max-w-xl mx-auto">{{ backendSurveyResponse.question }}</p>

            <!-- Layer 2: Dropdowns -->
            <div class="have-want-wrapper mb-4">
              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I HAVE</h3>
                <select v-model="selectedAnswers.have" class="have-want-options-dropdown">
                  <option v-for="option in backendSurveyResponse.have" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>

              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I WANT IDEAL</h3>
                <select v-model="selectedAnswers.want_ideal" class="have-want-options-dropdown">
                  <option v-for="option in backendSurveyResponse.want_ideal" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>

              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I WANT MIN</h3>
                <select v-model="selectedAnswers.want_min" class="have-want-options-dropdown">
                  <option v-for="option in backendSurveyResponse.want_min" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>

              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I WANT MAX</h3>
                <select v-model="selectedAnswers.want_max" class="have-want-options-dropdown">
                  <option v-for="option in backendSurveyResponse.want_max" :key="option" :value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Layer 3: Have Matters and Want Matters -->
            <div class="flex justify-center gap-4">
              <div class="have-want-containers text-center">
                <h3 class="font-bold mb-2">HAVE MATTERS</h3>
                <div class="matters-options">
                  <button v-for="n in 5" :key="'have-matters-' + n" @click="toggleMatter('have_matters', n)" 
                          class="survey-A-have-want-matters-options" :class="{'selected': selectedMatters.have_matters.includes(n)}">
                    {{ n }}
                  </button>
                </div>
              </div>

              <div class="have-want-containers text-center">
                <h3 class="font-bold mb-2">WANT MATTERS</h3>
                <div class="matters-options">
                  <button v-for="n in 5" :key="'want-matters-' + n" @click="toggleMatter('want_matters', n)" 
                          class="survey-A-have-want-matters-options" :class="{'selected': selectedMatters.want_matters.includes(n)}">
                    {{ n }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Layer 4: Update Button -->
            <div class="text-center my-4">
              <p class="text-gray-600 mb-2">{{ statusMessage }}</p> <!-- Display status message here -->
              <button class="survey-update-button" @click="updateQuestion">Update</button>
            </div>

            <!-- Current Question and Total Questions -->
            <div class="text-center mb-4">
              <div><strong>Current Question:</strong> {{ barcode }}</div>
              <div><strong>Total Questions:</strong> {{ totalQuestions }}</div>
            </div>

            <!-- Layer 5: Navigation Buttons -->
            <div class="survey-prev-next-container">
              <button @click="getPreviousQuestion" class="survey-prev">Previous</button>
              
              <button v-if="barcode != '3'" class="survey-next"
                      @click="getNextQuestion">Submit & Next Question</button>
              <button v-if="barcode == '3'" class="survey-next"
                      @click="submitAndNavigate">Submit & Survey</button>
            </div>
          </div>
        </div>
        <div class="flex justify-center text-center mb-2 gap-4">
          <button class="survey-get-go-button"
              style="width: 150px; height: 50px;" @click="fetchOwnAnswers">Get My Answer</button>
          <router-link class="survey-get-go-button"
              style="width: 150px; height: 50px;" to="/AnswerMap_MustSurvey" >Survey Map</router-link>
        </div>

        <div class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4 text-center" v-if="fetchedAnswers">
          <h3 class="font-bold mb-2 text-center">Your Current Answers</h3>
          <p><strong>I Have:</strong> {{ fetchedAnswers.have }}</p>
          <p><strong>I Want Ideal:</strong> {{ fetchedAnswers.want_ideal }}</p>
          <p><strong>I Want Min:</strong> {{ fetchedAnswers.want_min }}</p>
          <p><strong>I Want Max:</strong> {{ fetchedAnswers.want_max }}</p>
          <p><strong>I Have Matters:</strong> {{ fetchedAnswers.have_matters }}</p>
          <p><strong>I Want Matters:</strong> {{ fetchedAnswers.want_matters }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import router from '@/router'; 

export default {
  data() {
    return {
      backendSurveyResponse: null,
      selectedAnswers: {
        have: 'Missing',
        want_ideal: 'Missing',
        want_min: 'Missing',
        want_max: 'Missing'
      },
      selectedMatters: {
        have_matters: [],
        want_matters: []
      },
      fetchedAnswers: null,
      token: localStorage.getItem('token'),
      barcode: '1',
      totalQuestions: 0,
      statusMessage: ''
    };
  },
  async created() {
    await this.fetchSurveyQuestion();
  },
  methods: {
    async fetchSurveyQuestion() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      const barcode = this.barcode;

      try {
        const response = await axios.get(`${backendUrl}/retrieve_MustSurvey_C/`, {
          params: { barcode: barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.backendSurveyResponse = response.data;
        await this.fetchTotalQuestions();
        this.resetSelections();
      } catch (error) {
        console.error('Error fetching survey question:', error);
      }
    },
    async fetchTotalQuestions() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      try {
        const response = await axios.get(`${backendUrl}/number_of_questions_MustSurvey_C/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.totalQuestions = response.data.total_questions;
      } catch (error) {
        console.error('Error fetching total questions:', error);
      }
    },
    async fetchOwnAnswers() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      const barcode = this.barcode;

      try {
        const response = await axios.get(`${backendUrl}/get_own_answer_MustSurvey_C/`, {
          params: { barcode: barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.fetchedAnswers = response.data;
      } catch (error) {
        console.error('Error fetching own answers:', error);
      }
    },
    async getNextQuestion() {
      try {
        await this.submitAnswers();

        const backendUrl = config.apiBaseUrl;
        const token = this.token;
        const response = await axios.get(`${backendUrl}/get_next_question_MustSurvey_C/`, {
          params: { barcode: this.barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.barcode = response.data;

        await this.fetchSurveyQuestion();
        this.statusMessage = 'You are redirected to new question.';
        await this.fetchOwnAnswers();
      } catch (error) {
        console.error('Error fetching next question:', error);
      }
    },
    async submitAndNavigate() {
      await this.submitAnswers();
      router.push('/AnswerMap_MustSurvey');
      
    },
    async updateQuestion() {
      await this.submitAnswers();
      await this.fetchOwnAnswers();
      this.statusMessage = 'Successfully updated';
    },
    async getPreviousQuestion() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      const barcode = this.barcode;

      try {
        const response = await axios.get(`${backendUrl}/get_previous_question_MustSurvey_C/`, {
          params: { barcode: barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.barcode = response.data;
        await this.fetchSurveyQuestion();
        await this.fetchOwnAnswers();
        this.statusMessage = '';
      } catch (error) {
        console.error('Error fetching previous question:', error);
      }
    },
    toggleMatter(type, value) {
    if (type === 'have_matters') {
      // Single select logic for 'have_matters'
      if (this.selectedMatters.have_matters.includes(value)) {
        // Deselect if the value is already selected
        this.selectedMatters.have_matters = [];
      } else {
        // Select the new value
        this.selectedMatters.have_matters = [value];
      }
    } else {
      // Toggle selection for 'want_matters'
      if (this.selectedMatters[type].includes(value)) {
        this.selectedMatters[type] = this.selectedMatters[type].filter(item => item !== value);
      } else {
        this.selectedMatters[type].push(value);
      }
    }
  },
    
  async submitAnswers() {
    const backendUrl = config.apiBaseUrl;
    const token = this.token;

    const isEmpty = (!this.selectedAnswers.have || this.selectedAnswers.have === 'Missing') &&
                    (!this.selectedAnswers.want_ideal || this.selectedAnswers.want_ideal === 'Missing') &&
                    (!this.selectedAnswers.want_min || this.selectedAnswers.want_min === 'Missing') &&
                    (!this.selectedAnswers.want_max || this.selectedAnswers.want_max === 'Missing') &&
                    (!this.selectedMatters.have_matters || this.selectedMatters.have_matters.length === 0) &&
                    (!this.selectedMatters.want_matters || this.selectedMatters.want_matters.length === 0);

    if (isEmpty) {
      console.warn('No selections made, skipping submission');
      return;
    }

    const answerPayload = {
      question: this.backendSurveyResponse.question,
      segment: "MustSurvey",
      barcode: this.barcode,
      have: this.selectedAnswers.have !== 'Missing' ? this.selectedAnswers.have : null,
      want_ideal: this.selectedAnswers.want_ideal !== 'Missing' ? this.selectedAnswers.want_ideal : null,
      want_min: this.selectedAnswers.want_min !== 'Missing' ? this.selectedAnswers.want_min : null,
      want_max: this.selectedAnswers.want_max !== 'Missing' ? this.selectedAnswers.want_max : null,
      have_matters: this.selectedMatters.have_matters || [],
      want_matters: this.selectedMatters.want_matters || []
    };

    try {
      const response = await axios.post(`${backendUrl}/submit_question_MustSurvey_C`, answerPayload, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log('Submission successful:', response.data);
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  },
    resetSelections() {
      this.selectedAnswers = {
        have: 'Missing',
        want_ideal: 'Missing',
        want_min: 'Missing',
        want_max: 'Missing'
      };
      this.selectedMatters = {
        have_matters: [],
        want_matters: []
      };
    }
  }
};
</script>







<style scoped>
  .loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust height as needed */
}

/* General Styles */
.bg-gray-100 {
  background-color: #f7fafc; /* Equivalent to bg-gray-100 */
}
.min-h-screen {
  min-height: 100vh;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-6 {
  padding: 1.5rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}

/* Dropdown Styles */
.have-want-options-dropdown {
  background-color: #d1d5db; /* bg-gray-300 */
  color: #1f2937; /* text-gray-800 */
  font-weight: bold; /* font-bold */
  padding: 0.5rem 1rem; /* py-2 px-4 */
  border-radius: 1.5rem; /* rounded-3xl */
  transition: background-color 0.3s; /* for smooth hover effect */
  width: 80px; /* Adjust width of dropdown */ 
}

.have-want-options-dropdown:hover {
  background-color: #9ca3af; /* hover:bg-gray-400 */
}

.have-want-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust gap between dropdowns */
}

/* Matters Styles */
.matters-wrapper {
  display: flex;
  justify-content: space-around;
}

.have-want-containers {
  text-align: center;
}

.matters-options {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust gap between buttons */
}
.survey-A-have-want-matters-options.selected {
  background-color: #f97316; /* bg-orange-500 */ 
  color: white;
}

/* Text Alignment */
.text-center {
  text-align: center;
}

.my-4 {
  margin: 1rem 0;
}

.fetched-answers {
  margin-top: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    width: 100%; /* Full width to avoid horizontal scrolling */
    padding: 0 15px; /* Reduce padding */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  .have-want-wrapper {
    flex-direction: column; /* Stack the dropdowns vertically */
    align-items: center;
  }

  .have-want-containers {
    width: 80%; /* Full width containers */
    margin: 0 auto; /* Center aligning */
    padding: 10px; /* Padding for internal spacing */
  }

  .survey-A-have-want-options, .survey-A-have-want-matters-options {
    width: 100%; /* Full width for easy tapping */
    margin: 5px 0; /* Margin for spacing */
    font-size: x-small;
  }

  h1, h2 {
    font-size: 1.2rem; /* Smaller font size for headers */
    padding: 10px 0; /* Padding around headers */
    text-align: center; /* Center alignment */
  }

  button {
    padding: 8px; /* Smaller padding for buttons */
    font-size: 0.875rem; /* Smaller font size for button text */
  }
}

</style>
