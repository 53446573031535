<template>
  <h1 class="text-gray-800 font-bold text-center text-2xl mb-3">Dynamic Panel</h1>
  <div class="flex justify-center">
    <div class="game21-toggle-buttons rounded-3xl font-bold" style="width: 240px">
      <img :src="playIcon" class="dynamic-toggle-logo" :class="{ active: activeContent === 'icebreaker' }" @click="toggleContent('icebreaker')" alt="Icebreaker">
      <img :src="robotIcon" class="dynamic-toggle-logo" :class="{ active: activeContent === 'aibot' }" @click="toggleContent('aibot')" alt="AIBot">
      
      <div class="relative" @mouseleave="closeDropdown2">
        <img :src="questionIcon" class="dynamic-toggle-logo" @click="toggleDropdown2" :class="{ active: activeContent === 'yours' || activeContent === 'partner' }" alt="2 Truths & a Lie">
        <div v-show="isDropdownVisible2" class="dropdown absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <button @click="toggleContent('yours')">Yours</button>
          <button @click="toggleContent('partner')">Partner</button>
        </div>
      </div>

      <div class="relative" @mouseleave="closeDropdown">
        <img :src="infoIcon" class="dynamic-toggle-logo" @click="toggleDropdown" :class="{ active: activeContent === 'chat_info' }" alt="Info">
        <div v-show="isDropdownVisible" class="dropdown absolute mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <button @click="toggleContent('chat_info_icebreaker')">Icebreaker Info</button>
          <button @click="toggleContent('chat_info_aibot')">AI Info</button>
          <button @click="toggleContent('chat_info_game21')">Game21 Info</button>
        </div>
      </div>
      
    </div>
  </div>
  <component :is="activeComponent"></component>
</template>







<style scoped>
.game21-toggle-buttons {
  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.dynamic-toggle-logo {
  width: 50px;
  height: 50px;
  margin: 5px;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s, transform 0.3s;
}

.dynamic-toggle-logo.active {
  background-color: #ffffff;
  transform: scale(1.1);
}

.relative {
  position: relative;
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 10px;
  margin-top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown button {
  padding: 5px 10px;
  margin: 2px 0;
  border: none;
  cursor: pointer;
  background-color: #f3f4f6;
  color: #111827;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.dropdown button:hover {
  background-color: #e2e8f0;
}

.dropdown button.active {
  background-color: #ef7800;
  color: white;
}

.absolute {
  position: absolute;
  z-index: 10;
  width: max-content;
}
</style>


<script>
import Icebreaker from '../Chat/Icebreaker.vue';
import Chat_Info_Icebreaker from '../Information/Chat_Info_Icebreaker.vue';
import Chat_Info_AIBot from '../Information/Chat_Info_AIBot.vue';
import Chat_Info_Game21 from '../Information/Chat_Info_Game21.vue';

// Importing images
import playBlack from '@/assets/Dynamic/questionBlack.png';
import playOrange from '@/assets/Dynamic/questionOrange.png';
import robotBlack from '@/assets/Dynamic/robotBlack.png';
import robotOrange from '@/assets/Dynamic/robotOrange.png';
import infoBlack from '@/assets/Dynamic/infoBlack.png';
import infoOrange from '@/assets/Dynamic/infoOrange.png';
import questionBlack from '@/assets/Dynamic/playBlack.png';
import questionOrange from '@/assets/Dynamic/playOrange.png';

export default {
  props: ['activeContent'],
  components: {
    Icebreaker,
    Chat_Info_Icebreaker,
    Chat_Info_AIBot,
    Chat_Info_Game21
  },
  data() {
    return {
      isDropdownVisible: false,
      isDropdownVisible2: false,
    };
  },
  computed: {
    activeComponent() {
      switch (this.activeContent) {
        case 'yours':
        case 'partner':
          return 'YoursOrPartnerComponent'; // Replace with actual component if needed
        case 'aibot':
          return 'AIBotComponent'; // Replace with actual component if needed
        case 'icebreaker':
          return Icebreaker;
        case 'chat_info_icebreaker':
          return Chat_Info_Icebreaker;
        case 'chat_info_aibot':
          return Chat_Info_AIBot;
        case 'chat_info_game21':
          return Chat_Info_Game21;
        default:
          return null;
      }
    },
    playIcon() {
      return this.activeContent === 'icebreaker' ? playOrange : playBlack;
    },
    robotIcon() {
      return this.activeContent === 'aibot' ? robotOrange : robotBlack;
    },
    infoIcon() {
      return this.activeContent === 'chat_info' || this.activeContent === 'chat_info_icebreaker' || this.activeContent === 'chat_info_aibot' || this.activeContent === 'chat_info_game21' ? infoOrange : infoBlack;
    },
    questionIcon() {
      return this.activeContent === 'yours' || this.activeContent === 'partner' ? questionOrange : questionBlack;
    }
  },
  methods: {
    toggleContent(content) {
      this.$emit('toggle', this.activeContent === content ? null : content);
      this.closeDropdown();
      this.closeDropdown2();
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    closeDropdown() {
      this.isDropdownVisible = false;
    },
    toggleDropdown2() {
      this.isDropdownVisible2 = !this.isDropdownVisible2;
    },
    closeDropdown2() {
      this.isDropdownVisible2 = false;
    }
  },
};
</script>
