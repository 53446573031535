<template>
  <div class="bg-gray-100 items-center min-h-screen">
    <div class="container mx-auto px-4 pt-12">
      <img src="@/assets/Logos/logoSenlikBuddy.png" alt="Date Buddy Logo" class="segment-image mx-auto mb-4">
      <h2 class="text-center text-2xl font-bold mb-4 text-gray-100">Tam Senlik!</h2>
      <div class="flex flex-col lg:flex-row justify-between items-start">

        <!-- Left Container -->
        <div class="left-container w-full lg:w-1/4 mb-4 lg:mb-0">
          <div class="bg-white p-6 rounded-lg shadow-lg containers-color">
            <h2 class="text-center text-2xl font-bold mb-4 text-gray-100">Senlik Buddy</h2>
            <p class="text-center text-gray-300">Refined matchmaking algorithm which matches METU students since 2022.</p>
          </div>

          <div class="bg-white p-6 rounded-lg shadow-lg mt-5 containers-color">
            <h2 class="text-center text-2xl font-bold mb-4 text-gray-100">Statistics</h2>
            <p class="text-center text-gray-300">We have been working since 2021 and have received over 2,600 applications. Now, we are starting again with our new website.</p>
            <p class="text-center text-gray-400">34% of our users had not used a dating app before (as of January 2023).</p>
            <p class="text-center text-orange-500">We are very proud to have your trust!</p>
          </div>
        </div>

        <!-- Register/Login Box -->
        <div class="register-login-container w-full lg:w-1/3 bg-white p-6 shadow-lg flex flex-col items-center">
          <!-- Toggle Buttons -->
          <div class="flex justify-center mb-3 w-full">
            <button
              @click="isSignup = true"
              :class="['px-4 py-2 rounded-l-lg', isSignup ? 'bg-orange-500 text-gray-100' : 'bg-gray-200 text-gray-700']"
            >
              Signup
            </button>
            
            <button
              @click="isSignup = false"
              :class="['px-4 py-2 rounded-r-lg', !isSignup ? 'bg-orange-500 text-gray-100' : 'bg-gray-200 text-gray-700']"
            >
              Login
            </button>
          </div>

          <div class="mb-4 mt-3 text-gray-100 text-xs">"Dating is not competition but experience."</div>
          
          <!-- Form -->
          <form @submit.prevent="isSignup ? register() : login()" class="w-full">
            <div class="mb-4">
              <input
                type="email"
                v-model="email"
                placeholder="Email"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>
            <div class="mb-4">
              <input
                type="password"
                v-model="password"
                placeholder="Password"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
            </div>

            <div class="form-group mb-4">
              <input
                v-if="isSignup"
                type="password"
                v-model="confirmPassword"
                placeholder="Confirm Password"
                class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500"
              />
              <img
                v-if="!isSignup"
                src="@/assets/Register/doorhandle.png"
                alt="Date Buddy Logo"
                class="segment-image-login mx-auto"
              />
            </div>

            <div class="mb-4">
              <button type="submit" class="w-full bg-orange-500 text-gray-100 px-4 py-2 rounded-lg">
                {{ isSignup ? 'Signup' : 'Login' }}
              </button>
            </div>
            <div>
              <p class="text-center mb-3 text-gray-100">or</p>
            </div>
            <div class="google-signin-container mb-4">
              <div id="googleSignInButton">Loading Google Sign-In...</div>
            </div>
            <div class="text-center">
              <router-link to="/forgotpassword" class="text-orange-500 hover:underline">Forgot Password?</router-link>
            </div>
            <p v-if="message" class="text-gray-100 text-center mt-2">{{ message }}</p>
          </form>
        </div>

        <!-- Right Container -->
        <div class="right-container w-full lg:w-1/4 mt-4 lg:mt-0 mb-10">
          <div class="bg-white p-6 rounded-lg shadow-lg containers-color">
            <h2 class="text-center text-2xl font-bold mb-4 text-gray-100">What Unique?</h2>
            <p class="text-center text-gray-300">We have comprehensive, trained machine learning models. Additionally, we provide chat accelerators.</p>
            <div class="flex justify-center mt-2">
              <img src="@/assets/Dynamic/playOrange.png" alt="Date Buddy Logo" class="segment-image-unique mx-2 mb-4">
              <img src="@/assets/Dynamic/questionOrange.png" alt="Date Buddy Logo" class="segment-image-unique mx-2 mb-4">
              <img src="@/assets/Dynamic/robotOrange.png" alt="Date Buddy Logo" class="segment-image-unique mx-2 mb-4">
            </div>
            <p class="text-center text-gray-300">Icebreaker Questions (Active).</p>
            <p class="text-center text-gray-300">2 Truths & a Lie (Active).</p>
            <p class="text-center text-gray-300">Wing Buddy AI (In progress).</p>
          </div>
          
          <div class="containers-color p-6 rounded-lg shadow-lg mt-5">
            <h2 class="text-center text-2xl text-gray-100 font-bold mb-4">How it works?</h2>
            <p class="text-center text-gray-300">You fill out surveys. The algorithm finds the best match for your criteria. At the announcement time, you are able to meet your match.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.segment-image {
  width: 5rem;
  height: 5rem;
  margin-top: 20px;
}

.segment-image-login {
  width: 2rem;
  height: 2rem;
}

.segment-image-unique {
  width: 2rem;
  height: 2rem;
}

.google-signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensure it takes the full width of the container */
  margin-top: 1rem; /* Add some spacing on top */
}

.register-login-container {
  background: #282c34;
  padding: 2rem;
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 100px 10px 10px;
  overflow: hidden;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-group {
  height: 50px;
  display: flex;
  align-items: center;
}

.containers-color {
  background: #282c34;
}

.left-container,
.right-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

/* Add responsive styles */
@media (max-width: 1024px) {
  .register-login-container,
  .left-container,
  .right-container {
    width: 100%;
  }

  .register-login-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .right-container {
    margin-top: 1rem;
  }
}

@media (max-width: 640px) {
  .google-signin-container {
    margin-top: 0.5rem; /* Adjust spacing for smaller screens */
    height: auto; /* Adjust height to fit the content */
  }
}
</style>

<script>
import axios from 'axios';
import config from '@/config';
import { ref, onMounted } from 'vue';

export default {
  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      isSignup: true,
      message: ''
    };
  },
  methods: {
    async register() {
      if (this.password !== this.confirmPassword) {
        this.message = 'Passwords do not match.';
        return;
      }

      try {
        const response = await axios.post(`${this.backendUrl}/register_step_0/`, {
          email: this.email,
          password: this.password
        });
        console.log('Register response:', response.data);
        localStorage.setItem('token', response.data.token);
        this.$store.commit('setLoginState', true);
        this.$store.commit('setTermsState', response.data.termsofservice_status === "verified");
        localStorage.setItem('acceptedTerms', response.data.termsofservice_status === "verified");
        this.$router.push('/');
      } catch (error) {
        console.error('Error during registration:', error);
        this.message = 'Your signup attempt was unsuccessful.';
      }
    },
    async login() {
      try {
        const response = await axios.post(`${this.backendUrl}/login/`, {
          email: this.email,
          password: this.password
        });
        console.log('Login response:', response.data);
        localStorage.setItem('token', response.data.token);
        this.$store.commit('setLoginState', true);
        this.$store.commit('setTermsState', response.data.termsofservice_status === "verified");
        localStorage.setItem('acceptedTerms', response.data.termsofservice_status === "verified");
        this.$router.push('/');
      } catch (error) {
        console.error('Error during login:', error);
        this.message = 'Your login attempt was unsuccessful.';
      }
    },
    googleSignIn() {
      window.google.accounts.id.prompt();
    },
    loadGoogleScript() {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = this.renderGoogleButton;
      document.head.appendChild(script);
    },
    renderGoogleButton() {
      window.google.accounts.id.initialize({
        client_id: config.googleClientId,
        callback: async (response) => {
          try {
            const result = await axios.post(`${this.backendUrl}/register1Consent/`, {}, {
              headers: { 'Authorization': `Bearer ${response.credential}` }
            });
            console.log('Google Sign-In response:', result.data);
            localStorage.setItem('token', result.data.token);
            this.$store.commit('setLoginState', true);
            this.$store.commit('setTermsState', result.data.termsofservice_status === "verified");
            localStorage.setItem('acceptedTerms', result.data.termsofservice_status === "verified");
            this.$router.push('/');
          } catch (error) {
            console.error('Error during Google Sign-In:', error);
          }
        }
      });

      window.google.accounts.id.renderButton(
        document.getElementById('googleSignInButton'),
        { theme: 'outline', size: 'large' }
      );
    }
  },
  mounted() {
    if (!window.google || !window.google.accounts) {
      this.loadGoogleScript();
    } else {
      this.renderGoogleButton();
    }
  },
  setup() {
    const backendUrl = ref(config.apiBaseUrl);

    return {
      backendUrl
    };
  }
};
</script>



<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}
</style>
