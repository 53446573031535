<template>
  <div class="flex justify-center mt-10 ">
    <div class="chat-window bg-white w-full max-w-lg ">
      <div class="chat-header p-4 flex justify-between items-center">
        <h2 class="text-lg font-medium">Wing Buddy AI</h2>
        <button class="text-gray-400 hover:text-gray-400" @click="closeChat">&times;</button>
      </div>
      <div class="chat-body p-4 h-64 overflow-y-auto ">
        <div v-for="message in messages" :key="message.id" class="mb-2">
          {{ message.text }}
        </div>
        
        <div class="flex justify-center">
        <img src="@/assets/WingBuddy/wrench.png" alt="Date Buddy Logo" class="segment-image-wingman">
        </div>
        
      </div>
      <div class="chat-input p-4 flex items-center">
        <input v-model="newMessage" type="text" class="flex-grow p-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="In progress..." disabled>
        <button @click="sendMessage" class="bg-gray-500 text-white px-4 py-2 rounded-r-md hover:bg-gray-500" disabled>Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';

export default {
  data() {
    return {
      messages: [],
      newMessage: '',
      token: localStorage.getItem('token')
    };
  },
  methods: {
    async fetchMessages() {
      if (!this.token) {
        console.error('Token not found, user might not be logged in');
        return;
      }
      try {
        const response = await axios.post(`${config.apiBaseUrl}/get_conversation_history/`, {}, {
          headers: { Authorization: `Bearer ${this.token}` }
        });
        this.messages = response.data;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    sendMessage() {
      // Send message functionality is disabled.
    },
    closeChat() {
      // Logic to close chat window
    }
  },
  mounted() {
    this.fetchMessages();
  }
};
</script>

<style scoped>
.segment-image-wingman {
  width: 10rem;
  height: 10rem;
  margin-top: 20px;
}

body {
  font-family: 'Roboto', sans-serif;
}
.chat-window {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.chat-header {
  border-bottom: 1px solid #e5e7eb;
}
.chat-input {
  border-top: 1px solid #e5e7eb;
}
</style>
