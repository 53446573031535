<template>
  <div class="main-container">
    <!-- Sidebar -->
    <Sidebar/>
    
    <!-- Delete Profile Container -->
    <div class="delete-profile-container">
      <h1 class="font-bold text-xl mb-3">Delete Profile</h1>
      <p class="mb-3">Please enter your google email which you registered to confirm deletion:</p>
      <input type="email" v-model="userEmail" placeholder="Enter your email youremail@gmail.com" class="mb-3">
      <p v-if="message" :class="{'error-message': isError, 'success-message': !isError}" class="mb-2">{{ message }}</p>
      <button @click="deleteProfile" class="delete-btn mb-2 mt-2">Delete My Profile</button>
    </div>
  </div>
</template>



  <script>
  import axios from 'axios';
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import config from '@/config';
  import Sidebar from '@/components/Default/Sidebar.vue';
  
  export default {
    components: {
      Sidebar
    },
    setup() {
      const userEmail = ref('');
      const backendUrl = ref(config.apiBaseUrl);
      const router = useRouter();
      const store = useStore();
      const message = ref('');
      const isError = ref(false);
  
      const deleteProfile = async () => {
        if (!userEmail.value) {
          message.value = 'Please enter your email.';
          isError.value = true;
          return;
        }
  
        try {
          const url = `${backendUrl.value}/delete_profile/`;
          const token = localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${token}`
          };
          const response = await axios.delete(url, {
            headers: headers,
            data: { email: userEmail.value }
          });
          message.value = response.data.message;
          isError.value = false;
  
          // After deleting the profile, log the user out
          await store.dispatch('logout');
          // Redirect to the home page or any other page as needed
          router.push('/');
        } catch (error) {
          console.error('Error:', error);
          message.value = 'Error: ' + (error.response?.data.detail || 'Failed to delete profile');
          isError.value = true;
        }
      };
  
      return {
        userEmail,
        deleteProfile,
        message,
        isError
      };
    },
  };
  </script>
  
  <style scoped>
.main-container {
  display: flex;
  align-items: flex-start; /* Ensure alignment at the start of the container */
  height: 100vh; /* Full height to expand the main container */
}

.Sidebar {
  flex: 0 0 200px; /* fixed width for Sidebar */
}

.delete-profile-container {
  flex-grow: 1;
  margin-top: 20px; /* Reduced top margin */
  padding: 20px;
  text-align: center;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Adds shadow for better visibility */
  border-radius: 8px; /* Rounded corners for aesthetic */
  background-color: #fff; /* White background for the container */
  display: block; /* Use block to ensure it respects the max-width */
  width: 100%; /* Use 100% to respect padding and border settings */
  max-width: 500px; /* Maximum width */
  overflow: hidden; /* Hide any overflow */
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.delete-btn {
  width: 100%;
  padding: 10px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: darkred;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}
</style>
