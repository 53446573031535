<template>
  <div class="flex bg-gray-100">
    <!-- Sidebar -->
    <Sidebar/>

    <!-- Main Content Area -->
    <div class="content-area ">
      <div class="bg-gray-100 font-sans leading-normal tracking-normal">
        <div class="container mx-auto p-8">
          <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
            <div class="p-6">
          <div class="mb-4 flex justify-between items-center">
            <h1 class="text-xl font-semibold text-gray-900">Your Credits: {{ currentCredits }}</h1>
            <button style="width: 150px; height: 50px;" class="update-button bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    @click="goLoadCredits">
              Load Credits
            </button>
          </div>
          <div class="mb-4">
            <h1 class="text-xl font-semibold text-gray-900">Your Current Balance: ${{ currentBalance }}</h1>
          </div>
          <div class="mb-6">
            <h2 class="text-lg font-semibold text-gray-700">Balance Management</h2>
            <div class="grid grid-cols-3 gap-4 mt-4">
              <button v-for="option in balanceOptions" :key="option.product_id"
                      :class="{'balance-button-selected': selectedOption && selectedOption.product_id === option.product_id, 'balance-button': true}"
                      @click="selectOption(option)">
                {{ option.balance }} Balance<br>$ {{ option.price }}
              </button>
            </div>
          </div>
          <div class="flex justify-center">
            <button class="update-button bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    @click="updateBalance()"
                    :disabled="!selectedOption">
              Update Balance
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import { useRouter } from 'vue-router';

import Sidebar from '@/components/Default/Sidebar.vue';

export default {
  components: {
    Sidebar
  },
  setup() {
    const router = useRouter();
    const currentBalance = ref(0);
    const currentCredits = ref(0);
    const balanceOptions = ref([]);
    const selectedOption = ref(null);
    const goLoadCredits = () => { router.push('/LoadCredits'); };

    const fetchCredits = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.apiBaseUrl}/get_person_credits`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        currentCredits.value = response.data.credits;
      } catch (error) {
        console.error("Error fetching credits:", error);
      }
    };

    const fetchBalance = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${config.apiBaseUrl}/get_person_balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          currentBalance.value = response.data.balance;
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      };


    const fetchBalanceOptions = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${config.apiBaseUrl}/balance_price_table_dict`, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        balanceOptions.value = response.data;
      } catch (error) {
        console.error("Error fetching balance options:", error);
      }
    };

    const selectOption = (option) => {
      console.log("Option selected:", option);
      selectedOption.value = option;
    };

    const updateBalance = async () => {
      if (!selectedOption.value) return;
      try {
        const token = localStorage.getItem('token');
        const payload = {
          person_id: "currentUserID",  // this should be the actual user ID from authentication
          balance: selectedOption.value.price  // sending price as balance
        };
        const response = await axios.put(`${config.apiBaseUrl}/update_person_balance`, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        if (response.data) {
          console.log("Update successful:", response.data);
          currentBalance.value += selectedOption.value.price;  // Update local balance state
        }
      } catch (error) {
        console.error("Error updating balance:", error);
      }
    };



    onMounted(() => {
      fetchBalance();
      fetchBalanceOptions();
      fetchCredits();
    });

    return {
      currentBalance,
      currentCredits,
      balanceOptions,
      selectedOption,
      selectOption,
      updateBalance,
      goLoadCredits
    };
  }
}
</script>
<style scoped>
.flex {
  display: flex;
  flex-direction: row; /* Layout sidebar and content side by side */
  width: 100%;
}

.content-area {
  flex: 1; /* Takes the remaining space */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  justify-content: flex-start; /* Align content to the top */
}

.container {
  max-width: 768px; /* Adjust based on your preference */
  width: 100%; /* Responsive width */
}

/* General button styling */
.button, .credit-button, .update-button, .balance-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  color: white; /* Ensures text is white for all buttons */
}

/* Styling for different types of buttons */
.update-button {
  background-color: bg-orange-500; /* Default background for update buttons */
}


.credit-button, .balance-button {
  background-color: #4f554f; /* Default background for credit and balance buttons */
}

.credit-button-selected, .balance-button-selected {
  background-color: #bddbff; /* Light blue background for selected state */
}

/* Hover effects */
.button:hover, .credit-button:hover, .update-button:hover, .balance-button:hover {
  background-color: #c47309; /* A darker shade when hovered */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px); /* Moves button up slightly on hover */
}
</style>
