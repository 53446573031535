<template>
  <div class="content-container bg-gray-100">
    <div class="sidebar">
    
  </div>
    <div class="main-content-area bg-gray-100">
      <div class="segment-main">
        <h1 class="section-title text-gray-800 text-center">Senlik Buddy</h1>
        <p class="block text-gray-700 text-sm font-bold mb-4">Each survey has own match on specific date.</p> 
        
        <div class="buddy-cards-container">

          
          <router-link to="/Panel_MustSurvey" class="buddy-item-welcome">
            <img src="@/assets/Main/surveyMain.png" alt="Date Buddy Logo" class="segment-image">
            <h2 class="segment-logo-title text-center">1- Fill Must Survey</h2>
          </router-link>

          <router-link to="/Management_Map" class="buddy-item-welcome">
            <img src="@/assets/Main/doorIn.png" alt="Date Buddy Logo" class="segment-image">
            <h2 class="segment-logo-title text-center">2- Go To Chat Rooms</h2>
          </router-link>
          
        </div>
      </div>

      <!-- Second Segment Main for Status Table -->
      <div class="segment-main">
        <h1 class="section-title text-gray-700 text-center">Must Checklist</h1>
        <div class="shadow-lg rounded-lg overflow-hidden">
          <table class="w-full">
          
            <thead class="bg-gray-100 text-center">
              <tr>
                <th class="py-3 px-4 font-semibold text-gray-600 text-center">Task</th>
                <th class="py-3 px-4 font-semibold text-gray-600 text-center">Status</th>
                <th class="py-3 px-4 font-semibold text-gray-600 text-center">Action</th>
              </tr>
            </thead>
            
            <tbody>  
              
              <tr>
                <td class="py-2 px-4 text-center">EDU Verify</td>
                <td :class="getEDUVerify_StatusClass">{{ EDUVerify_StatusStatus }}</td>
                <td class="text-center">
                  <router-link :to="'./EDUVerify'" class="toggle-button-3 text-center" :class="getEDUVerify_StatusClass">Verify</router-link>
                </td>
              </tr>
              <tr class="bg-gray-50 text-center">
                <td class="py-2 px-4">Must Survey</td>
                <td :class="getMustSurvey_StatusClass">{{ MustSurvey_StatusStatus }}</td>
                <td class="text-center">
                  <router-link :to="'./Panel_MustSurvey'" class="toggle-button-3 text-center" :class="getMustSurvey_StatusClass">Finish</router-link>
                </td>
              </tr>
            </tbody>

          </table>
        </div>
        
        <div class="flex justify-center gap-3">

        <div class="mt-5 text-center">
          <router-link to="/Help" class="buddy-item-welcome-help">
            <img src="@/assets/Welcome/help.png" alt="Date Buddy Logo" class="segment-image-help">
            <h2 class="segment-logo-title-help text-gray-700 text-center">Help</h2>
          </router-link>
        </div>

        <div class="mt-5 text-center">
          <router-link to="/Communication" class="buddy-item-welcome-help">
            <img src="@/assets/Welcome/communicationBlack.png" alt="Date Buddy Logo" class="segment-image-help">
            <h2 class="segment-logo-title-help text-gray-700 text-center">News</h2>
          </router-link>
        </div>
        
      </div>
      <p class="block text-gray-700 text-sm font-bold mt-4">You can follow us on Instagram @senlikbuddy</p> 

      </div>
    </div>
  </div>
</template>




<style scoped>


html, body {
height: 100%;
margin: 0;
padding: 0;
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders between cells are merged */
}

th, td {
  text-align: center;
  padding: 8px; /* Adjust padding as needed */
  border-bottom: 1px solid #ddd;
  vertical-align: middle; /* Centers content vertically */
}

th {
  background-color: #f8f8f8;
}

::v-deep .sidebar {
  position: fixed;
  z-index: 1000; /* Use a higher value as necessary */

  top: var(--nav-height);  /* Adjust this based on your navigation bar's height */
  left: 0;
  width: 200px;  /* Adjust width as necessary */
  height: calc(100vh - var(--nav-height));  /* Full height minus navigation bar */
  overflow-y: auto;  /* Enables scrolling within the sidebar itself */
  background: #7e7b7b;  /* Sidebar background color */ 
}


.content-container {
display: flex;
flex-direction: row; /* Aligns children (sidebar and main content) in a row */
min-height: 100vh;
width: 100%;
}

.main-content-area {
flex-grow: 1; /* Takes remaining space */
display: flex;
flex-direction: column;
align-items: center;
margin: 0;
padding: 0;
}


</style>

<style scoped>

.segment-main {
max-width: 68rem;
margin: 0 auto; /* Reduced margin */
padding: 1rem;
display: flex;
flex-direction: column;
align-items: center; /* Centers the content vertically */
}

.buddy-cards-container {
display: flex;
flex-wrap: wrap; /* Allows items to wrap in new rows */
gap: 2rem;
justify-content: center; /* Center cards within the main content area */
}

.buddy-item-welcome {
display: flex;
flex-direction: column;
align-items: center;
width: 12rem;
height: 10rem;
background-color: #ffffff;
border-radius: 0.5rem;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease-in-out;
}

.buddy-item-welcome:hover {
transform: translateY(-7px);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.segment-image {
width: 5rem;
height: 5rem;
margin-top: 20px;

}

.segment-logo-title {
margin-top: 1rem;
font-size: 1rem;
font-weight: 700;
}


.section-title {
font-weight: 700;
font-size: 2.0rem;
text-align: center;
margin-bottom: 0.5rem;
}

/* Mobile styles */
@media (max-width: 768px) {
  .buddy-item-welcome {
    width: 6rem; /* Half the original width */
    height: 5rem; /* Adjust height accordingly */
  }

  .segment-image {
    width: 2.5rem; /* Half the original width */
    height: 2.5rem; /* Keep the aspect ratio */
  }

  .segment-logo-title {
    font-size: 0.5rem; /* Slightly smaller text */
    margin-top: 5px;
  }
}

</style>




<script>
import axios from 'axios';
import config from '@/config';
import SidebarMatch from '@/components/Default/SidebarMatch.vue';

import 'primeicons/primeicons.css'


export default {
  components: {
    SidebarMatch
  },
  name: 'Welcome_Main',
  data() {
    return {
      EDUVerify_StatusStatus: 'Loading...',
      MustSurvey_StatusStatus: 'Loading...',
      backendUrl: config.apiBaseUrl  // Using imported config for consistency
    };
  },
  methods: {
    async fetchStatus() {
      const token = localStorage.getItem('token');
      // Fetch EDU Verification Status
      await axios.get(`${this.backendUrl}/status_current_EDU_Verification/`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.EDUVerify_StatusStatus = response.data.verification_status || 'Error';
      }).catch(error => {
        console.error('Error fetching EDU Verification status:', error);
        this.EDUVerify_StatusStatus = 'Error';
      });

      // Fetch MustSurvey Status
      await axios.get(`${this.backendUrl}/complete_status_current_MustSurvey/`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.MustSurvey_StatusStatus = response.data.MustSurvey_Status || 'Error';
      }).catch(error => {
        console.error('Error fetching MustSurvey status:', error);
        this.MustSurvey_StatusStatus = 'Error';
      });
    },
  },
  watch: {
    '$store.state.acceptedTerms'(newVal) {
        console.log("Watched Terms acceptance change to:", newVal);
        if (newVal) {
            console.log("Fetching status due to terms acceptance.");
            this.fetchStatus();
        }
    }
}

, 
mounted() {
    this.$nextTick(() => {
        
        if (this.$store.state.acceptedTerms) {
            
            this.fetchStatus();
        } else {
        
        }
    });
}


}
</script>
