<template>
    <div class="container">
      <div class="card">
        <h1 class="title">Welcome to Feedback Section</h1>
        <div class="content text-center">
          <p class="mb-4">If you have feedback, use Feedback Form.</p>
          <p class="mb-4">If you would like to share a question to be added use Question Form.</p>
          <p class="mb-4">© Senlik Buddy Copy Rights</p>
          
        </div>
        <div class="buttons">
          <button @click="goButton2" class="welcome-feedback-button">
            Feedback
          </button>
          <button @click="goButton3" class="welcome-feedback-button">
            Add Your Question
          </button>
          
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { useRouter } from 'vue-router';
  
  export default {
    setup() {
      const router = useRouter();
      const goButton1 = () => { router.push('/ContactForm');  };
      const goButton2 = () => { router.push('/FeedbackForm');  };
      const goButton3 = () => { router.push('/FormQuestion');  };
      return { goButton1, goButton2, goButton3 };
    }
  }
  </script>
  
  <style scoped>
  body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}


.container {
  margin: 0 auto; /* Center the container */
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center children horizontally */
  justify-content: flex-start; /* Align children to the start vertically */
  width: 100%; /* Ensure it takes full width of its parent */
  height: 100vh; /* Full viewport height */
  padding-top: 20px; /* Adjust as needed for spacing from top */
}
  .card {
    background-color: white;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px; /* Adjust based on your preference */
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .content {
    background-color: #f9f9f9;
    padding: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .buttons {
  display: flex;
  justify-content: center; /* Center the buttons within the card */
  gap: 2.5rem; /* Closer gap between buttons, approximately 8px */
}

  
  .button {
    background-color: #ed9209;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    width: 150px; /* Fixed width for buttons */
    height: 70px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #dd6b20; /* Orange-700 for hover effect */
    transform: translateY(-2px);
  }



  </style>
  