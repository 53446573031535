<template>
  <div class="bg-gray-100 flex items-start justify-center pt-20 min-h-screen">
    <div class="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
      <h1 class="text-xl text-center font-bold mb-6">Contact Form</h1>
      <form @submit.prevent="handleSubmit" class="space-y-4 p-6 bg-white">
        <div>
          <label for="name" class="block font-bold">Name:</label>
          <input type="text" id="name" v-model="formData.name" class="form-input" :disabled="formDisabled">
        </div>
        <div>
          <label for="email" class="block font-bold">Email:</label>
          <input type="email" id="email" v-model="formData.email" class="form-input" :disabled="formDisabled">
        </div>
        <div>
          <label for="enquiry" class="block font-bold">Enquiry:</label>
          <textarea id="enquiry" v-model="formData.enquiry" class="form-textarea" :disabled="formDisabled"></textarea>
        </div>
        <div class="flex justify-center">
        <button type="submit" class="submit-button" :disabled="formDisabled">Submit</button>
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import config from '@/config';

export default {
  setup() {
    const formData = ref({
      name: '',
      email: '',
      enquiry: '',
    });

    const formDisabled = ref(false);

    const backendUrl = ref(config.apiBaseUrl);
    console.log("Environment VUE BACKEND URL:", backendUrl.value);

    const handleSubmit = async () => {
      formDisabled.value = true; // Disable form
      setTimeout(() => {
        formDisabled.value = false; // Enable form after 180000 ms (3 minutes)
      }, 180000);

      try {
        const url = `${backendUrl.value}/contact_submit/`;
        console.log("Sending POST request to:", url);
        const response = await axios.post(url, formData.value, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        });
        if (response.data.message === "CONTACT_FORM_INSERTED") {
          alert('Form submitted successfully!');
        } else {
          alert('Submission failed: ' + response.data.message);
        }
      } catch (error) {
        console.error('Failed to submit contact form:', error);
        alert('Failed to submit the form.');
      }
    };

    return { formData, handleSubmit, formDisabled };
  },
};
</script>

<style scoped>
.form-input, .form-textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container {
  display: flex;
  justify-content: center; /* Centers content along the horizontal line */
  align-items: center;     /* Centers content along the vertical line */
  min-height: 100vh;       /* Optional: Takes full viewport height */
}

.submit-button {
  background-color: #e06902; /* Dark orange background */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

</style>
