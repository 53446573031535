<template>
    <div class="flex justify-center bg-gray-100">
      <Sidebar/>

    
    <div class="bg-gray-100">
      <div class="password-manager-container w-full p-6 flex flex-col items-center">
        <!-- Toggle Buttons -->
        <div class="flex justify-center w-full">
          <button
            @click="activeTab = 'ChangePassword'"
            :class="['px-4 py-2 rounded-l-lg', activeTab === 'ChangePassword' ? 'bg-orange-500 text-gray-100' : 'bg-blue-500 text-gray-100']"
          >
            Change Password
          </button>
          <button
            @click="activeTab = 'ForgotPassword'"
            :class="['px-4 py-2 rounded-r-lg', activeTab === 'ForgotPassword' ? 'bg-orange-500 text-gray-100' : 'bg-blue-500 text-gray-100']"
          >
            Forgot Password
          </button>
        </div>
  
        <!-- Display active component -->
        <component :is="activeTab" class="w-full max-w-sm"></component>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import ChangePassword from './ChangePassword.vue';
  import ForgotPassword from './ForgotPassword.vue';
  import Sidebar from '@/components/Default/Sidebar.vue';

  export default {
    components: {
      ChangePassword,
      ForgotPassword,
      Sidebar 
    },
    data() {
      return {
        activeTab: 'ChangePassword'
      };
    }
  };
  </script>
  
  <style scoped>
  .password-manager-container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 24px;
    border-radius: 8px;
  }
  
  button {
    background-color: #f3f4f6;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button.bg-orange-500 {
    background-color: #F8740C;
    color: #ffffff;
  }
  
  button.bg-blue-500 {
    background-color: #282c34;
    color: #ffffff;
  }
  
  button:hover {
    background-color: #F8740C;
    color: #ffffff;
  }
  </style>
  