<template>
    <footer class="footer-custom">
      <div class="w-full mt-4 md:mt-0 md:w-auto text-center">
        <p class="text-sm text-white font-bold">
          © {{ currentYear }} Senlik Buddy. All rights reserved.
        </p>
      </div>
      <div class="w-full md:w-auto text-center md:text-left">
        <div class="flex flex-col md:flex-row gap-3 mt-3 md:mt-0 text-sm text-white font-bold">
          
          <a href="/PrivacyPolicy" class="footer-link">Privacy Policy</a>
          
          <a href="/FAQ" class="footer-link">FAQ</a>
          <a href="/About" class="footer-link">About</a>
          <a href="/ContactForm" class="footer-link">Contact</a>
          
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'NewFooter',
    data() {
      return {
        currentYear: new Date().getFullYear()
      };
    }
  };
  </script>
  
  <style scoped>
  .footer-custom {
    background-color: #282c34; /* Equivalent to Tailwind's bg-gray-700 */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  @media (min-width: 768px) {
    .footer-custom {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  .footer-link {
    color: white; /* Adjust link color if necessary */
    text-decoration: none;
    padding-left: 12px; /* equivalent to px-3 in Tailwind, which is 0.75rem assuming 16px base font size */
    padding-right: 12px;
    padding-top: 8px; /* equivalent to py-2 in Tailwind, which is 0.5rem */
    padding-bottom: 8px;
    border-radius: 0.375rem; /* equivalent to rounded-md in Tailwind */
  }
  
  .footer-link:hover {
    
    background-color: gray-700;
    
}

  </style>
  