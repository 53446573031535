<template>
  <div class="mt-10">
    <Sidebar v-if="showSidebar" />
    <div v-if="!isConnected" class="reconnect-overlay">Trying to reconnect...</div>
    
    <div class="chat-box-level-1">
      <div class="chat-box-level-2">
        <div class="chat-box-title font-bold">
          Conversation
          <button @click="confirmClose" class="close-chat-history-button">
            <img src="@/assets/Chat/crossBlack.png" class="cross-black" alt="Close">
            <img src="@/assets/Chat/crossRed.png" class="cross-red" alt="Close">
          </button>
        </div>
        <div class="chat-box-messages" ref="messageContainer">
          <div v-for="msg in messages" :key="msg.message_id" class="chat-message" :class="{'chat-sender-message': msg.sender_id === userId, 'chat-receiver-message': msg.sender_id !== userId}">
            {{ msg.content }}
          </div>
        </div>
        <div class="p-4">
          <div class="flex">
            <input type="text" v-model="message" @keyup.enter="sendMessage" placeholder="Type your message here..." class="chat-box-send">
            <button @click="sendMessage" class="chat-box-send-button">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';

import Icebreaker from './Icebreaker.vue';
import Sidebar from '../Leftside/Sidebar.vue';
import 'primeicons/primeicons.css';

export default {
  components: {
    Icebreaker,
    Sidebar
  },
  data() {
    return {
      conversationId: null,
      userId: null,
      ws: null,
      message: '',
      messages: [],
      showOptions: true,
      isConnected: false,
      reconnectAttempts: 0,
      maxReconnectAttempts: 3,  // Limit reconnection attempts to 3
      windowWidth: window.innerWidth, // Track the window width
    };
  },
  computed: {
    showSidebar() {
      return this.windowWidth < 768; // Adjust this value as needed
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Initial check
    await this.fetchUserDetails();
    if (this.userId && this.conversationId) {
      this.connectWebSocket();
      this.fetchChatHistory();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    if (this.ws) {
      this.ws.close();
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    confirmClose() {
      if (confirm("Are you sure to close connection?")) {
        this.closeChatHistory();
        this.$router.push('/Panel_MustSurvey'); // Navigate to Home page after the chat history is closed
      }
    },
    async closeChatHistory() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found, user might not be logged in');
        return;
      }
      const response = await axios.delete(`${config.apiBaseUrl}/close_connection/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data);
    },
    async fetchChatHistory() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found, user might not be logged in');
        return;
      }
      const response = await axios.get(`${config.apiBaseUrl}/get_conversation_history/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.messages = response.data.chat_history_json.messages || [];
    },
    async fetchUserDetails() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found, user might not be logged in');
        return;
      }
      const userIdResponse = await axios.get(`${config.apiBaseUrl}/get_user_id/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.userId = userIdResponse.data.user_id;
      const convIdResponse = await axios.get(`${config.apiBaseUrl}/get_conversation_id/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.conversationId = convIdResponse.data.conversation_id;
      if (this.conversationId === 'Missing') {
        console.error('Failed to fetch conversation ID:', convIdResponse.data);
      }
    },
    connectWebSocket() {
      const wsUrl = `${config.apiBaseUrlWS}/ws/chat/${this.conversationId}?token=${localStorage.getItem('token')}`;
      console.log(`Connecting to WebSocket at: ${wsUrl}`);
      this.ws = new WebSocket(wsUrl);
      this.ws.onmessage = this.handleMessage;
      this.ws.onclose = this.handleWebSocketClose;
      this.ws.onerror = this.handleWebSocketError;
      this.ws.onopen = this.handleWebSocketOpen;
    },
    handleMessage(event) {
      console.log("Received raw data:", event.data);
      const messageData = JSON.parse(event.data);
      const formattedMessage = {
        ...messageData,
        content: messageData.message,
        message_id: messageData.message_id || Date.now()
      };
      this.messages.push(formattedMessage);
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    handleWebSocketOpen() {
      console.log('WebSocket connected!');
      this.isConnected = true;
      this.reconnectAttempts = 0;
    },
    handleWebSocketClose() {
      this.isConnected = false;
      console.error('WebSocket disconnected! Trying to reconnect...');
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          if (!this.isConnected) {  // Only try to reconnect if not connected
            console.log('Trying to reconnect...');
            this.connectWebSocket();
            this.reconnectAttempts++;
          }
        }, Math.pow(2, this.reconnectAttempts) * 1000); // Exponential backoff
      } else {
        console.error('Failed to reconnect after maximum attempts.');
      }
    },
    handleWebSocketError(error) {
      console.error('WebSocket error:', error);
      this.ws.close();
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    sendMessage() {
      if (this.message !== '' && this.ws) {
        const chatMessage = {
          conversation_ID: this.conversationId,
          sender_id: this.userId,
          content: this.message
        };
        this.ws.send(JSON.stringify(chatMessage));
        this.message = '';
        this.scrollToBottom();
      } else {
        console.error('WebSocket is not connected.');
      }
    }
  }
}
</script>

<style>
/* Add any necessary styles here */
</style>
