<template>
  <div class="flex min-h-screen bg-gray-100 text-gray-800">
    <!-- Sidebar -->
    <SidebarQuiz/>
  <!-- Container for the entire matchmaking survey component -->
  <div class="container mx-auto px-4 bg-gray-100">
    <!-- Header displaying the title of the survey -->
    <header class="text-center py-5">
      <h1 class="text-2xl font-bold">Matchmaking Survey</h1>
      <h1 class="text-l font-bold mb-2">Survey C</h1>
      <router-link to="/Survey_DateBuddy_A" class="survey-A-have-want-matters-options bg-orange-500 mr-1">A</router-link>
      <router-link to="/Survey_DateBuddy_B" class="survey-A-have-want-matters-options bg-orange-500 mr-1">B</router-link>
      <router-link to="/Survey_DateBuddy_C" class="survey-A-have-want-matters-options bg-orange-500 mr-1">C</router-link>
      <router-link to="/Survey_DateBuddy_D" class="survey-A-have-want-matters-options bg-orange-500 mr-1">D</router-link>
    </header>

    <!-- Conditional rendering based on the loading state -->
    <div v-if="isLoading" class="text-center">Loading...</div>
    <!-- Display error message if any errors occur during data fetching or processing -->
    <div v-if="error" class="text-red-500 text-center">{{ error }}</div>

    <!-- Main content area, displayed only when not loading and no errors, and questions are available -->
    <div v-if="!isLoading && !error && questions.length > 0">
      <div class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4">
        <!-- Displays the current question from the survey -->
        <h2 class="text-lg text-center font-bold mb-4 max-w-xl mx-auto">{{ questions[currentIndex].question }}</h2>

        <!-- Dynamic rendering of dropdowns for the current question -->
        <div class="rounded p-1"> <!-- Increase padding -->
              <div class="mb-1 flex flex-wrap justify-center gap-3">
                  <!-- Loop through each type -->
                  <div v-for="type in ['have', 'want_ideal', 'want_min', 'want_max']" :key="type" class="have-want-containers text-center">
                      <h3 class="font-bold mb-2">{{ type.toUpperCase().replace('_', ' ') }}</h3>
                      <!-- Dropdown component -->
                      <select v-model="selected[type]" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-3xl">
                          <option v-for="option in questions[currentIndex][type]" :key="option" :value="option">
                              {{ option }}
                          </option>
                      </select>
                  </div>
              </div>
          </div>

        <!-- Display importance level selection for 'have' and 'want_ideal' -->
          <div class="flex justify-center gap-4 mt-2">
            <!-- Have Matters Selection -->
            <div class="border border-black rounded-3xl p-4">
              <div class="mt-0 text-center" v-if="questions.length > 0 && questions[currentIndex].have">
                <span class="font-bold text-sm">HAVE MATTERS</span>
                <div class="flex justify-center gap-1">
                  <button v-for="i in 5" :key="`have-${i}`"
                          class="survey-A-have-want-options"
                          :class="{ 'have-want-options-on-click': importance.have === i }"
                          @click="selectImportance('have', i)">
                    {{ i }}
                  </button>
                </div>
              </div>
            </div>
            <!-- Want Ideal Matters Selection -->
            <div class="border border-black rounded-3xl p-4">
              <div class="mt-0 text-center" v-if="questions.length > 0 && questions[currentIndex].want_ideal">
                <span class="font-bold text-sm">WANT MATTERS</span>
                <div class="flex justify-center gap-1">
                  <button v-for="i in 5" :key="`want_ideal-${i}`"
                          class="survey-A-have-want-matters-options"
                          :class="{ 'bg-orange-500': importance.want_ideal.includes(i) }"
                          @click="selectImportanceWantMatters('want_ideal', i)">
                    {{ i }}
                  </button>
                </div>
              </div>
            </div>
          </div>

        <!-- Button to submit updates for the current question -->
        <div class="text-center mb-2 mt-4">
          <button class="survey-update-button"
                  @click="updateSegmentQuestions">Submit</button>
        </div>
        <!-- Notification for successful update operation -->
        <div v-if="updateSuccess" class="text-green-500 text-center">{{ updateMessage }}</div>
        <!-- Displays current progress through the survey -->
        <div class="flex flex-col items-center justify-center text-center">
          <div><strong>Current Question:</strong> {{ barcode }}</div>
            <div><strong>Total Questions:</strong> {{ totalQuestions }}</div>
        </div>
        <!-- Navigation buttons for previous and next questions centered and fixed-size -->
        <div class="survey-prev-next-container">
          <button class="survey-prev"
                  @click="prevQuestion">Previous Question</button>
          <button class="survey-next"
                  @click="updateAndNext">Next Question</button>
        </div>
      </div>
    </div>


    
    <!-- Button to fetch the final answer based on all selections -->
    <div class="flex justify-center text-center mb-2 gap-4">
    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="goRouter">Router</button>

    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="fetchAnswer">Get My Answer</button>
    
    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="goManagement">Manager</button>
    </div>
    <!-- Display the final fetched answer -->
    <div v-if="fetchedAnswer" class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div class="text-center">
        <div class="text-lg font-bold">Your Answer:</div>
      </div>
      <div class="mt-2">
        <p><strong>Have:</strong> {{ fetchedAnswer.have }}</p>
        <p><strong>Importance of Have:</strong> {{ fetchedAnswer.have_matters }}</p>
        <p><strong>Want Ideal:</strong> {{ fetchedAnswer.want_ideal }}</p>
        <p><strong>Want Min:</strong> {{ fetchedAnswer.want_min }}</p>
        <p><strong>Want Max:</strong> {{ fetchedAnswer.want_max }}</p>
        <p><strong>Importance of Want Ideal:</strong> {{ fetchedAnswer.want_matters }}</p>
      </div>
    </div>
  </div>
</div>
</template>




<style scoped>
@media (max-width: 768px) {
  /* General container adjustments */
  .container {
    width: 100%; /* Full width to avoid horizontal scrolling */
    padding: 0 15px; /* Reduce padding */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  /* Specific adjustments for survey containers */
  .have-want-containers {
    width: 80%; /* Full width containers */
    margin: 0 auto; /* Center aligning */
    padding: 10px; /* Padding for internal spacing */
  }

  /* Buttons within containers */
  .survey-A-have-want-options, .survey-A-have-want-matters-options {
    width: 100%; /* Full width for easy tapping */
    margin: 5px 0; /* Margin for spacing */
    font-size: x-small;
  }

  /* Header and titles */
  h1, h2 {
    font-size: 1.2rem; /* Smaller font size for headers */
    padding: 10px 0; /* Padding around headers */
    text-align: center; /* Center alignment */
  }

  /* Adjust the size of buttons for importance */
  button {
    padding: 8px; /* Smaller padding for buttons */
    font-size: 0.875rem; /* Smaller font size for button text */
  }
}
</style>


<script>
// fetchAnswer: Fetches a specific answer based on the current segment and barcode.
// fetchTotalQuestions: Retrieves the total number of questions available for the current segment.
// fetchQuestions: Loads questions based on the current barcode and segment.
// resetSelections: Resets user selections for the current question.
// nextQuestion: Moves to the next question, fetching new questions if necessary.
// prevQuestion: Moves to the previous question, fetching new questions if necessary.
// selectOption: Selects or deselects an option for 'have' or 'want'.
// selectImportance: Sets the importance level for a selected option.
// updateSegmentQuestions: Submits updates to the server for the current selections and loads the updated response.
// Import necessary helpers from 'vue', HTTP client 'axios', and configuration settings
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import router from '@/router'; 

import SidebarQuiz from '@/components/Default/SidebarQuiz.vue';

export default {
  methods: {
    hasSelections() {
    // Check for any 'have' selection, and any non-null 'want' selections
    const hasHaveSelection = this.selected.have !== null;
    const hasWantSelections = this.selected.want_ideal !== null || this.selected.want_min !== null || this.selected.want_max !== null;
    const hasWantImportanceSelections = this.importance.want_ideal.length > 0;
    
    return hasHaveSelection || hasWantSelections || hasWantImportanceSelections;
  },
  async updateAndNext() {
    if (this.hasSelections()) {
      try {
        await this.updateSegmentQuestions();
        this.nextQuestion();
      } catch (error) {
        console.error("Failed to update:", error);
        // Optionally handle the error, e.g., notify the user
      }
    } else {
      console.log("No selections made; moving to next question without updating.");
      this.nextQuestion();
    }
  },
}, 
  components: {
    SidebarQuiz
  },
  // setup function is the entry point for using the Composition API
  setup() {
    // Define reactive state variables using 'ref'. These will store the component's state and update the DOM reactively when their values change.
    const questions = ref([]); // holds an array of questions
    const isLoading = ref(true); // manages loading state of the component
    const error = ref(null); // holds error messages, if any
    const updateSuccess = ref(false); // indicates whether an update operation was successful
    const updateMessage = ref(""); // stores the message related to the last update operation
    const backendUrl = ref(config.apiBaseUrl); // backend URL from configuration
    const segment = ref('DateBuddy'); // current segment identifier
    const barcode = ref('1'); // current barcode value
    const token = localStorage.getItem('token'); // fetch auth token from localStorage
    const currentIndex = ref(1); // index of the currently displayed question

    // Selected options and their importance levels for the survey
    const selected = ref({ have: null, want_ideal: null, want_min: null, want_max: null });
    const importance = ref({ have: null, want_ideal: [] });



    const goRouter = () => {
      router.push('/Welcome_Router_DateBuddy');
    };

    
    const goManagement = () => {
      router.push('/Activity_Match_DateBuddy');
    };


    const totalQuestions = ref(0); // total number of questions in the current segment
    const fetchedAnswer = ref(null); // stores the fetched answer from the backend

    // Async function to fetch the final answer based on user selections
    const fetchAnswer = async () => { 
      try {
        // Parameters for the GET request
        const params = { 
          segment: segment.value, 
          barcode: barcode.value.toString()  // Convert barcode to string to ensure correct parameter format
        };
        // Axios GET request to fetch the answer
        const response = await axios.get(`${backendUrl.value}/get_own_answer_DateBuddy_C/`, {
          params,
          headers: { Authorization: `Bearer ${token}` }  // Authorization header
        });
        // Update the fetchedAnswer state with the response data
        fetchedAnswer.value = response.data;
      } catch (err) {
        console.error("Failed to fetch answer:", err);
        // Handle errors by setting a comprehensive error state
        fetchedAnswer.value = { have: "Error", have_matters: "Error", want_ideal: "Error", want_matters: "Error" , want_min: "Error", want_max: "Error" };
      }
    };

      // Async function to fetch the total number of questions
      const fetchTotalQuestions = async () => {
      try {
        // Axios GET request to fetch the total number of questions for the current segment
        const response = await axios.get(`${backendUrl.value}/number_of_questions_DateBuddy_C/`, {
            params: { segment: segment.value },
            headers: { Authorization: `Bearer ${token}` }
        });
        // Update the totalQuestions state with the response data
        totalQuestions.value = response.data;
      } catch (err) {
        console.error("Error fetching total number of questions:", err);
      }
    };

    // Async function to fetch questions for the current segment and barcode
    const fetchQuestions = async () => {
      isLoading.value = true;  // Set loading state to true at the start
      let currentBarcode = parseInt(barcode.value); // Convert barcode to integer for validation
      if (isNaN(currentBarcode)) {  // Check if barcode is a valid number
        console.error("Invalid barcode value:", barcode.value);
        currentBarcode = 0;  // Default to 0 if barcode is not a number
      }

      let params = { segment: segment.value, barcode: currentBarcode.toString() };
      try {
        const response = await axios.get(`${backendUrl.value}/retrieve_DateBuddy_C/`, {
            params: params,
            headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.length > 0) {
          questions.value = response.data;  // Update questions state with the response data
          currentIndex.value = 0;  // Reset the current index to the start
          error.value = "";  // Clear any existing error messages
          isLoading.value = false;  // Set loading state to false after data is loaded
          return true;  // Return true to indicate successful data fetch
        } else {
          error.value = "No questions available at this barcode.";
          isLoading.value = false;  // Set loading state to false if no data is available
          return false;  // Return false to indicate no data was fetched
        }
      } catch (err) {
        error.value = "Failed to load questions.";  // Update error state with a message
        console.error("Error fetching the questions:", err);
        isLoading.value = false;  // Set loading state to false on error
        return false;  // Return false to indicate fetching failed
      }
    };


    onMounted(() => {
      fetchQuestions();  // Call fetchQuestions when component mounts
      fetchTotalQuestions();  // Also fetch the total number of questions
    });
    
    
    
    // Function to reset selections and importance ratings for both 'have' and 'want'
    const resetSelections = () => {
      selected.value = { have: null, want: [] }; // Resets 'have' to null and 'want' to an empty array
      importance.value = { have: 0, want_ideal: []  }; // Resets importance of 'have' to 0 and 'want' to an empty array
      fetchedAnswer.value = null; // Clears any previously fetched answer
    };

    // Function to navigate to the next question
    const nextQuestion = () => {
        console.log("Current index before increment:", currentIndex.value);
        currentIndex.value++;
        if (currentIndex.value >= questions.value.length) {
            console.log("Fetching next question from server...");
            // Wrap around logic adjusted here
            let nextBarcode = parseInt(barcode.value) + 1;
            if (nextBarcode > totalQuestions.value) {
                nextBarcode = 1;  // Wrap to the first barcode
            }
            barcode.value = nextBarcode;
            fetchQuestions().then(() => {
                resetSelections();
                console.log("New questions loaded for barcode:", barcode.value);
            });
        } else {
            resetSelections();
            console.log("Moved to next question:", currentIndex.value);
        }
    };

    // Function to navigate to the previous question
    const prevQuestion = () => {
        console.log("Current index before decrement:", currentIndex.value);
        currentIndex.value--;
        if (currentIndex.value < 0) {
            console.log("Fetching previous question from server...");
            let prevBarcode = parseInt(barcode.value) - 1;
            if (prevBarcode < 1) {
                prevBarcode = totalQuestions.value;  // Wrap to the last barcode
            }
            barcode.value = prevBarcode;
            fetchQuestions().then(() => {
                resetSelections();
                console.log("New questions loaded for barcode:", barcode.value);
            });
        } else {
            resetSelections();
            console.log("Moved to previous question:", currentIndex.value);
        }
    };


    // Function to handle option selection for both 'have' and 'want'
    const selectOption = (type, option) => {
      console.log(`Before change: ${type}`, selected.value[type]); // Log the current state before the change
      if (type === 'want') { // Check if the type is 'want'
        const index = selected.value[type].indexOf(option); // Find the index of the option in the 'want' array
        if (index > -1) {
          selected.value[type].splice(index, 1); // Remove the option if it is already selected
        } else {
          selected.value[type].push(option); // Add the option if it is not already selected
        }
      } else { // For 'have', toggle the selection
        selected.value[type] = selected.value[type] === option ? null : option; // Toggle the selection state
      }
      console.log(`After change: ${type}`, selected.value[type]); // Log the updated state after the change
    };

    // Function to handle importance selection for both 'have' and 'want'
    const selectImportance = (type, i) => {
   console.log("Selecting importance", type, i); // Log the importance selection
   importance.value[type] = i;
  };

  const selectImportanceWantMatters = (type, i) => {
  console.log("Selecting importance", type, i); // Log the importance selection
  const index = importance.value[type].indexOf(i);
  if (index === -1) {
    importance.value[type].push(i); // Add the option if it's not already selected
  } else {
    importance.value[type].splice(index, 1); // Remove the option if it's already selected
  }
};

    // Function to update segment questions and fetch updated answers
    const updateSegmentQuestions = async () => {
      
      if (questions.value.length > 0 && currentIndex.value >= 0) { // Ensure there are questions and a valid index
        const currentQuestion = questions.value[currentIndex.value]; // Get the current question
        const payload = {
        question: currentQuestion.question,
        selectedHave: selected.value.have,
        have_matters: [importance.value.have], // Single value
        selectedWantIdeal: selected.value.want_ideal,
        want_ideal_matters: [importance.value.want_ideal], // Single value
        selectedWantMin: selected.value.want_min,
        selectedWantMax: selected.value.want_max,
        want_matters: importance.value.want_matters, // Array of selected values
        segment: segment.value,
        barcode: barcode.value
      };

          console.log("Sending payload:", payload); 

        try {
          const response = await axios.post(`${backendUrl.value}/update_questions_DateBuddy_C/`, payload, {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (response.status === 200) {
            console.log("Update successful, fetching updated answer...");
            setTimeout(() => { // Use a slight delay to allow for database updates
              fetchAnswer(); // Fetch the updated answer after a delay
            }, 500);
          } else {
            console.error("Update failed with status code:", response.status, response.data);
            updateMessage.value = "Failed to update. Please try again.";
          }
        } catch (error) {
          console.error("An error occurred during the update:", error.response ? error.response.data : error.message);
          updateMessage.value = "Update error: " + (error.response ? error.response.data.detail : error.message);
        }
      } else {
        console.error("No question selected or invalid index");
      }
    };



    // Return all the reactive states and functions, making them accessible in the template
    return {
      questions, isLoading, error, selected, importance, 
      selectOption, selectImportance, selectImportanceWantMatters, 
      updateSegmentQuestions, 
      currentIndex, nextQuestion, prevQuestion, 
      updateSuccess, updateMessage, barcode, totalQuestions, 
      fetchedAnswer,
      fetchAnswer,
      goManagement,
      goRouter 

    };
  }
};
</script>
