<template>
  <div class="flex bg-gray-100">
    <!-- Sidebar -->
    <Sidebar/>
    <div class="flex flex-col justify-start items-center w-full min-h-screen mt-10 bg-gray-100">
  <div class="card h-full content-grid">
        <div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
          <div class="p-6">
            <div class="mb-4 flex justify-between items-center"> <!-- Updated this line -->
              <h1 class="text-xl font-semibold text-gray-900">Your Balance: ${{ currentBalance }}</h1>
              <button style="width: 150px; height: 50px;" class="update-button bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      @click="goLoadBalance">
                Load Balance
              </button>
              
            </div>
            <div class="mb-4">
              <h1 class="text-xl font-semibold text-gray-900">Your Current Credits: {{ currentCredits }}</h1>
            </div>
            <div class="mb-6">
              <h2 class="text-lg font-semibold text-gray-700">Credit Management</h2>
              <div class="grid grid-cols-3 gap-4 mt-4">
                <button v-for="option in creditOptions" :key="option.product_id"
                        :class="{'credit-button-selected': selectedOption && selectedOption.product_id === option.product_id, 'credit-button': true}"
                        @click="selectOption(option)">
                  {{ option.credit }} Credits<br>$ {{ option.price }}
                </button>
              </div>
            </div>
            <div class="flex justify-center">
              <button class="update-button bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      @click="updateCredits()"
                      :disabled="!selectedOption">
                Update Credits
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import config from '@/config';
  import { useRouter } from 'vue-router';

  import Sidebar from '@/components/Default/Sidebar.vue';

export default {
  components: {
    Sidebar
  },
    setup() {
      const router = useRouter();
      const currentCredits = ref(0);
      const currentBalance = ref(0);
      const creditOptions = ref([]);
      const selectedOption = ref(null);
      const goLoadBalance = () => { router.push('/LoadBalance'); };

      const fetchBalance = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${config.apiBaseUrl}/get_person_balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          currentBalance.value = response.data.balance;
        } catch (error) {
          console.error("Error fetching balance:", error);
        }
      };
  
      const fetchCredits = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${config.apiBaseUrl}/get_person_credits`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          currentCredits.value = response.data.credits;
        } catch (error) {
          console.error("Error fetching credits:", error);
        }
      };
  
      const fetchCreditOptions = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${config.apiBaseUrl}/credit_price_table_dict`, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          creditOptions.value = response.data;
        } catch (error) {
          console.error("Error fetching credit options:", error);
        }
      };
  
      const selectOption = (option) => {
        selectedOption.value = option;
      };
  
      const updateCredits = async () => {
        if (!selectedOption.value) return;
        try {
          const token = localStorage.getItem('token');
          const response = await axios.put(`${config.apiBaseUrl}/update_person_credits`, {
            person_ID: "currentUserID",  // This should be dynamically set based on actual user ID
            credits: selectedOption.value.credit
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          });
          if (response.status === 200) {
            alert("Credits purchased successfully!");
            currentCredits.value += selectedOption.value.credit;
            // Immediately re-fetch balance to reflect the transaction
            await fetchBalance();
            selectedOption.value = null;  // Reset selection after purchase
          } else {
            alert("Failed to update credits. Please try again.");
          }
        } catch (error) {
          console.error("Error updating credits:", error);
          alert("An error occurred while updating credits.");
        }
      };

      
  
      onMounted(() => {
        fetchCredits();
        fetchCreditOptions();
        fetchBalance();
      });
  
      return {
        currentCredits,
        currentBalance,
        creditOptions,
        selectedOption,
        selectOption,
        updateCredits,
        goLoadBalance
      };
    }
  }
  </script>
  
  <style scoped>
.flex {
  display: flex;
  flex-direction: row; /* Ensures the sidebar and content are side-by-side */
}

.flex.flex-col.justify-start.items-center.w-full.min-h-screen {
  align-items: center; /* Center items horizontally within the container */
  justify-content: flex-start; /* Align items to the top */
  width: 100%;
  min-height: 100vh;
  padding-top: 10px; /* Add top padding to push content down slightly */
}

.card {
  max-width: 400px; /* Set a max width for the card */
  width: 100%; /* Make card take up all space up to max-width */
  margin: 0 auto; /* Center the card horizontally within its container */
}

.bg-white {
  display: flex;
  flex: 1; /* takes remaining space after the sidebar */
  flex-direction: column; /* stacks children vertically */
  justify-content: center; /* centers content vertically */
  align-items: center; /* centers content horizontally */
}


 /* General button styling */
.button, .credit-button, .update-button, .balance-button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
  color: white; /* Ensures text is white for all buttons */
}

/* Styling for different types of buttons */
.update-button {
  background-color: bg-orange-500; /* Default background for update buttons */
}

.credit-button, .balance-button {
  background-color: #4f554f; /* Default background for credit and balance buttons */
}

.credit-button-selected, .balance-button-selected {
  background-color: #bddbff; /* Light blue background for selected state */
}

/* Hover effects */
.button:hover, .credit-button:hover, .update-button:hover, .balance-button:hover {
  background-color: #c47309; /* A darker shade when hovered */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px); /* Moves button up slightly on hover */
}
  </style>
  