<template>
  <div>
    <Sidebar />
    <div v-if="loading" class="loading-container">
      <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
    <!-- Main Content -->
    <div v-else>
      <h1 class="activity-match-survey-title mt-5 mb-5">Management Panel</h1>
      <div class="activity-match-level-1">
        <div class="activity-match-level-2">
          <div class="activity-match-level-3">
            <!-- Container 1 -->
            <div v-if="match_current" class="activity-match-level-4">
              <h1 class="activity-match-survey-title">Survey</h1>
              <div class="activity-match-survey-row">
                <router-link to="/AnswerMap_CoffeeBuddy" class="buddy-item-survey">
                  <img src="@/assets/Panel/survey1.png" alt="Survey 1 Logo" class="segment-image-survey">
                  <h2 class="segment-logo-title">Survey Map</h2>
                </router-link>
              </div>
              <div class="status-display text-center mt-3">
                <p><strong>Surveys Status:</strong> {{ CoffeeBuddy_Status }}</p>
              </div>
              <h1 class="activity-match-status-title mt-5">Matching</h1>
              <div class="activity-match-level-5">
                <div v-if="match_current.status_DB" class="status-container">
                  <p class="status-text"><strong>Status:</strong> {{ match_current.status_DB }}</p>
                  <label class="switch">
                    <input type="checkbox" @change="toggleStatus" :checked="match_current.status_DB === 'Active'">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Container 2 -->
            <div class="activity-match-level-4">
              <h2 class="text-center font-bold text-xl">Coffee Buddy</h2>
              <div class="buddy-cards-container">
                <router-link to="/Panel_CoffeeBuddy" class="buddy-item-buddy">
                  <img src="@/assets/Logos/logoSenlikBuddy.png" alt="Date Buddy Logo" class="segment-image-buddy">
                </router-link>
              </div>
              <div class="buddy-cards-container">
                <router-link to="/Help" class="buddy-item">
                  <img src="@/assets/Panel/help.png" alt="Date Buddy Logo" class="segment-image-buddy">
                  <h2 class="segment-logo-title">Help</h2>
                </router-link>
                <router-link to="/Brief_CoffeeBuddy" class="buddy-item">
                  <img src="@/assets/Panel/info.png" alt="Date Buddy Logo" class="segment-image-buddy">
                  <h2 class="segment-logo-title">Information</h2>
                </router-link>
              </div>
            </div>

            <!-- Container 3 -->
            <div class="activity-match-level-4">
              <h1 class="activity-match-status-title">Match When?</h1>
              <div class="buddy-cards-container">
                <router-link to="/Panel_CoffeeBuddy" class="buddy-item-when">
                  <img src="@/assets/Panel/date.png" alt="Date Buddy Logo" class="segment-image-survey">
                  <h2 class="segment-logo-title text-center">28th of Each Month</h2>
                </router-link>
                <router-link to="/Panel_CoffeeBuddy" class="buddy-item-when">
                  <img src="@/assets/Panel/time.png" alt="Date Buddy Logo" class="segment-image-survey">
                  <h2 class="segment-logo-title text-center">18:45 (Türkiye)</h2>
                </router-link>
              </div>
            </div>

            <!-- Container 4 -->
            <div class="activity-match-level-4">
              <h1 class="activity-match-status-title">Your Match</h1>
              <div class="buddy-cards-container">
                <!-- Check if result exists and has match_status -->
                <div v-if="result && result.match_status == 'Found'">
                  <router-link to="/ChatBox_CoffeeBuddy" class="buddy-item-chat">
                    <img src="@/assets/Panel/chat.png" alt="Date Buddy Logo" class="segment-image-chat">
                    <h2 class="segment-logo-title text-xs text-center mt-2">Chat Room</h2>
                  </router-link>
                  <div v-if="loading2" class="text-sm">
                    Loading...
                  </div>
                  <div v-else class="text-sm text-center">
                    <p class="text-left"><strong>Match Found Status:</strong> {{ result.match_status }}</p>
                  </div>
                </div>
                <!-- Show No Chat link if match_status is not 'Found' -->
                <div v-else>
                  <router-link to="/Panel_CoffeeBuddy" class="buddy-item-chat">
                    <img src="@/assets/Panel/sandTime.png" alt="Date Buddy Logo" class="segment-image-chat">
                    <h2 class="segment-logo-title text-xs text-center mt-2">Not Yet</h2>
                  </router-link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your CSS styles here */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Adjust height as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.buddy-cards-container {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap in new rows */
  gap: 2rem;
  justify-content: center; /* Center cards within the main content area */
}

/* Toggle Switch Styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #e28009;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.status-text {
  margin-right: 10px;
}
</style>




  
  <style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh; /* Adjust height as needed */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .buddy-cards-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap in new rows */
    gap: 2rem;
    justify-content: center; /* Center cards within the main content area */
  }
  
  .buddy-item-chat {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-chat:hover {
    transform: translateY(-10px);
  }
  
  .segment-image-chat {
    width: 4rem;
    height: 4rem;
  }
  
  .buddy-item {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 8rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item:hover {
    transform: translateY(-10px);
  }
  
  .segment-image {
    width: 4rem;
    height: 4rem;
    margin-top: 20px;
  }
  
  .buddy-item-survey {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-survey:hover {
    transform: translateY(-10px);
  }
  
  .segment-image-survey {
    width: 3rem;
    height: 3rem;
    margin-top: 20px;
  }
  
  .segment-logo-title {
    font-size: 1rem;
    font-weight: 700;
  }
  
  .buddy-item-buddy {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4rem;
    height: 5rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-buddy:hover {
    transform: translateY(-0px);
  }
  
  .segment-image-buddy {
    width: 4rem;
    height: 4rem;
    margin-top: 20px;
  }
  
  .buddy-item-when {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-when:hover {
    transform: translateY(-10px);
  }
  
  .activity-match-status-title {
    font-size: 1.5rem; /* Increase font size */
    font-weight: bold; /* Set font weight to bold */
    margin-bottom: 1rem; /* Add a bottom margin of 1rem */
    text-align: center; /* Center text horizontally */
  }
  
  .activity-match-survey-title {
    font-size: 1.5rem; /* Increase font size */
    font-weight: bold; /* Set font weight to bold */
    text-align: center; /* Center text horizontally */
  }
  
  .status-container {
    display: flex;
    justify-content: center; /* Center the items horizontally */
    align-items: center; /* Align items vertically */
    gap: 1rem; /* Spacing between items */
  }
  
  .activity-match-toggle-button {
    background-color: #f97316; /* Orange button */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px; /* Adds a 5px gap on the left of the button */
    display: flex; /* Use flexbox to center the content */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    text-align: center; /* Center text alignment */
    width: auto; /* Adjust width to content size, or set a fixed width if preferred */
  }
  
  .status-text {
    margin-right: 5px; /* Adds a 5px gap on the right */
    font-size: 1rem;
  }
  
  .activity-match-level-5 {
    display: flex;
    justify-content: center; /* Center the grid itself */
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  
  .activity-match-level-1 {
    display: flex; /* Ensures flexbox layout */
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
    width: 100%; /* Full width to accommodate both containers */
    margin-top: 10px;
  }
  
  .activity-match-level-2 {
    display: flex; /* Use flex to arrange children */
    flex-direction: row; /* Align children horizontally */
    gap: 20px; /* Space between containers */
    justify-content: center; /* Center the containers horizontally */
    align-items: center; /* Align items vertically */
    width: auto; /* Adjust width based on content */
  }
  
  .activity-match-level-3 {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 2rem;
    width: 100%;
    max-width: 1000px; /* Adjust based on the parent container or viewport size */
    margin: 0 auto; /* Center the grid horizontally */
  }
  
  .activity-match-level-4 {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    width: 100%; /* Ensure it fills the grid column */
  }
  
  .activity-match-survey-row {
    display: flex; /* Flex container */
    justify-content: center; /* Center items along the main axis */
    align-items: center; /* Center items along the cross axis */
    gap: 1rem; /* Spacing between items */
  }
  
  .activity-match-toggle-button {
    background-color: #f97316; /* bg-orange-500 */
    color: #fff; /* text-white */
    font-weight: bold; /* font-bold */
    border-radius: 1.5rem; /* rounded-3xl */
    transition: background-color 0.3s;
    font-size: 0.8rem;
    width: 140px;
    height: 30px;
  }
  
  .activity-match-toggle-button:hover {
    background-color: #dd6b20; /* A slightly darker orange for hover effect */
    transform: translateY(-5px);
  }
  
  .activity-match-survey-button-final {
    background-color: #f97316; /* bg-orange-500 */
    color: #fff; /* text-white */
    font-weight: bold; /* font-bold */
    border-radius: 1.5rem; /* rounded-3xl */
    transition: background-color 0.3s;
    width: 130px;
    height: 40px;
  }
  
  .activity-match-survey-button-final:hover {
    background-color: #dd6b20; /* A slightly darker orange for hover effect */
    transform: translateY(-5px);
  }
  
  @media (max-width: 768px) {  /* Mobile screens */
    .activity-match-level-1,
    .activity-match-level-2,
    .activity-match-level-3,
    .activity-match-level-4 {
      width: 100%;  /* Full width */
      display: block;  /* Stack vertically */
      margin: 0;  /* Remove margin for full width */
      padding: 10px;  /* Padding for spacing */
    }
  
    .activity-match-survey-title, .activity-match-status-title {
      text-align: center;  /* Center titles */
      margin-top: 20px;  /* Space above titles */
    }
  
    .segment-logo-title {
      font-size: 0.7rem;  /* Larger font size */
      margin-top: 3px;
    }
  }
  </style>
  
  
<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import { useRouter } from 'vue-router';

import Sidebar from '../Leftside/Sidebar.vue';

export default {
  data() {
    return {
      CoffeeBuddy_Status: 'Loading...', // Initial default status
    };
  },
  methods: {},
  setup() {
    const match_current = ref(null);  // Initialize to null
    const loading = ref(true);  // Track loading state
    const backendUrl = ref(config.apiBaseUrl);
    const router = useRouter();

    const goButton1 = () => router.push('/Welcome_Router_CoffeeBuddy');

    onMounted(async () => {
      try {
        const response = await axios.get(`${backendUrl.value}/status_current_CoffeeBuddy/`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.data && response.data.status_DB) {
          match_current.value = { status_DB: response.data.status_DB };
        } else {
          match_current.value = { status_DB: 'Inactive' };  // Default if undefined
        }
      } catch (error) {
        console.error('Error fetching match_current:', error);
        match_current.value = { status_DB: 'Inactive' };  // Default on error
      } finally {
        loading.value = false;  // Set loading to false regardless of the outcome
      }
    });

    const toggleStatus = async () => {
      try {
        const response = await axios.put(`${backendUrl.value}/status_update_CoffeeBuddy/`, {}, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        if (response.data.message === "User updated successfully") {
          match_current.value.status_DB = response.data.new_status;
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    };

    const result = ref(null);  // Initialize result to null
    const loading2 = ref(true);  // Track loading state

    async function fetchResults() {
      try {
        const url = `${config.apiBaseUrl}/result_CoffeeBuddy/`;
        const token = localStorage.getItem('token'); // Fetch the auth token from localStorage
        const headers = {
          'Authorization': `Bearer ${token}` // Use the token for authorization header
        };
        console.log("Fetching results from URL:", url, "with token", token);
        const response = await axios.post(url, {}, { headers: headers });
        result.value = response.data;
        loading2.value = false;
      } catch (error) {
        console.error('There was an error fetching the results:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to fetch results'));
        loading2.value = false;
      }
    }

    const CoffeeBuddy_Status = ref('Loading...'); // Use ref for reactive data

    const fetchStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }
      try {
        const response = await axios.get(`${config.apiBaseUrl}/complete_status_current_CoffeeBuddy/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        CoffeeBuddy_Status.value = response.data.CoffeeBuddy_Status || 'Error2';
      } catch (error) {
        console.error('Error fetching CoffeeBuddy status:', error);
        CoffeeBuddy_Status.value = 'Error';
      }
    };

    onMounted(() => {
      fetchResults();
      fetchStatus();  
    });

    return {
      match_current,
      loading,
      toggleStatus,
      loading2,
      result,
      goButton1,
      CoffeeBuddy_Status,
      fetchStatus,
    };
  },
};
</script>