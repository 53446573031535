<template>
    <div class="bg-white py-12">
      <table class="mx-auto w-4/5 text-left border-collapse rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th class="bg-gray-200 border text-center p-3">Date</th>
            <th class="bg-gray-200 border text-center p-3">Time</th>
            <th class="bg-gray-200 border text-center p-3">Subject</th>
            <th class="bg-gray-200 border text-center p-3">Announcement</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in newsItems" :key="item.id">
            <td class="border p-3">{{ item.date }}</td>
            <td class="border p-3">{{ item.Time }}</td>
            <td class="border p-3">{{ item.Subject }}</td>
            <td class="border text p-3">{{ item.Announcement }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NewsTable',
    data() {
      return {
        newsItems: [
          { id: 1, date: '13-05-2024', Time: '02:00', 
          Subject: "Dev-Notes", 
          Announcement: 'Senlik Buddy BETA version is online. Please when you encounter any bug report it by feedback. Mobile version is not advised.' },

          { id: 2, date: '13-05-2024', Time: '02:05', 
          Subject: "Dev-Notes", 
          Announcement: 'It will be better for all of us if you can share the website with your friends at METU.' },

          { id: 3, date: '13-05-2024', Time: '02:35', 
          Subject: "New Feature", 
          Announcement: 'Profile section has new image based logos for routes. . ' },

          { id: 4, date: '14-05-2024', Time: '23:37', 
          Subject: "Bug-Fixes", 
          Announcement: 'Survey A and Survey B questions and answers are fixed. | Chat room text bar and text size problems are fixed. | Login button is deleted. | Suddent OTP invalid token error is fixed.' },

          { id: 4, date: '15-05-2024', Time: '01:24', 
          Subject: "Bug-Fixes", 
          Announcement: 'Missing links problems are fixed. | Change after the survey is fixed. | EDU Verification problem is fixed.' },

          { id: 5, date: '16-05-2024', Time: '02:35', 
          Subject: "Bug-Fixes", 
          Announcement: 'Survey C single-multiple click problems are fixed. | Spelling mistake in Survey A question is fixed.' }, 

          { id: 6, date: '17-05-2024', Time: 'XX:XX', 
          Subject: "New Feature", 
          Announcement: 'Survey Map is added. | Animated loading bars are added. | Panel section of Meet Buddy is revised.' },




        ]
      }
    }
  }
  </script>
  
  <style scoped>
  /* Ensure the table, th, and td have a unified style */
  table {
    border-collapse: separate; /* Important for spacing between rows when border radius is used */
    border-spacing: 0; /* Removes default spacing */
  }
  
  thead tr:first-child th:first-child {
    border-top-left-radius: 0.5rem; /* Rounded corners for the first header */
  }
  
  thead tr:first-child th:last-child {
    border-top-right-radius: 0.5rem; /* Rounded corners for the last header */
  }
  
  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem; /* Rounded corners for the bottom left cell */
  }
  
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem; /* Rounded corners for the bottom right cell */
  }
  </style>
  