import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Ensure this is pointing to the correct file
import store from './store/store';  // This points to src/store/store.js
import './assets/styles/tailwind.css';  // Adjust the path according to your project structure

const app = createApp(App);

app.use(router); // Use Vue Router
app.use(store); // Use Vuex Store

app.mount('#app');
