<template>
  <div class="container">
    <div class="header">Welcome to Senlik Buddy</div>
    <div v-if="loading" class="loading-container">
      <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
      </svg>
      <span class="sr-only">Loading...</span>
    </div>
    <form v-else @submit.prevent="submitTerms">
      <div class="form-group">
        <label class="checkbox">
          Okudum, anladım, kabul ediyorum.
          <a href="https://docs.google.com/document/d/1m84xK-pHpnlErlJwcP_1bBTvLPNb53aLZCIywWgS0Pg/edit?usp=sharing" class="terms-link" target="_blank">(KVKK)</a>
          <input type="checkbox" v-model="termsAccepted" name="terms">
          <span class="checkmark"></span>
        </label>
        <label class="checkbox">
          Okudum, anladım, kabul ediyorum.
          <a href="https://docs.google.com/document/d/1B1JZik3C4JBcTEBX2caIQ03dIEjNtmlp_7s_dbnRD6k/edit?usp=sharing" class="terms-link" target="_blank">(Aydınlatma Metni)</a>
          <input type="checkbox" v-model="privacyAccepted" name="privacy">
          <span class="checkmark"></span>
        </label>
      </div>
      <div v-if="!termsAccepted || !privacyAccepted" class="alert-message">
        Please accept all terms and policies
      </div>
      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>
      <button type="submit" class="submit-btn" :disabled="!termsAccepted || !privacyAccepted">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config'; // Adjust according to your configuration setup
import router from '@/router'; // Ensure this path matches your project's file structure

export default {
  data() {
    return {
      termsAccepted: false,
      privacyAccepted: false,
      successMessage: '', // New data property for success message
      loading: true, // New data property for loading state
    };
  },
  mounted() {
    this.fetchTermsStatus();
  },
  methods: {
    async fetchTermsStatus() {
      try {
        const response = await axios.get(`${config.apiBaseUrl}/get_terms_of_service/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.termsAccepted = response.data.privacy_policy_1;
        this.privacyAccepted = response.data.privacy_policy_2;
        this.loading = false; // Set loading to false after data is fetched
      } catch (error) {
        console.error('Failed to load terms status:', error);
        alert('Failed to load terms status. Please refresh the page to try again.');
        this.loading = false; // Ensure loading is set to false in case of error
      }
    },
    async submitTerms() {
      try {
        const payload = {
          privacy_policy_1: this.termsAccepted,
          privacy_policy_2: this.privacyAccepted,
        };
        const response = await axios.post(`${config.apiBaseUrl}/update_terms_of_service/`, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          },
        });

        if (response.status === 202) {
          this.successMessage = 'Terms updated successfully. You are about to be redirected.'; // Set success message
          // Update both Vuex and localStorage
          this.$store.commit('setTermsState', true);
          localStorage.setItem('acceptedTerms', true); // Ensure localStorage is also updated
          setTimeout(() => {
            router.push('/Main');  // Redirect to main page or as required
          }, 2000); // Redirect after 2 seconds
        }
      } catch (error) {
        console.error('Failed to update terms:', error.response ? error.response.data : error);
        alert('Failed to update terms');
      }
    }
  }
}
</script>



<style scoped>
.terms-link {
  color: blue;
}
body {
  background-color: #f9fafb;
  font-family: 'Arial', sans-serif;
}
.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #111827;
}
.form-group {
  margin-bottom: 20px;
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  user-select: none;
}
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: #10b981;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.submit-btn {
  width: 100%;
  background-color: #e08610;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}
.submit-btn:hover {
  background-color: #2563eb;
}
.submit-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.alert-message {
  color: red;
  margin-bottom: 10px;
}
.success-message { /* New style for the success message */
  color: green;
  margin-bottom: 10px;
  text-align: center;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
