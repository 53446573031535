<template>
  <div class="bg-gray-100 min-h-screen flex flex-col items-center justify-center">
    <div class="bg-white p-8 rounded-lg shadow-lg max-w-screen-md w-full">
      <div class="text-center mb-4">
        <h1 class="text-xl font-bold">Question / Answer Feedback</h1>
        <p>Let's vote and decide which question to use in our surveys.</p>
      </div>
      <div class="mb-4 info-container">
        <p class="font-bold text-gray-700 text-center">Question</p>
        <p class="text-gray-900 text-center mt-2">{{ question }}</p>
        <ul>
          <p class="font-bold text-gray-700 text-center mt-2">Answers</p>
          <li class="text-gray-900 text-center mt-2" v-for="(answer, index) in answers" :key="index">* {{ answer }}</li>
        </ul>
      </div>
      <!-- Display barcode -->
      <div class="text-center mb-4">
        <p class="text-gray-500">Barcode: {{ questionId }}</p>
      </div>
      <!-- Display user's vote status -->
      <div class="text-center mb-4" v-if="userVoteStatus">
        <p class="text-gray-500">Your Vote: {{ userVoteStatus }}</p>
      </div>
      <!-- Display alert message -->
      
      <div class="flex justify-between items-center flex-wrap">
        <button @click="prevQuestion" class="bg-gray-200 p-2 rounded-lg hover:bg-gray-300 mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <button @click="vote('Disapproved')" class="bg-red-200 p-4 rounded-lg hover:bg-red-300 mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <button @click="vote('Approved')" class="bg-green-200 p-4 rounded-lg hover:bg-green-300 mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
        </button>
        <button @click="nextQuestion" class="bg-gray-200 p-2 rounded-lg hover:bg-gray-300 mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
      <div v-if="alertVisible" class="text-center mb-4">
        <p class="text-gray-500">{{ alertMessage }}</p>
      </div>
      <!-- Dropdown for selecting question -->
      <div class="text-center mt-5 ">
        <select v-model="selectedQuestion" class="bg-gray-200 p-2 rounded-lg mb-2">
          <option v-for="n in totalQuestions" :key="n" :value="n">Question {{ n }}</option>
        </select>
        <button @click="goToSelectedQuestion" class="bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 mb-2 md:ml-2">Go</button>
        <router-link to="/FormQuestion" class="justify-center flex mt-4">
          <img src="@/assets/Votes/feedback.png" alt="Survey 1 Logo" class="segment-image">
        </router-link>
        <router-link to="/FormQuestion" class="justify-center flex mt-4">
          <h2 class="text-center ml-2 items-center text-gray-700">Add Your Question</h2>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';

const backendUrl = config.apiBaseUrl;

export default {
  data() {
    return {
      token: localStorage.getItem('token'), // JWT token from local storage.
      questionId: 1, // Initialize with the first question barcode
      question: null,
      answers: [],   
      userVoteStatus: '', // Store user's vote status
      totalQuestions: 0, // Store the total number of questions
      selectedQuestion: 1, // Selected question from dropdown
      alertMessage: '', // New property for alert message
      alertVisible: false, // New property to control alert visibility
    };
  },
  created() {
    this.fetchTotalQuestions().then(() => {
      this.fetchQuestion();
    });
  },
  methods: {
    async fetchTotalQuestions() {
      try {
        const response = await axios.get(`${backendUrl}/total_vote_questions/`);
        this.totalQuestions = response.data.total_number_questions;
      } catch (error) {
        console.error('Error fetching total questions:', error);
      }
    },
    calculateNewBarcode(direction) {
      if (direction === 'next') {
        return this.questionId >= this.totalQuestions ? 1 : this.questionId ;
      } else {
        return this.questionId <= 1 ? this.totalQuestions : this.questionId ;
      }
    },
    async fetchQuestion(direction = 'next') {
      try {
        const newBarcode = this.calculateNewBarcode(direction);
        const response = await axios.get(`${backendUrl}/retrieve_question/`, {
          params: { barcode: newBarcode, direction },
          headers: { Authorization: `Bearer ${this.token}` },
        });

        console.log('Response Data:', response.data); // Debugging statement
        this.question = response.data.question;
        this.questionId = response.data.barcode;
        this.answers = response.data.answers; // Ensure answers are assigned

        console.log('Answers:', this.answers); // Debugging statement
        this.fetchUserVoteStatus(); // Fetch user's vote status after fetching question
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    },
    async fetchQuestionById(barcode) {
      try {
        barcode = parseInt(barcode); // Convert to integer
        console.log("barcode", barcode);
        const response = await axios.get(`${backendUrl}/retrieve_question/`, {
          params: { barcode, direction: 'none' },
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.question = response.data.question;
        this.questionId = response.data.barcode;
        this.fetchUserVoteStatus(); // Fetch user's vote status after fetching question
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    },
    async vote(action) {
      try {
        await axios.post(`${backendUrl}/submit_question/`, {
          question: this.question,
          barcode: this.questionId,
          vote: action,
        }, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.alertMessage = `You have ${action} the question. You are redirected to the next question.`;
        this.alertVisible = true;
        setTimeout(() => {
          this.alertVisible = false;
          this.fetchQuestion('next'); // Move to next question after voting
        }, 3000); // Hide alert message after 3 seconds
      } catch (error) {
        console.error('Error voting:', error);
      }
    },
    async fetchUserVoteStatus() {
      try {
        const response = await axios.get(`${backendUrl}/retrieve_own_answer/`, {
          params: { barcode: this.questionId },
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.userVoteStatus = response.data.vote;
      } catch (error) {
        console.error('Error fetching user vote status:', error);
        this.userVoteStatus = 'No answer'; // Default message if error occurs
      }
    },
    prevQuestion() {
      this.fetchQuestion('prev');
      this.alertVisible = false;
    },
    nextQuestion() {
      this.fetchQuestion('next');
      this.alertVisible = false;
    },
    goToSelectedQuestion() {
      this.fetchQuestionById(this.selectedQuestion);
    },
  },
};
</script>

<style scoped>
body {
  font-family: 'Roboto', sans-serif;
}

.buddy-item {
  width: 2.5rem;
  height: 2.5rem;
}

.segment-image {
  width: 2rem;
  height: 2rem;
}
</style>
