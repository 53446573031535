<template>
  <div class="content-container">
    <div class="main-content-wrapper">



      <div class="content-area-Game21">
        <div class="card-1 h-full content-grid">
          <div class="justify-center flex">
            <img 
              :src="currentBookImage" 
              class="game21-logo-submit mr-2 book-toggle" 
              @click="toggleTipsVisibility"
              @mouseover="bookHovering = true"
              @mouseleave="bookHovering = false">
          </div>
          <div v-if="areTipsVisible">
            <p class="mb-2 text-left text-gray-700"><strong>1-)</strong> Say hi.</p>
            <p class="mb-2 text-left text-gray-700"><strong>2-)</strong> Ask question from Icebreaker.</p>
            <p class="mb-2 text-left text-gray-700"><strong>3-)</strong> Say let's play 2 Truths & a Lie.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="main-content-wrapper">
      <div class="content-area-Game21-2">
        <div class="card-2 h-full content-grid">
          <div class="justify-center flex">
            <img 
              :src="currentEyeImage" 
              class="game21-logo-submit mr-2 eye-toggle" 
              @click="toggleVisibility"
              @mouseover="hovering = true"
              @mouseleave="hovering = false">
          </div>

          <div v-if="isVisible">
            <div class="mb-2 text-gray-700 flex items-center">
              <img src="@/assets/Dynamic/questionOrange.png" class="game21-logo-submit mr-2">
              <p class="text-left">Icebreaker Questions</p>
            </div>
            <div class="mb-2 text-gray-700 flex items-center">
              <img src="@/assets/Dynamic/playBlack.png" class="game21-logo-submit mr-2">
              <p class="text-left">2 Truths and a Lie</p>
            </div>
            <div class="mb-2 text-gray-700 flex items-center">
              <img src="@/assets/Dynamic/robotOrange.png" class="game21-logo-submit mr-2">
              <p class="text-left">Wing Buddy AI</p>
            </div>
            <div class="mb-2 text-gray-700 flex items-center">
              <img src="@/assets/Dynamic/infoBlack.png" class="game21-logo-submit mr-2">
              <p class="text-left">Details</p>
            </div>
            <div class="mb-2 text-gray-700 flex items-center">
              <img src="@/assets/Chat/crossRed.png" class="game21-logo-submit mr-2">
              <p class="text-left">Close chat permanently</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

.content-area-Game21 {
  width: 100%; /* Full width on mobile */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-area-Game21-2 {
  width: 100%; /* Full width on mobile */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>


<style scoped>
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem; /* Add padding for mobile */
  box-sizing: border-box;
}

.main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem; /* Reduce gap for mobile */
  box-sizing: border-box;
}

.card-1 {
  background-color: white;
  border-radius: 8px;
  padding: 1rem; /* Adjust padding */
  width: 100%; /* Make cards full width on mobile */
  max-width: 350px; /* Limit the maximum width */
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for children */
  height: 150px; /* Fixed height for the card */
  overflow: hidden; /* Hide overflow */
}
.card-2 {
  background-color: white;
  border-radius: 8px;
  padding: 1rem; /* Adjust padding */
  width: 100%; /* Make cards full width on mobile */
  max-width: 350px; /* Limit the maximum width */
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for children */
  height: 250px; /* Fixed height for the card */
  overflow: hidden; /* Hide overflow */
}

.section-title {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.game21-logo-submit {
  width: 2.5rem; /* Adjust size for mobile */
  height: 2.5rem; /* Adjust size for mobile */
  transition: transform 0.2s;
}

@media (min-width: 768px) {
  .main-content-wrapper {
    gap: 3rem; /* Restore gap for larger screens */
  }
}
</style>



<style scoped>


@media (min-width: 768px) {
  .main-content-wrapper {
    gap: 3rem; /* Restore gap for larger screens */
  }
}
</style>

<script>
export default {
  data() {
    return {
      isVisible: true, // Initially show the section
      hovering: false, // Track hover state
      areTipsVisible: true, // Initially show the tips section
      bookHovering: false // Track book hover state
    };
  },
  computed: {
    currentEyeImage() {
      if (this.hovering) {
        return require('@/assets/Chat_Info/eyeHoverOrange.png');
      } else if (this.isVisible) {
        return require('@/assets/Chat_Info/eyeOpenOrange.png');
      } else {
        return require('@/assets/Chat_Info/eyeClosedBlack.png');
      }
    },
    currentBookImage() {
      if (this.bookHovering) {
        return require('@/assets/Chat_Info/bookHoverOrange.png');
      } else if (this.areTipsVisible) {
        return require('@/assets/Chat_Info/bookOpenOrange.png');
      } else {
        return require('@/assets/Chat_Info/bookCloseOrange.png');
      }
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    toggleTipsVisibility() {
      this.areTipsVisible = !this.areTipsVisible;
    }
  }
}
</script>
