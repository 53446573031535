<template>
    <div class="container bg-white shadow-md rounded-lg p-6 mt-10">
      <h1 class="text-2xl font-bold mb-6 text-center ">Survey Map</h1>
      <div v-if="loading" class="loading-container">
        <div role="status">
          <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <table v-else class="table">
        <thead>
          <tr>
            <th>Survey</th>
            <th>Total</th>
            <th>Yours</th>
            <th>Status</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="center-align">Survey A</td>
            <td class="center-align">{{ surveyQuestions.total_answers_A }}</td>
            <td class="center-align">{{ surveyAnswers.survey_answers_A }}</td>
            <td class="center-align">{{ surveyStatus.survey_status_A }}</td>
            <td class="center-align">
              <router-link class="answer-map-button" to="/Survey_MustSurvey_A">
                Go To
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="center-align">Survey B</td>
            <td class="center-align">{{ surveyQuestions.total_answers_B }}</td>
            <td class="center-align">{{ surveyAnswers.survey_answers_B }}</td>
            <td class="center-align">{{ surveyStatus.survey_status_B }}</td>
            <td class="center-align">
              <router-link class="answer-map-button" to="/Survey_MustSurvey_B">
                Go To
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="center-align">Survey C</td>
            <td class="center-align">{{ surveyQuestions.total_answers_C }}</td>
            <td class="center-align">{{ surveyAnswers.survey_answers_C }}</td>
            <td class="center-align">{{ surveyStatus.survey_status_C }}</td>
            <td class="center-align">
              <router-link class="answer-map-button" to="/Survey_MustSurvey_C">
                Go To
              </router-link>
            </td>
          </tr>
          <tr>
            <td class="center-align">Survey D</td>
            <td class="center-align">{{ surveyQuestions.total_answers_D }}</td>
            <td class="center-align">{{ surveyAnswers.survey_answers_D }}</td>
            <td class="center-align">{{ surveyStatus.survey_status_D }}</td>
            <td class="center-align">
              <router-link class="answer-map-button" to="/Survey_MustSurvey_D">
                Go To
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="justify-center flex mt-5">
      <router-link class="manager-panel-button" to="/Panel_MustSurvey">
        Management Panel
        </router-link>
    </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import config from '@/config'
  
  export default {
    name: 'SurveyStatistics',
    data() {
      return {
        surveyQuestions: {
          total_answers_A: '',
          total_answers_B: '',
          total_answers_C: '',
          total_answers_D: '',
        },
        surveyAnswers: {
          survey_answers_A: '',
          survey_answers_B: '',
          survey_answers_C: '',
          survey_answers_D: '',
        },
        surveyStatus: {
          survey_status_A: '',
          survey_status_B: '',
          survey_status_C: '',
          survey_status_D: '',
        },
        token: localStorage.getItem('token'),
        loading: true, // Add a loading state
      }
    },
  
    async created() {
      console.log('Token:', this.token);
      const backendUrl = config.apiBaseUrl;
      try {
        const response = await axios.get(`${backendUrl}/get_answer_eval_MustSurvey/`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        console.log('Response:', response.data);
        this.surveyQuestions  = response.data.survey_Questions;
        this.surveyAnswers    = response.data.survey_Answers;
        this.surveyStatus     = response.data.survey_Status;
      } catch (error) {
        console.error("Error fetching survey data:", error);
        if (error.response) {
          console.error("Error details:", error.response.data);
        }
      } finally {
        this.loading = false; // Set loading to false once data is fetched
      }
    }
  }
  </script>
  
  <style scoped>
    .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh; /* Adjust height as needed */
  }


  .answer-map-button {
    background-color: #e36c0a; /* bg-gray-700 */
    color: #fff; /* text-white */
    font-weight: bold; /* font-bold */
    font-size: small;
    padding: 0.25rem 0.5rem; /* py-2 px-4 */
    border-radius: 1.5rem; /* rounded-3xl */
    display: inline-block;
    text-align: center;
    line-height: 2rem; /* Adjust to vertically center text */
    /* Hover effect */
    transition: background-color 0.3s, transform 0.3s;
    width: 60px;
  }
  .answer-map-button:hover {
    background-color: #1a202c; /* hover:bg-gray-900 */
    transform: translateY(-2px);
  }

  .manager-panel-button {
    background-color: #4a5568; /* bg-gray-700 */
    color: #fff; /* text-white */
    font-weight: bold; /* font-bold */
    padding: 0.25rem 0.5rem; /* py-2 px-4 */
    border-radius: 1.5rem; /* rounded-3xl */
    display: inline-block;
    text-align: center;
    line-height: 2rem; /* Adjust to vertically center text */
    /* Hover effect */
    transition: background-color 0.3s, transform 0.3s;
    width: 200px;
  }
  .manager-panel-button:hover {
    background-color: #1a202c; /* hover:bg-gray-900 */
    transform: translateY(-2px);
  }
  body {
    font-family: 'Arial', sans-serif;
  }
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  .table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 0.5rem; /* Add rounded corners to the table */
    overflow: hidden;
  }
  .table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    vertical-align: middle; /* Ensures vertical centering of content */
  }
  .table th {
    background-color: #f88d00;
    text-align: center; /* Center the text in header cells */
  }
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  .table tr:hover {
    background-color: #ddd;
  }
  .center-align {
    text-align: center;
  }
  .table thead tr:first-child th:first-child {
    border-top-left-radius: 0.5rem;
  }
  .table thead tr:first-child th:last-child {
    border-top-right-radius: 0.5rem;
  }
  .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 0.5rem;
  }
  .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 0.5rem;
  }
  </style>
  

  <style scoped>

@media (max-width: 640px) {
  .table th, .table td {
    padding: 4px;
    font-size: 0.875rem;
  }
  .answer-map-button, .manager-panel-button {
    width: 100%;
    font-size: 0.75rem;
    padding: 0.5rem;
  }
}

</style>