<template>
  <!-- Displays the loading spinner if isLoading is true -->
  <div v-if="isLoading" class="loading-container">
    <svg aria-hidden="true" class="w-32 h-32 text-gray-200 animate-spin dark:text-gray-600 fill-orange-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <!-- Outer circular path of the spinner -->
      <path d="M100 50.5908C100 78.2051 77.6182 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6182 0.59082 100 22.9766 100 50.5908ZM9.08184 50.5908C9.08184 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08184 27.9921 9.08184 50.5908Z" fill="currentColor"/>
      <!-- Inner circular path of the spinner -->
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
  </div>

  <!-- Main Content, displayed when isLoading is false -->
  <div v-else>
    <div class="flex min-h-screen bg-gray-100 text-gray-800">
      <!-- Container for the entire matchmaking survey component -->
      <div class="container mx-auto px-4 bg-gray-100">
        <!-- Header displaying the title of the survey -->
        <header class="text-center py-5">
          <h1 class="text-2xl font-bold">Matchmaking Survey</h1>
          <h1 class="text-l font-bold mb-2">Survey D</h1>
          <router-link to="/Survey_MustSurvey_A" class="survey-A-have-want-matters-options bg-orange-500 mr-1">A</router-link>
          <router-link to="/Survey_MustSurvey_D" class="survey-A-have-want-matters-options bg-orange-500 mr-1">B</router-link>
          <router-link to="/Survey_MustSurvey_C" class="survey-A-have-want-matters-options bg-orange-500 mr-1">C</router-link>
          <router-link to="/Survey_MustSurvey_D" class="survey-A-have-want-matters-options bg-orange-500 mr-1">D</router-link>
        </header>
        
        <!-- Main survey content container -->
        <div class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4">
          <!-- Check if there is a response from the backend -->
          <div v-if="backendSurveyResponse">
            <!-- Display the survey question -->
            <p class="text-lg text-center font-bold mb-4 max-w-xl mx-auto">{{ backendSurveyResponse.question }}</p>
            
            <!-- Container for Have and Want options and their respective matters -->
            <div class="have-want-wrapper mb-4">
              <!-- Container for Have options and Have Matters -->
              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I HAVE</h3>
                <div class="options vertical">
                  <!-- Loop through Have options and create buttons -->
                  <button v-for="option in backendSurveyResponse.have" 
                          :key="option" 
                          @click="selectOption('have', option)"
                          :class="{'selected': selectedAnswers.have.includes(option)}"
                          class="survey-X-have-want-options">
                    {{ option }}
                  </button>
                </div>
                <!-- Have Matters -->
                <h3 v-if="!['1'].includes(barcode)" class="font-bold mb-2 mt-4">HAVE MATTERS</h3>
                <div v-if="!['1'].includes(barcode)" class="matters-options">
                  <button v-for="n in 5" 
                          :key="'have-matters-' + n" 
                          @click="toggleMatter('have_matters', n)" 
                          class="survey-X-have-want-matters-options" 
                          :class="{'selected': selectedMatters.have_matters.includes(n)}">
                    {{ n }}
                  </button>
                </div>
              </div>
              <!-- Container for Want options and Want Matters -->
              <div class="have-want-containers">
                <h3 class="font-bold mb-2">I WANT</h3>
                <div class="options vertical">
                  <!-- Loop through Want options and create buttons -->
                  <button v-for="option in backendSurveyResponse.want" 
                          :key="option" 
                          @click="selectOption('want', option)"
                          :class="{'selected': selectedAnswers.want.includes(option)}"
                          class="survey-X-have-want-options">
                    {{ option }}
                  </button>
                </div>
                <!-- Want Matters -->
                <h3 v-if="!['1'].includes(barcode)" class="font-bold mb-2 mt-4">WANT MATTERS</h3>
                <div v-if="!['1'].includes(barcode)" class="matters-options">
                  <button v-for="n in 5" 
                          :key="'want-matters-' + n" 
                          @click="toggleMatter('want_matters', n)" 
                          class="survey-X-have-want-matters-options" 
                          :class="{'selected': selectedMatters.want_matters.includes(n)}">
                    {{ n }}
                  </button>
                </div>
              </div>
            </div>

            <!-- Button to submit answers -->
            <div class="text-center my-4">
              <button class="survey-update-button" @click="updateQuestion">Update</button>
            </div>
            <!-- Display current question and total questions -->
            <div class="text-center mb-4">
              <div><strong>Current Question:</strong> {{ barcode }}</div>
              <div><strong>Total Questions:</strong> {{ totalQuestions }}</div>
            </div>
            <!-- Navigation buttons for previous and next questions -->
            <div class="survey-prev-next-container">
              <button @click="getPreviousQuestion" class="survey-prev">Previous</button>
              <button v-if="barcode != '11'" class="survey-next" @click="getNextQuestion">Submit & Next Question</button>
              <button v-if="barcode == '11'" class="survey-next" @click="submitAndNavigate">Submit & Survey</button>
            </div>
          </div>
        </div>
        <!-- Buttons to fetch user's own answers and navigate to the survey map -->
        <div class="flex justify-center text-center mb-2 gap-4">
          <button class="survey-get-go-button" @click="fetchOwnAnswers">Get My Answer</button>
          <router-link class="survey-get-go-button" to="/AnswerMap_MustSurvey">Survey Map</router-link>
        </div>
        <!-- Display the fetched answers if available -->
        <div class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4 text-center" v-if="fetchedAnswers">
          <h3 class="font-bold mb-2 text-center">Your Current Answers</h3>
          <p><strong>I Have:</strong> {{ formattedHave }}</p>
          <p><strong>I Want:</strong> {{ formattedWant }}</p>
          <p v-if="!['1'].includes(barcode)"><strong>I Have Matters:</strong> {{ formattedHaveMatters }}</p>
          <p v-if="!['1'].includes(barcode)"><strong>I Want Matters:</strong> {{ formattedWantMatters }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.bg-gray-100 {
  background-color: #f7fafc;
}
.min-h-screen {
  min-height: 100vh;
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.p-6 {
  padding: 1.5rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}




.have-want-wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.have-want-containers {
  text-align: center;
  flex: 1 1 45%;
  max-width: 100%;
}

.options.vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}



.survey-X-have-want-matters-options {
  background-color: #cbd5e0;
  color: #2d3748;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  transition: background-color 0.3s;
  
}
.survey-X-have-want-options {
  background-color: #cbd5e0;
  color: #2d3748;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  transition: background-color 0.3s;
  width: 90%;
}


.survey-X-have-want-options:hover,
.survey-X-have-want-matters-options:hover {
  background-color: #9ca3af;
}

.survey-X-have-want-options.selected,
.survey-X-have-want-matters-options.selected {
  background-color: #f97316;
  color: white;
}

.matters-options {
  display: flex;
  flex-direction: row; /* Ensure horizontal alignment */
  justify-content: center;
  gap: 5px; /* Adjust spacing as needed */
  margin-top: 1rem;
  flex-wrap: wrap; /* Ensure wrapping on smaller screens */
}

.text-center {
  text-align: center;
}

.my-4 {
  margin: 1rem 0;
}

.fetched-answers {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .have-want-wrapper {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .have-want-containers {
    width: 100%;
    margin: 0 auto;
    padding: 10px;
  }

  .survey-X-have-want-options {
    width: 100%;
    margin: 5px 0;
    font-size: x-small;
  }

  .matters-options {
    flex-direction: row; /* Ensure horizontal alignment */
    justify-content: center;
    gap: 10px; /* Adjust spacing as needed */
    margin-top: 1rem;
    flex-wrap: wrap; /* Ensure wrapping on smaller screens */
  }

  .survey-X-have-want-matters-options {
    width: 40px;
    height: 40px;
    font-size: 0.875rem;
  }

  h1, h2 {
    font-size: 1.2rem;
    padding: 10px 0;
    text-align: center;
  }

  button {
    padding: 8px;
    font-size: 0.875rem;
  }
}



</style>




<script>
import axios from 'axios';
import config from '@/config';
import router from '@/router'; 

/* 
  Function Dictionary:
  - fetchSurveyQuestion: Fetches the current survey question from the backend.
  - fetchTotalQuestions: Fetches the total number of questions in the survey.
  - fetchOwnAnswers: Fetches the user's own answers to the survey.
  - submitAndNavigate: Submits answers and navigates to the survey map.
  - getNextQuestion: Submits current answers and fetches the next question.
  - getPreviousQuestion: Fetches the previous question.
  - toggleMatter: Toggles selection for 'have_matters' and 'want_matters' options.
  - selectOption: Toggles selection for 'have' and 'want' options.
  - submitAnswers: Submits the answers to the backend.
*/

export default {
  data() {
    return {
      backendSurveyResponse: null, // Holds the response from the backend containing survey data.
      selectedAnswers: { // Stores selected "have" and "want" answers.
        have: [],
        want: []
      },
      selectedMatters: { // Stores selected "have_matters" and "want_matters" answers.
        have_matters: [],
        want_matters: []
      },
      fetchedAnswers: null, // Holds user's fetched answers.
      token: localStorage.getItem('token'), // JWT token from local storage.
      barcode: 1, // Tracks the current question.
      totalQuestions: 0, // Holds the total number of questions.
      isLoading: false, // Indicates if data is being loaded.
    };
  },
  async created() {
    await this.fetchSurveyQuestion(); // Fetches the initial survey question when the component is created.
  },
  methods: {
    // Step 1: Fetches the current survey question from the backend
    async fetchSurveyQuestion() {
      this.isLoading = true; // Shows loading spinner
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      const barcode = this.barcode;

      try {
        const response = await axios.get(`${backendUrl}/retrieve_MustSurvey_D/`, {
          params: { barcode: barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.backendSurveyResponse = response.data; // Stores survey response
        console.log('Survey question received:', this.backendSurveyResponse);
        await this.fetchTotalQuestions(); // Fetches the total number of questions
      } catch (error) {
        console.error('Error fetching survey question:', error);
      } finally {
        this.isLoading = false; // Hides loading spinner
      }
    },
    
    // Step 2: Fetches the total number of questions
    async fetchTotalQuestions() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      try {
        const response = await axios.get(`${backendUrl}/number_of_questions_MustSurvey_D/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        this.totalQuestions = response.data.total_questions; // Stores the total number of questions
      } catch (error) {
        console.error('Error fetching total questions:', error);
      }
    },
    
    // Step 3: Fetches the user's own answers
    async fetchOwnAnswers() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;
      const barcode = this.barcode;

      try {
        const response = await axios.get(`${backendUrl}/get_own_answer_MustSurvey_D/`, {
          params: { barcode: barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        this.fetchedAnswers = response.data; // Stores fetched answers
      } catch (error) {
        console.error('Error fetching own answers:', error);
      }
    },

    //
    async updateQuestion() {
      await this.submitAnswers(); // Submits the answers
      await this.fetchOwnAnswers(); // Fetches the updated survey question
    }, 

    // Step 4: Submits answers and navigates to the survey map
    async submitAndNavigate() {
      await this.submitAnswers(); // Submits the answers
      router.push('/AnswerMap_MustSurvey'); // Navigates to the survey map
    },   

    // Step 5: Submits current answers and fetches the next question
      async getNextQuestion() {
        await this.submitAnswers(); // Submits the current answers

        const backendUrl = config.apiBaseUrl;
        const token = this.token;
        const barcode = this.barcode;

        try {
          const response = await axios.get(`${backendUrl}/get_next_question_MustSurvey_D/`, {
            params: { barcode: barcode },
            headers: { Authorization: `Bearer ${token}` }
          });

          // Update the barcode and then fetch the survey question
          this.barcode = response.data;
          
          // Wait for the state to update before fetching the next question
          await this.$nextTick();
          
          // Reset selected answers and matters
          this.selectedAnswers = { have: [], want: [] };
          this.selectedMatters = { have_matters: [], want_matters: [] };
          this.fetchedAnswers = null;
          
          // Fetch the next survey question
          await this.fetchSurveyQuestion();
        } catch (error) {
          console.error('Error fetching next question:', error);
        }
      },

      // Step 6: Fetches the previous question
      async getPreviousQuestion() {
        const backendUrl = config.apiBaseUrl;
        const token = this.token;
        const barcode = this.barcode;

        try {
          const response = await axios.get(`${backendUrl}/get_previous_question_MustSurvey_D/`, {
            params: { barcode: barcode },
            headers: { Authorization: `Bearer ${token}` }
          });

          // Update the barcode and then fetch the survey question
          this.barcode = response.data;

          // Wait for the state to update before fetching the previous question
          await this.$nextTick();

          // Reset selected answers and matters
          this.selectedAnswers = { have: [], want: [] };
          this.selectedMatters = { have_matters: [], want_matters: [] };
          this.fetchedAnswers = null;

          // Fetch the previous survey question
          await this.fetchSurveyQuestion();
        } catch (error) {
          console.error('Error fetching previous question:', error);
        }
      },


    // Step 7: Toggles selection for 'have_matters' and 'want_matters' options
    toggleMatter(type, value) {
      if (type === 'have_matters') {
        // Single select logic for 'have_matters'
        if (this.selectedMatters.have_matters.includes(value)) {
          // Deselect if the value is already selected
          this.selectedMatters.have_matters = [];
        } else {
          // Select the new value
          this.selectedMatters.have_matters = [value];
        }
      } else {
        // Toggle selection for 'want_matters'
        if (this.selectedMatters[type].includes(value)) {
          this.selectedMatters[type] = this.selectedMatters[type].filter(item => item !== value);
        } else {
          this.selectedMatters[type].push(value);
        }
      }
    },

    // Step 8: Toggles selection for 'have' and 'want' options
    selectOption(type, value) {
      if (type === 'have' && this.isSingleSelectBarcode) {
        // Single select logic for specific barcodes
        if (this.selectedAnswers.have.includes(value)) {
          // Deselect if the value is already selected
          this.selectedAnswers.have = [];
        } else {
          // Select the new value
          this.selectedAnswers.have = [value];
        }
      } else {
        // Toggle selection for both 'have' and 'want' options
        if (this.selectedAnswers[type].includes(value)) {
          this.selectedAnswers[type] = this.selectedAnswers[type].filter(item => item !== value);
        } else {
          this.selectedAnswers[type].push(value);
        }
      }
    },

    // Step 9: Submits the answers to the backend
    async submitAnswers() {
      const backendUrl = config.apiBaseUrl;
      const token = this.token;

      // Check if any of the selected arrays are empty
      const isEmpty = !this.backendSurveyResponse || 
                      (this.selectedAnswers.have.length === 0 && 
                      this.selectedAnswers.want.length === 0 && 
                      this.selectedMatters.have_matters.length === 0 && 
                      this.selectedMatters.want_matters.length === 0);

      if (isEmpty) {
        console.warn('No selections made, skipping submission');
        return; // Return early if there's nothing to submit
      }

      // Prepare the payload with current answers and selections
      const answerPayload = {
        question: this.backendSurveyResponse.question,
        segment: "MustSurvey",
        barcode: this.barcode,
        have: this.selectedAnswers.have,
        want: this.selectedAnswers.want,
        have_matters: this.selectedMatters.have_matters,
        want_matters: this.selectedMatters.want_matters
      };

      try {
        const response = await axios.post(`${backendUrl}/submit_question_MustSurvey_D`, answerPayload, {
          headers: { Authorization: `Bearer ${token}` }
        });
        // Optionally fetch the updated answers after successful submission
        // await this.fetchOwnAnswers();
      } catch (error) {
        console.error('Error submitting answers:', error);
      }
    }
  },
  computed: {
    // Step 10: Checks if the current barcode requires single selection
    isSingleSelectBarcode() {
      const singleSelectBarcodes = ['1']; // Replace with actual barcodes
      return singleSelectBarcodes.includes(this.barcode);
    },
  formattedHave() {
    return this.fetchedAnswers && Array.isArray(this.fetchedAnswers.have) ? this.fetchedAnswers.have.join(", ") : "";
  },
  formattedHaveMatters() {
    return this.fetchedAnswers && Array.isArray(this.fetchedAnswers.have_matters) ? this.fetchedAnswers.have_matters.join(", ") : "";
  },
  formattedWant() {
    return this.fetchedAnswers && Array.isArray(this.fetchedAnswers.want) ? this.fetchedAnswers.want.join(", ") : "";
  },
  formattedWantMatters() {
    return this.fetchedAnswers && Array.isArray(this.fetchedAnswers.want_matters) ? this.fetchedAnswers.want_matters.join(", ") : "";
  }
}
};
</script>
