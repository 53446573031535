<template>
    <div class="flex flex-col items-center justify-center py-8">
      <div class="w-full max-w-2xl bg-custom-purple rounded-xl shadow-lg p-6">
        <div v-for="faq in faqs" :key="faq.index" class="mb-2 last:mb-0">
          <button 
            type="button"
            class="flex items-center justify-between w-full py-4 px-6 text-left text-gray-700 bg-gray-300 rounded-lg shadow focus:outline-none"
            @click="toggle(faq.index)"
          >
            <span class="text-gray-900 font-bold">{{ faq.title }}</span>
            <span :class="{'rotate-180': !isOpen(faq.index)}">▼</span>
          </button>
          <div
            v-if="isOpen(faq.index)"
            class="text-black font-bold py-4 px-6 bg-gray-200"
          >
            {{ faq.answer }}
          </div>
        </div>
        <p v-if="faqs.length === 0">Loading FAQs...</p>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import config from '@/config';
  
  const faqs = ref([]);
  const opened = ref(new Set());
  const backendUrl = ref(config.apiBaseUrl);
  
  const toggle = index => {
    if (opened.value.has(index)) {
      opened.value.delete(index);
    } else {
      opened.value.add(index);
    }
  };
  
  const isOpen = index => opened.value.has(index);
  
  onMounted(async () => {
    try {
      const response = await axios.get(`${backendUrl.value}/faq_displayer`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      faqs.value = response.data;
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  });
  </script>
  