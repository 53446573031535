<template>
    <div class="parent-container">
      <div class="icebreaker-container">
        <div class="text-center" v-if="currentQuestion">{{ currentQuestion }}</div>
        
        <div class="navigation-buttons">
          <button class="icebreaker-button" @click="decreaseBarcode">Previous</button>
          <button class="icebreaker-button-center" @click="fetchCurrentQuestion">{{ questionBarcode }}</button>
          <button class="icebreaker-button" @click="increaseBarcode">Next</button>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import config from '@/config';  // Adjust this path to correctly point to your configuration file
  
  export default {
    name: 'Icebreaker',
    data() {
      return {
        currentQuestion: '',
        people_IDs: [],
        match_type: '',
      };
    },
    methods: {
        async fetchCurrentQuestion() {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('Authentication token not found');
                return;
            }
            try {
                const response = await axios.get(`${config.apiBaseUrl}/get_icebreaker_question_DateBuddy/`, {
                headers: { Authorization: `Bearer ${token}` }
                });
                console.log("API Response:", response.data);  // Log the full API response
                this.currentQuestion = response.data.question;  // Set the question from the response
                this.questionBarcode = response.data.question_barcode;  // Set the barcode from the response
                console.log("Current Question Set To:", this.currentQuestion); 
                console.log("Question Barcode Set To:", this.questionBarcode);  // Verify the value of questionBarcode
            } catch (error) {
                console.error('Error fetching the current question:', error);
            }
            } ,
      async increaseBarcode() {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Authentication token not found');
          return;
        }
        try {
          await axios.post(`${config.apiBaseUrl}/increase_barcode_by_1_DateBuddy/`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.fetchCurrentQuestion();
        } catch (error) {
          console.error('Error increasing the barcode:', error);
        }
      },
      async decreaseBarcode() {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Authentication token not found');
          return;
        }
        try {
          await axios.post(`${config.apiBaseUrl}/decrease_barcode_by_1_DateBuddy/`, {}, {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.fetchCurrentQuestion();
        } catch (error) {
          console.error('Error decreasing the barcode:', error);
        }
      }
    },
    
    mounted() {
      this.fetchCurrentQuestion();
    }
  }
  </script>
  