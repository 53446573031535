<template>
  <div class="flex bg-gray-100">
    <Sidebar/>

    <div class="card h-full content-grid">
      <h1 class="text-center section-title">Current Status</h1>
      <div class="content-grid">
        <!-- Displaying current city and country -->
        <div class="info-container mt-5">
          <h2><strong>Current City:</strong> {{ currentCity }}</h2>
          <h2><strong>Current Country:</strong> {{ currentCountry }}</h2>
        </div>
      </div>
    </div>

    <div class="card h-full content-grid">
      <div class="content-grid">
        <h1 class="text-center section-title">Update Your City / Country</h1>
        <form @submit.prevent="submitProfile" class="max-w-sm mx-auto">
          <!-- Dropdown to select whether to update city or country -->
          <div class="input-group mb-4">
            <label for="attribute" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Attribute to Update (City or Country)</label>
            <select id="attribute" v-model="selectedAttribute" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <option value="city">City</option>
              <option value="country">Country</option>
            </select>

            <!-- Conditional display based on selection -->
            <div v-if="selectedAttribute === 'city'">
              <label for="city" class="block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Your City</label>
              <select id="city" v-model="city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value="Ankara">Ankara</option>
                <option value="KKTC">KKTC</option>
                <option value="Antalya">Antalya</option>
                <option value="İzmir">İzmir</option>
                <option value="İstanbul">İstanbul</option>
                <option value="Münih">Münih</option>
                <option value="Eindhoven">Eindhoven</option>
              </select>
            </div>
            <div v-else>
              <label for="country" class="block mt-4 mb-2 text-sm font-medium text-gray-900 dark:text-white">Select Your Country</label>
              <select id="country" v-model="country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-40 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <option value="Türkiye">Türkiye</option>
                <option value="Germany">KKTC</option>
                <option value="Germany">Almanya</option>
                <option value="Germany">Hollanda</option>
              </select>
            </div>

            <!-- Submit button -->
            <button type="submit" class="update-profile-button mt-4">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; /* Adjust height as needed */
  }

@media (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
  .card, .content-grid {
    max-width: 100%;
    margin: 20px 0; /* Adjust margin to add space between stacked cards */
  }
  .input-group select, .input-group input {
    width: 100%; /* Full-width inputs on small screens */
  }
  .max-w-sm {
    max-width: none; /* Remove max width restriction */
  }
}

.section-title {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0rem;
  width: 100%;
  margin: 0 auto;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: auto; /* Center the card horizontally */
}

.input-group {
  margin-bottom: 20px;
}
.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.input-group input, .input-group select {
  width: 100%; /* Ensure input and selects are full width */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
</style>


<style scoped>
.aligned-dropdown {
    display: flex;
    align-items: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  height: 100%; /* Make the input group full height */
}

.input-group label {
display: block;
margin-bottom: 5px;
font-weight: bold;
}
.input-group input {
width: 100%;
padding: 8px;
border: 1px solid #ddd;
border-radius: 4px;
}


.section-title {
font-weight: 700;
font-size: 2.5rem;
text-align: center;
margin-bottom: 1rem;
}

.content-grid {
display: grid;
grid-template-columns: 1fr; /* Keep it single column for stacking */
gap: 0rem;
width: 100%;
max-width: 500px; /* Control the max width of the content grid */
margin: 0 auto; /* Center the grid horizontally */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 50px auto;
}


</style>

<script>
import axios from 'axios';
import config from '@/config';
import Sidebar from '@/components/Default/Sidebar.vue';


export default {
  data() {
    return {
      selectedAttribute: 'city', // This determines which attribute to update
      city: '',
      country: '',
      currentCity: null,
      currentCountry: null,
      backendUrl: config.apiBaseUrl
    };
  },
  components: {
    Sidebar
  },
  methods: {
    async submitProfile() {
      const url = `${this.backendUrl}/update_profile_current_city/`;
      const token = localStorage.getItem('token');
      
      // Determine which data to send based on the selected attribute
      const data = this.selectedAttribute === 'city' ? { current_city_DB: this.city } : { current_country_DB: this.country };

      try {
        const response = await axios.put(url, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        alert(response.data.message);
        this.fetchCurrentProfile(); // Refresh the display after update
      } catch (error) {
        console.error('Error during API call:', error);
        alert('Error updating your profile. See console for details.');
      }
    },
    async fetchCurrentProfile() {
      try {
        const response = await axios.get(`${this.backendUrl}/display_profile_city/`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.currentCity = response.data.query.current_city_DB;
        this.currentCountry = response.data.query.current_country_DB;
      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    }
  },
  mounted() {
    this.fetchCurrentProfile();
  }
}
</script>
