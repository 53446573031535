<template>
    <div>
      <!-- Toggle Button for the Sidebar -->
      <button @click="toggleSidebar"
          class="absolute top-16 left-1 z-50 inline-flex items-center p-2 text-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          :aria-expanded="sidebarOpen">
        <span class="sr-only">Toggle Sidebar</span>
      <svg v-if="!sidebarOpen" class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5h14a1 1 0 110 2H3a1 1 0 010-2zm0 6h14a1 1 0 110 2H3a1 1 0 010-2zm0 6h14a1 1 0 110 2H3a1 1 0 010-2z"/>
      </svg>
      <!-- Times Icon when open -->
      <i v-else class="pi pi-times w-6 h-6"></i>
    </button>
      
      <!-- Sidebar -->
      <aside :class="{'translate-x-0': sidebarOpen, '-translate-x-full': !sidebarOpen}"
             class="fixed z-40 top-19 left-0 w-64 h-[calc(100vh-5rem)] bg-gray-100 dark:bg-gray-300 transition-transform duration-300 transform overflow-y-auto">
        <div class="px-8 py-4 overflow-y-auto">
          <!-- Navigation Links with Icons -->
          <ul class="space-y-4">
  

            <li>
              <router-link to="/Panel_METUFest2024Buddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Management Panel</span>
              </router-link>
            </li>
  
            <li>
              <router-link to="/Brief_METUFest2024Buddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Information</span>
              </router-link>
            </li>
  
            <li>
              <router-link to="/Chat_Match_METUFest2024Buddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Chat Room</span>
              </router-link>
            </li>
  
            <li>
              <router-link to="/Survey_METUFest2024Buddy_A" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Survey A</span>
              </router-link>
            </li>
  
            <li>
              <router-link to="/Survey_METUFest2024Buddy_B" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Survey B</span>
              </router-link>
            </li>
  
            <li>
              <router-link to="/Survey_METUFest2024Buddy_C" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Survey C</span>
              </router-link>
            </li>
  

            <li>
              <router-link to="/Survey_METUFest2024Buddy_D" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
                <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
                <span>Survey D</span>
              </router-link>
            </li>
  
  
          </ul>
        </div> 
      </aside>
    </div>
  </template>
  
  <script>
  import 'primeicons/primeicons.css'
  
  
  export default {
    name: 'SidebarChatMETUFest2024Buddy',
    data() {
      return {
        sidebarOpen: false  // Default state of the sidebar
      };
    },
    methods: {
      toggleSidebar() {
        this.sidebarOpen = !this.sidebarOpen;
      }
    }
  }
  </script>
  
  <style scoped>
  /* Styles for the button */
  button {
    position: fixed; /* Ensuring button is positioned relative to the viewport */
    top: 1rem; /* Adjust based on your actual navbar height */
    left: 1rem;
  }
  
  ul {
    padding: 0;
    margin: 0;
  }
  
  li {
    list-style: none; /* Removes bullet points */
  }
  
  /* Improved hover effect */
  button:hover {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .router-link-icon {
    margin-right: 8px; /* Space between icon and text */
  }
  </style>
  
  