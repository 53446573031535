<template>
  <div class="max-w-4xl mx-auto px-4 py-8">
    <h1 class="text-3xl font-bold text-center mb-4">Privacy Policy</h1>
    <p class="text-sm text-gray-600 text-center mb-8">Last updated: May 25, 2024</p>

    <div class="bg-white shadow rounded-lg p-6 mb-6">
      <h2 class="text-2xl font-semibold mb-4">Senlik Buddy Privacy Policy</h2>
      <p>This Privacy Policy ("Policy") explains how we, Senlik Buddy, through the website www.senlikbuddy.com and the Instagram page @senlikbuddy, protect your personal data (any information that identifies or can identify an individual) and the rules and processes we follow when processing your personal data. This Policy outlines our data protection practices in accordance with the Law on Protection of Personal Data No. 6698 ("KVKK").</p>

      <h3 class="text-xl font-semibold mb-2">1. Collection and Processing of Personal Data</h3>
      <p>At Senlik Buddy, we collect and process our users' personal data for the following purposes:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Registration and identity verification of users</li>
        <li>Management of matching processes</li>
        <li>Communication and customer relationship management</li>
        <li>Informing users about our services</li>
        <li>Conducting user satisfaction activities</li>
        <li>Ensuring security and preventing fraud</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">2. Purposes of Processing Personal Data</h3>
      <p>We process the personal data we collect for the following purposes:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Finding the best match based on users' preferences and interests</li>
        <li>Communicating with our users and managing relationships</li>
        <li>Offering products and services tailored to users' interests</li>
        <li>Conducting our activities in compliance with the law</li>
        <li>Contributing to R&D, marketing, and investment processes with anonymized data</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">3. Principles of Processing Personal Data</h3>
      <p>We adhere to the following principles when processing your personal data:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Lawfulness and fairness</li>
        <li>Accuracy and being up-to-date when necessary</li>
        <li>Processing for specific, explicit, and legitimate purposes</li>
        <li>Being relevant, limited, and proportionate to the purposes for which they are processed</li>
        <li>Being retained for the period stipulated in the relevant legislation or the period required for the purpose for which they are processed</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">4. Legal Reasons for Processing Personal Data</h3>
      <p>We process your personal data based on the legal reasons specified in Articles 5 and 6 of the KVKK. These reasons include:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Necessity for the establishment or performance of a contract</li>
        <li>Necessity for the establishment, exercise, or protection of a right</li>
        <li>Necessity for compliance with a legal obligation</li>
        <li>Necessity for the protection of our legitimate interests</li>
        <li>Your explicit consent</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">5. Transfer of Personal Data</h3>
      <p>Your personal data may be transferred to the following parties within the country and abroad:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Authorized persons, institutions, or organizations: For the purpose of fulfilling our legal obligations</li>
        <li>Service providers and business partners: For the purpose of providing and improving our services</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">6. Personal Data Security</h3>
      <p>We take necessary technical and administrative measures to ensure the security of your personal data. For example:</p>
      <ul class="list-disc list-inside mb-4">
        <li>We use intrusion detection and prevention software to detect and prevent cyber attacks</li>
        <li>We determine employees' access rights to personal data</li>
        <li>We use data loss prevention software</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">7. Rights of Data Subjects</h3>
      <p>Under Article 11 of the KVKK, you have the following rights regarding your personal data:</p>
      <ul class="list-disc list-inside mb-4">
        <li>To learn whether your personal data is processed</li>
        <li>If processed, to request information about the processing</li>
        <li>To learn the purpose of processing and whether they are used in accordance with the purpose</li>
        <li>To know the third parties to whom your personal data is transferred domestically or abroad</li>
        <li>To request the correction of incomplete or incorrect data</li>
        <li>To request the deletion or destruction of your personal data</li>
        <li>To object to any unfavorable outcome that might result from the processing of your data exclusively by automated systems</li>
        <li>To request compensation for damages arising from the unlawful processing of your personal data</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">8. Methods of Collecting Personal Data</h3>
      <p>We collect your personal data through the following methods:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Through our website www.senlikbuddy.com</li>
        <li>Through our social media accounts and email address match@senlikbuddy.com</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">9. Use of Cookies</h3>
      <p>We use cookies on our website to enhance user experience and make our site more efficient.</p>

      <h3 class="text-xl font-semibold mb-2">10. Contact</h3>
      <p>If you have any questions regarding the protection and processing of your personal data, you can contact us:</p>
      <ul class="list-disc list-inside mb-4">
        <li>Email: match@senlikbuddy.com</li>
        <li>Address: Sümer Mahallesi, 2482/2 Sokak, Sky City B Blok, No:4/1/26 Merkezefendi / Denizli</li>
      </ul>

      <h3 class="text-xl font-semibold mb-2">11. Updates</h3>
      <p>This policy may be updated when necessary. Any changes will be published on our website.</p>
      <p class="text-sm text-gray-600 mt-3">Last Updated Date: May 25, 2024</p>
      
      <p class="mt-4">
        Sources: 
        <a href="https://docs.google.com/document/d/1m84xK-pHpnlErlJwcP_1bBTvLPNb53aLZCIywWgS0Pg/edit?usp=sharing" class="terms-link" target="_blank">(KVKK)</a> and 
        <a href="https://docs.google.com/document/d/1B1JZik3C4JBcTEBX2caIQ03dIEjNtmlp_7s_dbnRD6k/edit?usp=sharing" class="terms-link" target="_blank">(Aydınlatma Metni)</a>
      </p>
    </div>

    <div class="bg-white shadow rounded-lg p-6">
      <h2 class="text-2xl font-semibold mb-4">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, you can contact us:</p>
      <ul class="list-disc list-inside">
        <li>By email: orcun@tandogan.dev</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
};
</script>

<style scoped>
.terms-link {
  color: blue;
}
</style>
