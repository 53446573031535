import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/store';  // This points to src/store/store.js



// Home
import HomePage from '../components/Homepage/HomePage.vue';
// Default 
import FAQ           from '../components/Default/FAQ.vue';
import PrivacyPolicy from '../components/Default/PrivacyPolicy.vue'; // adjust path as needed
import ContactForm   from '@/components/Homepage/ContactForm.vue';


import About from '@/components/Default/About.vue'; 

// Register 
import Register0 from '../components/reception/register/standard/Register0.vue'; 
import Register1 from '../components/reception/register/direct_google/Register1.vue'; 
// Forgot Password
import ForgotPassword from '../components/reception/register/password/ForgotPassword.vue';
// Reset Password 
import ResetPassword from '../components/reception/register/password/ChangePassword.vue'; 
// Toggle Password 
import TogglePassword from '../components/reception/register/password/TogglePassword.vue'; 


import RegisterC0Google from '../components/reception/register/otp/RegisterC0Google.vue';
import RegisterC1Google from '../components/reception/register/otp/RegisterC1Google.vue';
import RegisterCXGoogle from '../components/reception/register/otp/RegisterCXGoogle.vue';

// Terms and Service 
import TermsOfService from '../components/reception/Terms/TermsOfService.vue'; 


// Verification | Student 
import EduVerify from '@/components/reception/Verification/EduVerify.vue';
import GraduateVerify from '@/components/reception/Verification/GraduateVerify.vue'; 


// Welcome 

// All Match Management 
import Help                from '@/components/Default/Help.vue' 
import Welcome_Main        from '@/components/Maps/Welcome_Main.vue'; 
import Management_Map      from '@/components/Maps/Management_Map.vue'; 


// News
import NewsDisplayer from '@/components/Notifications/News.vue'; 


// Profile 
import ProfileDisplayer    from '../components/Profiles/ProfileDisplayer.vue';
import UpdateProfileSocial from '../components/Profiles/UpdateProfileSocial.vue';
import UpdateProfileSocialEx from '../components/Profiles/UpdateProfileSocialEx.vue';
import UpdateProfileBasic  from '../components/Profiles/UpdateProfileBasic.vue'; 
import UpdateProfileCity from '../components/Profiles/UpdateProfileCity.vue';  
import UpdateProfileUniversity from '../components/Profiles/UpdateProfileUniversity.vue';  
import DeleteProfile           from '@/components/Profiles/DeleteProfile.vue';




// Quizes | Must 
import NewSegment                  from '@/components/Maps/NewSegment.vue';
import Communication               from '@/components/Notifications/Communication.vue'; 
import FeedbackForm                from '@/components/Match/Feedback/FeedbackForm.vue';
import FormQuestion                from '@/components/Match/Feedback/FormQuestion.vue'; 
import Welcome_Router_Feedback     from '@/components/Match/Feedback/Welcome_Router.vue';
 

// Match | Event Buddy | METU Fest 2024 
//import Welcome_Router_METUFest2024     from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Welcome/Welcome_Router.vue';
//import Activity_Match_METUFest2024     from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Activity_Match/Activity_Match.vue';
import Survey_METUFest2024_A             from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Quiz/Quiz_Questions.vue';
//import Activity_Match_DateBuddy     from '@/components/Match/EventBuddy/DateBuddy/Activity_Match/Activity_Match.vue'; 
// Panel 
import Panel_METUFest2024                from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Manager/Panel.vue'; 
// Brief 
import Brief_METUFest2024                from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Information/Brief.vue'; 
// Chat
import ChatBox_METUFest2024              from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Chat/Hangar/Chat_Match.vue'; 
// Answer Map
import AnswerMap_METUFest2024              from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Answers/Map.vue';
// Chat3in1_METUFest2024Buddy
import Chat3in1_METUFest2024Buddy              from '@/components/Match/EventBuddy/Year2024/Festivals/METUFest2024/Chat/Chat3in1.vue';



//import Panel_MustSurvey   from '@/components/Match/SenlikBuddy/MustSurvey/Activity_Match/Activity_Match';
//import Welcome_Router_MustSurvey     from '@/components/Match/SenlikBuddy/MustSurvey/Welcome/Welcome_Router.vue';
import Survey_MustSurvey_A   from '@/components/Match/SenlikBuddy/MustSurvey/Quiz/Quiz_Questions_A';
import Survey_MustSurvey_B   from '@/components/Match/SenlikBuddy/MustSurvey/Quiz/Quiz_Questions_B';
import Survey_MustSurvey_C   from '@/components/Match/SenlikBuddy/MustSurvey/Quiz/Quiz_Questions_C';
import Survey_MustSurvey_D   from '@/components/Match/SenlikBuddy/MustSurvey/Quiz/Quiz_Questions_D';
// Panel 
import Panel_MustSurvey                from '@/components/Match/SenlikBuddy/MustSurvey/Manager/Panel.vue'; 
// Brief 
import Brief_MustSurvey                from '@/components/Match/SenlikBuddy/MustSurvey/Information/Brief.vue'; 
// Chat
import ChatBox_MustSurvey              from '@/components/Match/SenlikBuddy/MustSurvey/Chat/Chat_Match.vue'; 
// Answer Map
import AnswerMap_MustSurvey            from '@/components/Match/SenlikBuddy/MustSurvey/Answers/Map.vue';
// Game21 
import Game21_MustSurvey                 from '@/components/Match/SenlikBuddy/MustSurvey/TrueFalse/Game21.vue'; 
// Game21 
import Chat3in1_MustSurvey             from '@/components/Match/SenlikBuddy/MustSurvey/Chat/Chat3in1.vue';
// Dynamic Panel 
import Dynamic_Panel             from '@/components/Match/SenlikBuddy/MustSurvey/Dynamic/Dynamic_Panel.vue'; 
// WingBuddy AI 
import WingBuddyAI_MustSurvey                       from '@/components/Match/SenlikBuddy/MustSurvey/WingBuddy/AIBot.vue'; 
// ChatInfo MustSurvey
import ChatInfo_MustSurvey                          from '@/components/Match/SenlikBuddy/MustSurvey/Information/Chat_Info.vue'; 
// Chat Info



// Match | Senlik Buddy | Date Buddy 
//import Activity_Match_DateBuddy   from '@/components/Match/SenlikBuddy/DateBuddy/Activity_Match/Activity_Match';
//import Welcome_Router_DateBuddy     from '@/components/Match/SenlikBuddy/DateBuddy/Welcome/Welcome_Router.vue';
import Survey_DateBuddy_A   from '@/components/Match/SenlikBuddy/DateBuddy/Quiz/Quiz_Questions_A';
import Survey_DateBuddy_B   from '@/components/Match/SenlikBuddy/DateBuddy/Quiz/Quiz_Questions_B';
import Survey_DateBuddy_C   from '@/components/Match/SenlikBuddy/DateBuddy/Quiz/Quiz_Questions_C';
import Survey_DateBuddy_D   from '@/components/Match/SenlikBuddy/DateBuddy/Quiz/Quiz_Questions_D';
//import Activity_Match_DateBuddy     from '@/components/Match/SenlikBuddy/DateBuddy/Activity_Match/Activity_Match.vue'; 
// Panel 
import Panel_DateBuddy                from '@/components/Match/SenlikBuddy/DateBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_DateBuddy                from '@/components/Match/SenlikBuddy/DateBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_DateBuddy              from '@/components/Match/SenlikBuddy/DateBuddy/Chat/Chat_Match.vue'; 





// Match | Senlik Buddy | Coffee Buddy 
//import Welcome_Router_CoffeeBuddy     from '@/components/Match/SenlikBuddy/CoffeeBuddy/Welcome/Welcome_Router.vue';
import Survey_CoffeeBuddy_A             from '@/components/Match/SenlikBuddy/CoffeeBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_CoffeeBuddy   from '@/components/Match/SenlikBuddy/CoffeeBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_CoffeeBuddy                from '@/components/Match/SenlikBuddy/CoffeeBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_CoffeeBuddy                from '@/components/Match/SenlikBuddy/CoffeeBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_CoffeeBuddy              from '@/components/Match/SenlikBuddy/CoffeeBuddy/Chat/Chat_Match.vue'; 
// Answer 
import AnswerMap_CoffeeBuddy            from '@/components/Match/SenlikBuddy/CoffeeBuddy/Answers/Map.vue'; 




// Match | Senlik Buddy | Coffee Buddy 
//import Welcome_Router_StudyBuddy    from '@/components/Match/SenlikBuddy/StudyBuddy/Welcome/Welcome_Router.vue';
import Survey_StudyBuddy_A             from '@/components/Match/SenlikBuddy/StudyBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_StudyBuddy   from '@/components/Match/SenlikBuddy/StudyBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_StudyBuddy                from '@/components/Match/SenlikBuddy/StudyBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_StudyBuddy                from '@/components/Match/SenlikBuddy/StudyBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_StudyBuddy              from '@/components/Match/SenlikBuddy/StudyBuddy/Chat/Chat_Match.vue'; 




// Match | Fictional Buddy | Ezel Buddy
//import Welcome_Router_EzelBuddy     from '@/components/Match/FictionalBuddy/EzelBuddy/Welcome/Welcome_Router.vue';
import Survey_EzelBuddy_A             from '@/components/Match/FictionalBuddy/EzelBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_EzelBuddy     from '@/components/Match/FictionalBuddy/EzelBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_EzelBuddy                from '@/components/Match/FictionalBuddy/EzelBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_EzelBuddy                from '@/components/Match/FictionalBuddy/EzelBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_EzelBuddy              from '@/components/Match/FictionalBuddy/EzelBuddy/Chat/Chat_Match.vue'; 




// Match | Fictional Buddy | Leyla İle Mecnun Buddy
//import Welcome_Router_LeylaIleMecnunBuddy     from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Welcome/Welcome_Router.vue';
import Survey_LeylaIleMecnunBuddy_A   from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_LeylaIleMecnunBuddy     from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_LeylaIleMecnunBuddy                from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_LeylaIleMecnunBuddy                from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_LeylaIleMecnunBuddy              from '@/components/Match/FictionalBuddy/LeylaIleMecnunBuddy/Chat/Chat_Match.vue'; 



// Match | Fictional Buddy | Friends Buddy
//import Welcome_Router_FriendsBuddy     from '@/components/Match/FictionalBuddy/FriendsBuddy/Welcome/Welcome_Router.vue';
import Survey_FriendsBuddy_A   from '@/components/Match/FictionalBuddy/FriendsBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_FriendsBuddy     from '@/components/Match/FictionalBuddy/FriendsBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_FriendsBuddy                from '@/components/Match/FictionalBuddy/FriendsBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_FriendsBuddy                from '@/components/Match/FictionalBuddy/FriendsBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_FriendsBuddy              from '@/components/Match/FictionalBuddy/FriendsBuddy/Chat/Chat_Match.vue'; 




// Match | Fictional Buddy | Yedi Tepe Buddy
//import Welcome_Router_YediTepeBuddy     from '@/components/Match/FictionalBuddy/YediTepeBuddy/Welcome/Welcome_Router.vue';
import Survey_YediTepeBuddy_A   from '@/components/Match/FictionalBuddy/YediTepeBuddy/Quiz/Quiz_Questions.vue';
//import Activity_Match_YediTepeBuddy     from '@/components/Match/FictionalBuddy/YediTepeBuddy/Activity_Match/Activity_Match.vue';
// Panel 
import Panel_YediTepeBuddy                from '@/components/Match/FictionalBuddy/YediTepeBuddy/Manager/Panel.vue'; 
// Brief 
import Brief_YediTepeBuddy                from '@/components/Match/FictionalBuddy/YediTepeBuddy/Information/Brief.vue'; 
// Chat
import ChatBox_YediTepeBuddy              from '@/components/Match/FictionalBuddy/YediTepeBuddy/Chat/Chat_Match.vue'; 





// Load Credits | Balance 
import LoadCredits from '@/components/Credits/LoadCredits.vue';  
import LoadBalance from '@/components/Credits/LoadBalance.vue';

// Vote 
import Vote from '@/components/Votes/UseVote.vue';


// Test | Inventory 
import ProductDisplayer from '../components/Inventory/ProductDisplayer.vue';
import ProductCreate    from '../components/Inventory/ProductCreate.vue'; 
import ProductDelete    from '../components/Inventory/ProductDelete.vue'; 
import ProductUpdate    from '../components/Inventory/ProductUpdate.vue'; 
import ProductMain from '@/components/Inventory/ProductMain.vue'; 


const routes = [
  { path: '/secret', component: HomePage, name: 'Secret',  meta: { requiresAuth: true, requiresTerms: true } },

  // Votes 
  { path: '/Vote', component: Vote, name: 'Vote',  meta: { requiresAuth: true, requiresTerms: true } }, 
  
  // Basic 
  { path: '/FAQ',              component: FAQ,            name: 'FAQ',           }, 
  { path: '/PrivacyPolicy',    component: PrivacyPolicy,  name: 'PrivacyPolicy', },
  { path: '/ContactForm',      component: ContactForm,    name: 'ContactForm',   }, 

  // About
  { path: '/About',       component: About, name: 'About' }, 


  // ProductMain
  { path: '/Test',             component: ProductMain, name: 'ProductMain',  }, 
  
  // Reception 
  { path: '/registerVersion0',         component: Register1, name: 'Register1', }, 
  { path: '/register',         component: Register0, name: 'Register0', }, 
  { path: '/login',            component: Register0, name: 'Register0', }, 
  { path: '/forgotpassword',   component: ForgotPassword, name: 'ForgotPassword', },
  { path: '/resetpassword',    component: ResetPassword, name: 'ResetPassword', },
  { path: '/managepassword',   component: TogglePassword, name: 'TogglePassword', }, 

  { path: '/:notFound(.*)', redirect: '/'  },
  { path: '/',               component: Welcome_Main, name: 'Home',                           meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Main',           component: Welcome_Main, name: 'Welcome_Main',               meta: { requiresAuth: true, requiresTerms: true } },


  { path: '/Management_Map', component: Management_Map, name: 'Management_Map', meta: { requiresAuth: true, requiresTerms: true } },

  { path: '/TermsOfService', component: TermsOfService, name: 'TermsOfService', meta: { requiresAuth: true, } },


  { path: '/registerOld',         component: RegisterC0Google, name: 'RegisterC0Google', },
  { path: '/verify',           component: RegisterC1Google, name: 'EmailVerification', }, 
  { path: '/notification',     component: RegisterCXGoogle, name: 'RegisterCXGoogle', },

  
  // Student Verification 
  { path: '/EduVerify',        component: EduVerify, name: 'EduVerify',  meta: { requiresAuth: true, requiresTerms: true } },   
  { path: '/GraduateVerify',   component: GraduateVerify, name: 'GraduateVerify',  meta: { requiresAuth: true, requiresTerms: true } }, 




  // Welcome
  
  { path: '/Help',             component: Help,           name: 'Help',            meta: { requiresAuth: true, requiresTerms: true } }, 
  

  // News
  { path: '/NewsDisplayer',    component: NewsDisplayer, name: 'NewsDisplayer',  meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/Communication',    component: Communication, name: 'Communication',  meta: { requiresAuth: true, requiresTerms: true } }, 



  // Sample Product 
  { path: '/ProductDisplayer', component: ProductDisplayer, name: 'ProductDisplayer',  meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/ProductCreate',    component: ProductCreate,    name: 'ProductCreate',     meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/ProductDelete',    component: ProductDelete,    name: 'ProductDelete',     meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/ProductUpdate',    component: ProductUpdate,    name: 'ProductUpdate',     meta: { requiresAuth: true, requiresTerms: true } }, 
  
  


  // Profile 
  { path: '/ProfileDisplayer',        component: ProfileDisplayer,        name: 'ProfileDisplayer',         meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/UpdateProfileSocial',     component: UpdateProfileSocial,     name: 'UpdateProfileSocial',      meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/UpdateProfileSocialEx',   component: UpdateProfileSocialEx,   name: 'UpdateProfileSocialEx',    meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/UpdateProfileBasic',      component: UpdateProfileBasic,      name: 'UpdateProfileBasic',       meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/UpdateProfileCity',       component: UpdateProfileCity,       name: 'UpdateProfileCity',        meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/UpdateProfileUniversity', component: UpdateProfileUniversity, name: 'UpdateProfileUniversity',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/DeleteProfile',           component: DeleteProfile,           name: 'DeleteProfile',            meta: { requiresAuth: true, requiresTerms: true } },

  // Quizes
  
  { path: '/NewSegment',   component: NewSegment,   name: 'NewSegment',    meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/FeedbackForm', component: FeedbackForm, name: 'FeedbackForm',  meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/FormQuestion', component: FormQuestion, name: 'FormQuestion',  meta: { requiresAuth: true, requiresTerms: true } }, 

  { path: '/Welcome_Router_Feedback', component: Welcome_Router_Feedback, name: 'Welcome_Router_Feedback', meta: { requiresAuth: true, requiresTerms: true }  }, 


  // LoadCredits 
  { path: '/LoadCredits', component: LoadCredits, name: 'LoadCredits',  meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/LoadBalance', component: LoadBalance, name: 'LoadBalance',  meta: { requiresAuth: true, requiresTerms: true } }, 

  // Match | Senlik Buddy | Date Buddy 
  //{ path: '/Welcome_Router_MustSurvey',   component: Welcome_Router_MustSurvey,   name: 'Welcome_Router_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Survey_MustSurvey_A', component: Survey_MustSurvey_A, name: 'Survey_MustSurvey_A',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Survey_MustSurvey_B', component: Survey_MustSurvey_B, name: 'Survey_MustSurvey_B',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Survey_MustSurvey_C', component: Survey_MustSurvey_C, name: 'Survey_MustSurvey_C',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Survey_MustSurvey_D', component: Survey_MustSurvey_D, name: 'Survey_MustSurvey_D',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_MustSurvey',   component: Panel_MustSurvey,   name: 'Panel_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_MustSurvey',   component: Brief_MustSurvey,   name: 'Brief_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  
  // Answer Map 
  { path: '/AnswerMap_MustSurvey', component: AnswerMap_MustSurvey, name: 'AnswerMap_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } },
  // Game21
  { path: '/Game21_MustSurvey', component: Game21_MustSurvey, name: 'Game21_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } }, 




  // Wingman AI 
  { path: '/WingBuddyAI_MustSurvey', component: WingBuddyAI_MustSurvey, name: 'WingBuddyAI_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } },
  // Information Chat 
  { path: '/ChatInfo_MustSurvey', component: ChatInfo_MustSurvey, name: 'ChatInfo_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Dynamic_Panel 
  { path: '/Dynamic_Panel', component: Dynamic_Panel, name: 'Dynamic_Panel',  meta: { requiresAuth: true, requiresTerms: true } }, 
  




  // Match | Senlik Buddy | Date Buddy 
  //{ path: '/Welcome_Router_DateBuddy',   component: Welcome_Router_DateBuddy,   name: 'Welcome_Router_DateBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_DateBuddy_A, name: 'Survey_DateBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_DateBuddy_B, name: 'Survey_DateBuddy_B',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_DateBuddy_C, name: 'Survey_DateBuddy_C',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_DateBuddy_D, name: 'Survey_DateBuddy_D',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_DateBuddy',   component: Activity_Match_DateBuddy,   name: 'Activity_Match_DateBuddy'   }, 
  // Panel 
  { path: '/Panel_DateBuddy',   component: Panel_DateBuddy,   name: 'Panel_DateBuddy',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_DateBuddy',   component: Brief_DateBuddy,   name: 'Brief_DateBuddy',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion', component: ChatBox_DateBuddy, name: 'ChatBox_DateBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 

 


  // Match | Senlik Buddy | Coffee Buddy 
  //{ path: '/Welcome_Router_CoffeeBuddy',   component: Welcome_Router_CoffeeBuddy,   name: 'Welcome_Router_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/Survey_CoffeeBuddy_A',   component: Survey_CoffeeBuddy_A,   name: 'Survey_CoffeeBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_CoffeeBuddy',   component: Activity_Match_CoffeeBuddy,   name: 'Activity_Match_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_CoffeeBuddy',      component: Panel_CoffeeBuddy,   name: 'Panel_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_CoffeeBuddy',      component: Brief_CoffeeBuddy,   name: 'Brief_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',     component: ChatBox_CoffeeBuddy, name: 'ChatBox_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Answer Map
  { path: '/AnswerMap_CoffeeBuddy',     component: AnswerMap_CoffeeBuddy, name: 'AnswerMap_CoffeeBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 


  // Match | Senlik Buddy | Study Buddy 
  //{ path: '/Welcome_Router_StudyBuddy',   component: Welcome_Router_StudyBuddy,   name: 'Welcome_Router_StudyBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion',   component: Survey_StudyBuddy_A,   name: 'Survey_StudyBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_StudyBuddy',   component: Activity_Match_StudyBuddy,   name: 'Activity_Match_StudyBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_StudyBuddy',      component: Panel_StudyBuddy,   name: 'Panel_StudyBuddy' , meta: { requiresAuth: true, requiresTerms: true }  }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_StudyBuddy',      component: Brief_StudyBuddy,   name: 'Brief_StudyBuddy' , meta: { requiresAuth: true, requiresTerms: true }  }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',    component: ChatBox_StudyBuddy, name: 'ChatBox_StudyBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 

 






  // Match | Event Buddy | METU Fest 2024
  //{ path: '/Welcome_Router_METUFest2024',    component: Welcome_Router_METUFest2024,    name: 'Welcome_Router_METUFest2024',  meta: { requiresAuth: true, requiresTerms: true } }, 
  { path: '/Survey_METUFest2024Buddy_A',  component: Survey_METUFest2024_A,  name: 'Survey_METUFest2024_A',  meta: { requiresAuth: true, requiresTerms: true } },
  //{ path: '/Activity_Match_METUFest2024',    component: Activity_Match_METUFest2024,  name: 'Activity_Match_METUFest2024',  meta: { requiresAuth: true, requiresTerms: true } },
  // Panel 
  { path: '/Panel_METUFest2024Buddy',     component: Panel_METUFest2024,   name: 'Panel_METUFest2024',       meta: { requiresAuth: true, requiresTerms: true } }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_METUFest2024Buddy',     component: Brief_METUFest2024,   name: 'Brief_METUFest2024',        meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 1.1: Chat Room 
  { path: '/ChatBox_METUFest2024Buddy',   component: ChatBox_METUFest2024, name: 'ChatBox_METUFest2024',      meta: { requiresAuth: true, requiresTerms: true } }, 
  // Answer Map
  { path: '/AnswerMap_METUFest2024Buddy',     component: AnswerMap_METUFest2024, name: 'AnswerMap_METUFest2024Buddy',  meta: { requiresAuth: true, requiresTerms: true } }, 

  

  // Match | Fictional Buddy | Ezel Buddy 
  //{ path: '/Welcome_Router_EzelBuddy',   component: Welcome_Router_EzelBuddy,   name: 'Welcome_Router_EzelBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion',    component: Survey_EzelBuddy_A, name: 'Survey_EzelBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_EzelBuddy',   component: Activity_Match_EzelBuddy,   name: 'Activity_Match_EzelBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_EzelBuddy',       component: Panel_EzelBuddy,   name: 'Panel_EzelBuddy',      meta: { requiresAuth: true, requiresTerms: true } } ,
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_EzelBuddy',       component: Brief_EzelBuddy,   name: 'Brief_EzelBuddy',      meta: { requiresAuth: true, requiresTerms: true}  }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',     component: ChatBox_EzelBuddy, name: 'ChatBox_EzelBuddy',    meta: { requiresAuth: true, requiresTerms: true } }, 


  // Match | Fictional Buddy | Friends Buddy 
  //{ path: '/Welcome_Router_FriendsBuddy',   component: Welcome_Router_FriendsBuddy,   name: 'Welcome_Router_FriendsBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_FriendsBuddy_A, name: 'Survey_FriendsBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_FriendsBuddy',   component: Activity_Match_FriendsBuddy,   name: 'Activity_Match_FriendsBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_FriendsBuddy',    component: Panel_FriendsBuddy,   name: 'Panel_FriendsBuddy',      meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_FriendsBuddy',    component: Brief_FriendsBuddy,   name: 'Brief_FriendsBuddy',      meta: { requiresAuth: true, requiresTerms: true } }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',  component: ChatBox_FriendsBuddy, name: 'ChatBox_FriendsBuddy',     meta: { requiresAuth: true, requiresTerms: true } }, 



  // Match | Fictional Buddy | Leyla İle Mecnun Buddy 
  //{ path: '/Welcome_Router_LeylaIleMecnunBuddy',   component: Welcome_Router_LeylaIleMecnunBuddy,   name: 'Welcome_Router_LeylaIleMecnunBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_LeylaIleMecnunBuddy_A, name: 'Survey_LeylaIleMecnunBuddy_A',   meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_LeylaIleMecnunBuddy',   component: Activity_Match_LeylaIleMecnunBuddy,   name: 'Activity_Match_LeylaIleMecnunBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_LeylaIleMecnunBuddy',    component: Panel_LeylaIleMecnunBuddy,   name: 'Panel_LeylaIleMecnunBuddy',       meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_LeylaIleMecnunBuddy',    component: Brief_LeylaIleMecnunBuddy,   name: 'Brief_LeylaIleMecnunBuddy',       meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',  component: ChatBox_LeylaIleMecnunBuddy, name: 'ChatBox_LeylaIleMecnunBuddy',     meta: { requiresAuth: true, requiresTerms: true } }, 



  // Match | Fictional Buddy | Yedi Tepe Buddy 
  //{ path: '/Welcome_Router_YediTepeBuddy',   component: Welcome_Router_YediTepeBuddy,   name: 'Welcome_Router_YediTepeBuddy',  meta: { requiresAuth: true, requiresTerms: true } },
  { path: '/FormQuestion', component: Survey_YediTepeBuddy_A, name: 'Survey_YediTepeBuddy_A',  meta: { requiresAuth: true, requiresTerms: true } }, 
  //{ path: '/Activity_Match_YediTepeBuddy',   component: Activity_Match_YediTepeBuddy,   name: 'Activity_Match_YediTepeBuddy',  meta: { requiresAuth: true, requiresTerms: true } }, 
  // Panel 
  { path: '/Panel_YediTepeBuddy',    component: Panel_YediTepeBuddy,   name: 'Panel_YediTepeBuddy',      meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 
  // Chat 1.0: Potantial
  { path: '/Brief_YediTepeBuddy',    component: Brief_YediTepeBuddy,   name: 'Brief_YediTepeBuddy',      meta: { requiresAuth: true, requiresTerms: true }   }, 
  // Chat 1.1: Chat Room 
  { path: '/FormQuestion',  component: ChatBox_YediTepeBuddy, name: 'ChatBox_YediTepeBuddy',    meta: { requiresAuth: true, requiresTerms: true } }, 




  // Chat Game21 
  {
    path: '/Chat3in1_METUFest2024Buddy',
    component: Chat3in1_METUFest2024Buddy,
    name: 'Chat3in1_METUFest2024Buddy',
    meta: { requiresAuth: true, requiresTerms: true, hideFooter: true }
  },

  // Chat Game21 
  {
    path: '/Chat3in1_MustSurvey',
    component: Chat3in1_MustSurvey,
    name: 'Chat3in1_MustSurvey',
    meta: { requiresAuth: true, requiresTerms: true, hideFooter: true }
  },
  // Chat 1.1: Chat Room 
  { path: '/ChatBox_MustSurvey', component: ChatBox_MustSurvey, name: 'ChatBox_MustSurvey',  meta: { requiresAuth: true, requiresTerms: true } }, 
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('Route Guard Check:', store.state.isLoggedIn, store.state.acceptedTerms);

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresTerms = to.matched.some(record => record.meta.requiresTerms);
  const isLoggedIn = store.state.isLoggedIn;
  const acceptedTerms = store.state.acceptedTerms;

  if (requiresAuth && !isLoggedIn) {
    // Redirect to register if the user is not logged in and the route requires authentication
    next({ name: 'Register0' });
  } else if (requiresTerms && !acceptedTerms) {
    // Redirect to terms of service if the route requires terms acceptance
    next({ name: 'TermsOfService' });
  } else if (isLoggedIn && to.name === 'register') {
    // Redirect logged-in users to the main page if they try to access the register page
    next({ name: 'Welcome_Main' });
  } else if (!isLoggedIn && to.name === 'Main') {
    // Redirect logged-out users to the login page if they try to access the main page
    next({ name: 'Register0' });
  } else {
    next();
  }
});


export default router;