<template>
  <div class="bg-gray-100 flex items-start justify-center pt-20 min-h-screen">
    <div class="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
      <h1 class="text-xl text-center font-bold mb-6">Add or Ask Your Question</h1>
      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div class="input-group">
          <label for="feedback_subject" class="block text-gray-700 text-sm font-bold mb-2">Select Match:</label>
          <select id="feedback_subject" v-model="formData.question_subject" class="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option value="MustSurvey">Must Survey/Meet Buddy</option>
            <option value="DateBuddy">Date Buddy</option>
            <option value="StudyBuddy">Study Buddy</option>
            <option value="CoffeBuddy">Leyla İle Mecnun Buddy</option>
            <option value="CoffeBuddy">Friends Buddy</option>
            <option value="CoffeBuddy">Yedi Tepe Buddy</option>
            <option value="MustSurvey">Bug</option>
            <option value="MustSurvey">Comment</option>
          </select>
        </div>

        <div>
          <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Name (Optional):</label>
          <input type="text" id="name" v-model="formData.name" class="form-input">
        </div>
        <div>
          <label for="email" class="block text-gray-700 text-sm font-bold mb-2">Email (Optional):</label>
          <input type="text" id="email" v-model="formData.email" class="form-input">
        </div>
        <div>
          <label for="question" class="block text-gray-700 text-sm font-bold mb-2">Question:</label>
          <textarea id="question" v-model="formData.question" class="form-textarea"></textarea>
        </div>
        <div>
          <label for="answers" class="block text-gray-700 text-sm font-bold mb-2">Answers:</label>
          <textarea id="answers" v-model="formData.answers" class="form-textarea"></textarea>
        </div>
        <div class="flex justify-center"> <!-- Center the submit button -->
        <button type="submit" class="feedback-form-button">Submit</button>
        </div> 
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import config from '@/config';

export default {
  setup() {
    const formData = ref({
      question_subject: '',
      name: '',
      email: '',
      question: '',
      answers: '', 
    });

    const backendUrl = ref(config.apiBaseUrl);

    const handleSubmit = async () => {
      try {
        const url = `${backendUrl.value}/form_question/`;
        const response = await axios.post(url, formData.value, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        if (response.data.message === "CONTACT_FORM_INSERTED") {
          alert('Form submitted successfully!');
        } else {
          alert('Submission failed: ' + response.data.message);
        }
      } catch (error) {
        console.error('Failed to submit contact form:', error);
        alert('Failed to submit the form.');
      }
    };

    return { formData, handleSubmit };
  },
};
</script>

<style scoped>
.form-input, .form-textarea {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #e37a0b; /* Changed for better visibility */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%; /* Full width for better mobile responsiveness */
}
</style>
