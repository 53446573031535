<template>
  <div class="page-container">
    
    <div class="chat-game-container">
      <div class="chat-info-section">
        <ChatInfo />
      </div>
      <div class="chat-match-section">
        <ChatMatch />
      </div>
      <div class="game-section">
        <Game21 />
      </div>
    </div>
  </div>
</template>

<script>
import ChatMatch from './Chat_Match.vue';
import ChatInfo from '../Information/Chat_Info.vue';
import Game21 from '../TrueFalse/Game21.vue';


export default {
  components: {
    ChatMatch,
    ChatInfo,
    Game21,
    
  },
  data() {
    return {
      activeContent: 'yours', // Default active content
    };
  },
  methods: {
    toggleContent(content) {
      this.activeContent = content;
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
}
/* Layout and Structure Styles */
.chat-game-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
}

/* Section Styles */
.chat-info-section,
.chat-match-section,
.game-section {
  width: 100%; /* Full width for each section */
  max-width: 600px; /* Max width for each section */
  padding: 10px; /* Padding for spacing inside sections */
  box-sizing: border-box; /* Include padding in the element's total width and height */
}

/* Responsive Design Styles */
@media (max-width: 768px) {
  .chat-game-container {
    flex-direction: column; /* Stack sections vertically on smaller screens */
  }
  .chat-info-section,
  .chat-match-section,
  .game-section {
    padding: 5px; /* Reduce padding for smaller screens */
  }
}

/* Global Styles */
body {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}
</style>
