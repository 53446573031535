<template>
    <div class="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-md">
      <h1 class="text-2xl font-semibold mb-6 text-center">Delete Product</h1>
      <form @submit.prevent="handleDelete" class="flex flex-col gap-4 items-center">
        <input
          v-model="productId"
          type="text"
          placeholder="Product ID"
          class="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
        />
        <button type="submit" class="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-700">
          Delete Product
        </button>
      </form>
      <div v-if="message" class="text-center mt-4">{{ message }}</div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import config from '@/config'; 
  import { ref } from 'vue';
  
  export default {
    setup() {
      const productId = ref('');
      const message = ref('');
      const backendUrl = ref(config.apiBaseUrl); 
      console.log("Environment VUE BACKEND URL:", backendUrl.value);
  
      const handleDelete = async () => {
        try {
          const response = await axios.delete(`${backendUrl.value}/product_delete/`, {
            data: { product_id: productId.value },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // Ensure secure handling of the token
            },
          });
          message.value = response.data.message;
          productId.value = ''; // Optionally clear the input field
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust the delay as needed
        } catch (error) {
          console.error('Error deleting product:', error.response?.data?.detail || 'Unknown error');
          message.value = error.response?.data?.detail || 'An error occurred. Please try again.';
        }
      };
  
      return {
        productId,
        message,
        handleDelete,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Styles specific to this component */
  </style>
  