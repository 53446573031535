<template>
  <div class=" flex items-start justify-center min-h-screen pt-20 ">
    <div class="bg-white p-4 rounded-lg shadow-lg w-full max-w-sm">
      <h2 class="text-2xl font-bold mb-6 text-center mt-3">Forgot Password</h2>
      <div class="mb-4">
        <input
          type="email"
          placeholder="Email"
          v-model="email"
          class="w-full p-3 border border-gray-300 rounded-lg mb-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
        <div class="mt-2">
          <button
            @click="forgotPassword"
            :disabled="isButtonDisabled"
            class="w-full p-3 bg-orange-500 text-white rounded-full hover:bg-orange-600"
            :class="{ 'opacity-50 cursor-not-allowed': isButtonDisabled }"
            style="border-radius: 40px; max-width: 200px; margin: 0 auto; display: block;"
          >
            Send New Password
          </button>
        </div>
      </div>
      <!-- Display the message here -->
      <p v-if="message" class="text-center text-red-500">{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import { ref } from 'vue';

export default {
  setup() {
    const backendUrl = ref(config.apiBaseUrl);
    return {
      backendUrl
    };
  },
  data() {
    return {
      email: '',
      message: '', // For displaying the status message
      isButtonDisabled: false // For disabling the button
    };
  },
  methods: {
    async forgotPassword() {
      if (!this.email) {
        this.message = 'Please enter your email.';
        return;
      }
      this.isButtonDisabled = true; // Disable the button
      try {
        const response = await axios.post(`${this.backendUrl}/forgot_password/`, {
          email: this.email
        });
        console.log('Forgot Password response:', response.data);
        this.message = response.data.message; // Display the response message
      } catch (error) {
        console.error('Error during forgot password request:', error);
        if (error.response && error.response.data && error.response.data.detail) {
          this.message = error.response.data.detail;
        } else {
          this.message = 'An error occurred while processing your request.';
        }
      } finally {
        // Enable the button after 3 minutes
        setTimeout(() => {
          this.isButtonDisabled = false;
        }, 180000); // 180000 milliseconds = 3 minutes
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}
</style>
