<template>
  <div class="shadow-lg rounded-lg overflow-hidden mx-auto my-4 max-w-4xl">
    <table class="w-full table-fixed">
      <thead>
        <tr class="bg-gray-100">
          <th class="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product Name</th>
          <th class="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product ID</th>
          <th class="w-1/3 py-4 px-6 text-left text-gray-600 font-bold uppercase">Product Price</th>
        </tr>
      </thead>
      <tbody class="bg-white">
        <tr v-for="(item, index) in items" :key="index">
          <td class="py-4 px-6 border-b border-gray-200">{{ item.product_name }}</td>
          <td class="py-4 px-6 border-b border-gray-200">{{ item.product_id }}</td>
          <td class="py-4 px-6 border-b border-gray-200">${{ item.product_price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config'; 

export default {
  setup() {
    const items = ref([]);
    const backendUrl = ref(config.apiBaseUrl); 
    console.log("Environment VUE BACKEND URL:", backendUrl.value);

    onMounted(async () => {
      try {
        const url = `${backendUrl.value}/product_display/`; 
        console.log("Making GET request to:", url);
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, 
          },
        });
        items.value = response.data; 
      } catch (error) {
        console.error('Error fetching items:', error);
      }
    });


    return { items };
  },
};
</script>

<style scoped>
/* Styles specific to this component */
</style>
