<template>
  <div class="game21-container">
    <div class="bg-white h-full">
      <ToggleButtons @toggle="toggleContent" :activeContent="activeContent" />
      <div class="flex-grow overflow-auto p-4">
        <div v-if="loading" class="game21-loading-container"> 
        </div>
        <div v-else class="flex-col">
          <div v-if="activeContent === 'yours'" class="game21-content-wrapper flex-grow">
            <YourContent
              :userQuestions="userQuestions"
              :comparisonResults="comparisonResults"
              :isUserButtonDisabled="isUserButtonDisabled"
              :isSubmitLocked="isUserSubmitLocked"
              :areAnswerButtonsDisabled="areUserAnswerButtonsDisabled"
              :countdown="userCountdown"
              :isComparisonDisabled="isComparisonDisabled"
              :isGetResultsDisabled="isGetResultsDisabled"
              :getResultsCountdown="getResultsCountdown"
              @set-user-answer="setUserAnswer"
              @submit-user-answers="submitUserAnswers"
              @update:isUserButtonDisabled="updateIsUserButtonDisabled"
              @fetch-comparison="fetchComparison"
              @lock-submit="lockUserSubmitButton"
              @disable-answer-buttons="disableUserAnswerButtons"
            />
          </div>
          <div v-if="activeContent === 'partner'" class="game21-content-wrapper flex-grow">
            <PartnerContent
              :partnerQuestions="partnerQuestions"
              :comparisonResults="comparisonResults"
              :isPartnerButtonDisabled="isPartnerButtonDisabled"
              :isSubmitLocked="isPartnerSubmitLocked"
              :areAnswerButtonsDisabled="arePartnerAnswerButtonsDisabled"
              :countdown="partnerCountdown"
              :isComparisonDisabled="isComparisonDisabled"
              :isGetResultsDisabled="isGetResultsDisabled"
              :getResultsCountdown="getResultsCountdown"
              @set-partner-answer="setPartnerAnswer"
              @submit-partner-answers="submitPartnerAnswers"
              @update:isPartnerButtonDisabled="updateIsPartnerButtonDisabled"
              @fetch-comparison="fetchComparison"
              @lock-submit="lockPartnerSubmitButton"
              @disable-answer-buttons="disablePartnerAnswerButtons"
            />
          </div>
          <div v-if="activeContent === 'aibot'" class="game21-content-wrapper flex-grow">
            <AIBot />
          </div>
          <div class="flex justify-center gap-5 mt-4">
            
          </div>
          <div v-if="isComparisonDisabled" class="countdown-game21">
            Buttons will be enabled in {{ comparisonCountdown }} seconds
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import config from '@/config';
import ToggleButtons from './ToggleButtons.vue';
import YourContent from './YourContent.vue';
import PartnerContent from './PartnerContent.vue';
import AIBot from '../WingBuddy/AIBot.vue';
import './Game21.css';

export default {
  name: 'Game21',
  components: {
    ToggleButtons,
    YourContent,
    PartnerContent,
    AIBot,
  },
  setup() {
    const store = useStore();
    const backendUrl = ref(config.apiBaseUrl);
    const userQuestions = ref([]);
    const partnerQuestions = ref([]);
    const gameRoomID = ref(null);
    const comparisonResults = ref({});
    const token = localStorage.getItem('token');
    
    const isUserButtonDisabled = ref(false);
    const userCountdown = ref(10);
    const isPartnerButtonDisabled = ref(false);
    const partnerCountdown = ref(10);
    
    const getResultsCountdown = ref(10);
    const comparisonCountdown = ref(15);
    const activeContent = ref('icebreaker');
    const loading = ref(true);
    const isGetResultsDisabled = ref(false);
    const isComparisonDisabled = ref(false);

    const isUserSubmitLocked = ref(false); // Track the lock state for user
    const isPartnerSubmitLocked = ref(false); // Track the lock state for partner
    const areUserAnswerButtonsDisabled = ref(false); // Track the disabled state of user answer buttons
    const arePartnerAnswerButtonsDisabled = ref(false); // Track the disabled state of partner answer buttons

    const setUserAnswer = (index, answer) => {
      userQuestions.value[index].userAnswer = answer;
    };

    const setPartnerAnswer = (index, answer) => {
      partnerQuestions.value[index].partnerAnswer = answer;
    };

    const submitUserAnswers = async () => {
      isUserButtonDisabled.value = true;
      try {
        await axios.post(`${backendUrl.value}/submit-answers-own/`, {
          game_room_ID: gameRoomID.value,
          user_answers_question_1: userQuestions.value[0]?.userAnswer || "None",
          user_answers_question_2: userQuestions.value[1]?.userAnswer || "None",
          user_answers_question_3: userQuestions.value[2]?.userAnswer || "None"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        lockUserSubmitButton();
        disableUserAnswerButtons();
      } catch (error) {
        console.error('Error submitting user answers:', error);
      }
    };

    const submitPartnerAnswers = async () => {
      isPartnerButtonDisabled.value = true;
      try {
        await axios.post(`${backendUrl.value}/submit-answers-guess/`, {
          game_room_ID: gameRoomID.value,
          user_answers_question_1: partnerQuestions.value[0]?.partnerAnswer || "None",
          user_answers_question_2: partnerQuestions.value[1]?.partnerAnswer || "None",
          user_answers_question_3: partnerQuestions.value[2]?.partnerAnswer || "None"
        }, {
          headers: { Authorization: `Bearer ${token}` }
        });
        lockPartnerSubmitButton();
        disablePartnerAnswerButtons();
      } catch (error) {
        console.error('Error submitting partner answers:', error);
      }
    };

    const fetchQuestions = async (barcode) => {
      try {
        const response = await axios.get(`${backendUrl.value}/get-questions`, {
          params: { barcode },
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data;
      } catch (error) {
        console.error('Error fetching question:', error);
        return null;
      }
    };

    const getGameRoomID = async () => {
      try {
        const response = await axios.get(`${backendUrl.value}/get_game_room_ID/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        return response.data.game_room_ID;
      } catch (error) {
        console.error('Error fetching game room ID:', error);
        return null;
      }
    };

    const fetchComparison = async () => {
      try {
        const response = await axios.get(`${backendUrl.value}/compare_answers/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        comparisonResults.value = response.data;
        startComparisonCountdown();
      } catch (error) {
        console.error('Error fetching comparison data:', error);
        comparisonResults.value = {};
      }
    };

    const updateUserCountdown = (newCountdown) => {
      userCountdown.value = newCountdown;
    };

    const updateIsUserButtonDisabled = (newState) => {
      isUserButtonDisabled.value = newState;
    };

    const updatePartnerCountdown = (newCountdown) => {
      partnerCountdown.value = newCountdown;
    };

    const updateIsPartnerButtonDisabled = (newState) => {
      isPartnerButtonDisabled.value = newState;
    };

    const lockUserSubmitButton = () => {
      isUserSubmitLocked.value = true;
    };

    const lockPartnerSubmitButton = () => {
      isPartnerSubmitLocked.value = true;
    };

    const disableUserAnswerButtons = () => {
      areUserAnswerButtonsDisabled.value = true;
    };

    const disablePartnerAnswerButtons = () => {
      arePartnerAnswerButtonsDisabled.value = true;
    };

    const startGetResultsCountdown = () => {
      const interval = setInterval(() => {
        getResultsCountdown.value--;
        if (getResultsCountdown.value <= 0) {
          clearInterval(interval);
          isGetResultsDisabled.value = false;
        }
      }, 1000);
    };

    const startComparisonCountdown = () => {
      const interval = setInterval(() => {
        comparisonCountdown.value--;
        if (comparisonCountdown.value <= 0) {
          clearInterval(interval);
          isComparisonDisabled.value = false;
        }
      }, 1000);
    };

    onMounted(async () => {
      loading.value = true;
      gameRoomID.value = await getGameRoomID();
      if (gameRoomID.value) {
        const barcodes = ['1', '2', '3'];
        const fetchedQuestions = [];

        for (const barcode of barcodes) {
          const question = await fetchQuestions(barcode);
          if (question) {
            fetchedQuestions.push(question);
          }
        }

        userQuestions.value = fetchedQuestions;
        partnerQuestions.value = JSON.parse(JSON.stringify(fetchedQuestions));
      } else {
        console.error('No game room ID provided.');
      }
      loading.value = false;
      startGetResultsCountdown();
    });

    return {
      userQuestions,
      partnerQuestions,
      setUserAnswer,
      setPartnerAnswer,
      submitUserAnswers,
      submitPartnerAnswers,
      fetchComparison,
      comparisonResults,
      isUserButtonDisabled,
      userCountdown,
      isPartnerButtonDisabled,
      partnerCountdown,
      getResultsCountdown,
      comparisonCountdown,
      activeContent,
      loading,
      isGetResultsDisabled,
      isComparisonDisabled,
      isUserSubmitLocked,
      isPartnerSubmitLocked,
      areUserAnswerButtonsDisabled,
      arePartnerAnswerButtonsDisabled,
      toggleContent(content) {
        activeContent.value = content;
      },
      updateUserCountdown,
      updateIsUserButtonDisabled,
      updatePartnerCountdown,
      updateIsPartnerButtonDisabled,
      lockUserSubmitButton,
      lockPartnerSubmitButton,
      disableUserAnswerButtons,
      disablePartnerAnswerButtons,
      startGetResultsCountdown,
      startComparisonCountdown,
    };
  },
};
</script>
