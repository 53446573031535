<template>
  <div id="app" class="flex flex-col min-h-screen">
    <NavigationBar />
    <div class="flex-grow">
      <router-view />
    </div>
    <Footer v-if="showFooter" />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import NavigationBar from './components/Default/NavigationBar.vue';
import Footer from './components/Default/Footer.vue';

import './css/Survey_A.css';
import './css/Survey_D.css';
import './css/Welcome_Router.css';
import './css/Welcome_Feedback.css';
import './css/Profile.css';
import './css/Dropdown.css';
import './css/Match_Manager.css';

// Chat
import './css/Chat_Match.css';
import './css/Icebreaker.css';

export default {
  components: {
    NavigationBar,
    Footer,
  },
  setup() {
    const route = useRoute();
    const isIPhone = ref(/iPhone/i.test(navigator.userAgent));
    const showFooter = computed(() => {
      return !route.meta.hideFooter && !isIPhone.value;
    });

    return {
      showFooter,
    };
  },
};
</script>

<style src="@/css/App.css"></style>
