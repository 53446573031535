<template>
  <div>
    <Sidebar/>

    <Icebreaker />
    <div class="chat-box-level-1">
      <div class="chat-box-level-2">
        <div class="chat-box-title font-bold">
          Conversation
          <!-- Cross icon button to close chat history with confirmation -->
          <button @click="confirmClose" class="close-chat-history-button pi pi-times">
          </button>
        </div>
        <div class="chat-box-messages" ref="messageContainer">
          <div v-for="msg in messages" :key="msg.message_id"
            class="break-words p-3 mb-2 rounded-lg max-w-3/4"
            :class="{'bg-orange-500 text-white text-right': msg.sender_id === userId, 'bg-gray-200 text-left': msg.sender_id !== userId}">
            {{ msg.content }}
          </div>
        </div>
        <div class="p-4">
          <div class="flex">
            <input type="text" v-model="message" @keyup.enter="sendMessage" placeholder="Type your message here..."
              class="chat-box-send">
            <button @click="sendMessage" class="chat-box-send-button">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


  
<script>
// Import axios library for performing HTTP requests
import axios from 'axios';
// Import project-specific configuration settings (like API URLs)
import config from '@/config';

import Icebreaker from './Icebreaker.vue';
import Sidebar         from '../Leftside/Sidebar.vue';
import 'primeicons/primeicons.css'

export default {

  components: {
    Icebreaker,
    Sidebar
  }, 
  // Data function returns an object containing the component's local state
  data() {
    return {
      conversationId: null, // Initializes conversationId as null, to be set after fetching
      userId: null, // Initializes userId as null, to be set after fetching
      ws: null, // Placeholder for WebSocket connection
      message: '', // Temporarily stores message input from the user
      messages: [], // Array to store messages retrieved from the server

      showOptions: true, // Initially show the Icebreaker
    };
  },
  // Lifecycle hook that runs after the component is mounted on the DOM
  async mounted() {
    await this.fetchUserDetails(); // Calls method to fetch user-specific details like userId
    if (this.userId && this.conversationId) {
      this.connectWebSocket(); // Establish WebSocket connection if IDs are valid
      this.fetchChatHistory(); // Fetch chat history from the server
    }
  },
  methods: {
    confirmClose() {
      if (confirm("Are you sure you want to close the chat history?")) {
        this.closeChatHistory();
      }
    },

    // Call axios post close_connection_FriendsBuddy by submit of closeChatHistory button 
    async closeChatHistory() {
      const token = localStorage.getItem('token'); // Retrieve authentication token from local storage
      if (!token) {
        console.error('Token not found, user might not be logged in'); // Log error if token is missing
        return;
      }
      // Perform POST request to close chat history, using the token for authorization
      const response = await axios.delete(`${config.apiBaseUrl}/close_connection_FriendsBuddy/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      console.log(response.data); // Log the response data
    }, 

    // Asynchronously fetches chat history using an HTTP GET request
    async fetchChatHistory() {
      const token = localStorage.getItem('token'); // Retrieve authentication token from local storage
      if (!token) {
        console.error('Token not found, user might not be logged in'); // Log error if token is missing
        return;
      }
      // Perform GET request to fetch chat history, using the token for authorization
      const response = await axios.get(`${config.apiBaseUrl}/get_conversation_history_FriendsBuddy/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.messages = response.data.chat_history_json.messages || []; // Store fetched messages or an empty array if none
    },
    // Asynchronously fetches user details like userId and conversationId
    async fetchUserDetails() {
      const token = localStorage.getItem('token'); // Retrieve authentication token from local storage
      if (!token) {
        console.error('Token not found, user might not be logged in'); // Log error if token is missing
        return;
      }
      // Fetch userId from the server using the token for authorization
      const userIdResponse = await axios.get(`${config.apiBaseUrl}/get_user_id_FriendsBuddy/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.userId = userIdResponse.data.user_id; // Store the fetched userId
      // Fetch conversationId from the server using the token for authorization
      const convIdResponse = await axios.get(`${config.apiBaseUrl}/get_conversation_id_FriendsBuddy/`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      this.conversationId = convIdResponse.data.conversation_id; // Store the fetched conversationId
      if (this.conversationId === 'Missing') {
        console.error('Failed to fetch conversation ID:', convIdResponse.data); // Log error if conversationId is missing
        return;
      }
    },
    // Initializes WebSocket connection
    connectWebSocket() {
      const token = localStorage.getItem('token'); // Retrieve authentication token from local storage
      // Construct WebSocket URL with the conversationId and token as query parameters
      const wsUrl = `${config.apiBaseUrlWS}/ws/chat_FriendsBuddy/${this.conversationId}?token=${token}`;
      console.log(`Connecting to WebSocket at: ${wsUrl}`); // Log the WebSocket connection URL
      this.ws = new WebSocket(wsUrl); // Create a new WebSocket connection
      // Setup WebSocket event handlers
      this.ws.onmessage = this.handleMessage; // Handle incoming messages
      this.ws.onclose = this.handleClose; // Handle WebSocket disconnection
      this.ws.onerror = this.handleError; // Handle errors
      this.ws.onopen = this.handleOpen; // Handle successful connection
    },
    // Handles incoming WebSocket messages
    handleMessage(event) {
      console.log("Received raw data:", event.data); // Log raw data received from WebSocket
      const messageData = JSON.parse(event.data); // Parse the received data into JSON
      // Format and push the message into the messages array
      const formattedMessage = {
        ...messageData,
        content: messageData.message, // Map 'message' field to 'content'
        message_id: messageData.message_id || Date.now() // Ensure each message has a unique ID
      };
        this.messages.push(formattedMessage);
        this.$nextTick(() => {
          setTimeout(() => this.scrollToBottom(), 10); // Short delay
        });
      },


    
    // Handles WebSocket disconnections
    handleClose() {
      console.error('WebSocket disconnected! Attempting to reconnect...'); // Log disconnection and attempt to reconnect
      setTimeout(this.connectWebSocket, 3000); // Reconnect after 3 seconds
    },
    // Handles WebSocket errors
    handleError(error) {
      console.error('WebSocket error:', error); // Log WebSocket errors
    },
    // Handles successful WebSocket connections
    handleOpen() {
      console.log('WebSocket connected!'); // Log successful connection
    },





      scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.messageContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    // Sends a message through the WebSocket
    sendMessage() {
    if (this.message !== '' && this.ws) { // Check if there's a message and a WebSocket connection
      const chatMessage = {
        conversation_ID: this.conversationId,
        sender_id: this.userId,
        content: this.message // Send the content of 'message'
      };
      this.ws.send(JSON.stringify(chatMessage)); // Send the message as a JSON string

      this.message = ''; // Clear the message input field after sending

      // Scroll to bottom after the message has been added and DOM updated
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    } else {
      console.error('WebSocket is not connected.'); // Log an error if WebSocket is not connected
    }
  },
  }
}
</script>
