<template>
  <div class="flex bg-gray-100">
    <h1 class="section-title-main mt-5 ">About Us</h1> 
    
    <!-- New Flex Container for horizontal arrangement -->
    <div class="flex-row-1">
      
      <!-- Content 2: Remember Container -->
      <div class="card h-full content-grid remember"> 
        
        <h1 class="font-bold text-2xl text-center mb-2">Origin</h1> 
        <p class="mb-2 text-center">Founder developer was working on a project where he matches cells and gene expressions. 
          He was building filtration systems and also building prediction models by machine learning models. 
          Then he decided to use these tecnihuqes for matchmaking. The founder developer currently works on his thesis, too.</p>
      </div>

      <!-- Profile Container -->
      <div class="card h-full content-grid profile"> 
        <h1 class="section-title">History</h1> 
        <p class="mb-2 text-center">Our system has been launched in 2021 METU Fest first time. Since then have got more than 2000 applications and matched over 900 people.
        </p>

        
        <h1 class="text-2xl text-center font-bold mb-2">Come & Join Us!</h1> 
        <p class="mb-2">To get matched, you need to verify your profile by your EDU email.</p>
        <div class="input-group gap-4 mb-3">
          <router-link to="/register" custom v-slot="{ navigate }">
            <button class="welcome-router-survey-button" @click="navigate">
              Register
            </button>
          </router-link> 
        </div>
      </div>
    </div>



    <!-- New Flex Container for horizontal arrangement -->
    <div class="flex-row-2">
      <div class="card h-full content-grid remember "> 
        <h1 class="text-2xl text-center font-bold">Artifical Selection</h1> 
        <router-link to="/Panel_StudyBuddy" class="buddy-item justify-center flex">
              <img src="@/assets/Aboutus/analysis2.jpg" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title mb-2"><strong>Table 1.0</strong>: Sample from previous matches</h2>
        </router-link>
        <p class="mb-2 text-left">For every profile that is sent in, the info is carefully looked over. After being sorted by your preferences, the best matches are put in touch in the Chat Room. </p>
      </div>


      <!-- Profile Container -->
      <div class="card h-full content-grid profile"> 


        <h1 class="section-title">Statistics</h1> 
        <router-link to="/Panel_StudyBuddy" class="buddy-item justify-center flex">
              <img src="@/assets/Aboutus/analysis1.jpg" alt="Date Buddy Logo" class="segment-image">
              <h2 class="segment-logo-title mb-2"><strong>Table 1.1</strong>: Sample from previous matches</h2>
        </router-link>
        <p class="mb-2 text-left">  
          Our advanced matchmaking system uses machine learning to carefully look at and pair profiles that are a good match, which increases the chances of successful connections.
          
        </p> 
      </div>

    </div>
    



  </div>
</template>

<style scoped> 

@media (max-width: 768px) {
  /* Adjusts layout for screens smaller than 768px */
  .flex-row-1, .flex-row-2 {
    flex-direction: column; /* Stack vertically on mobile */
    gap: 10px; /* Reduce gap for mobile */
  }
}
</style>


<style scoped>
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%; /* Ensures it takes full available width */
}

.flex-row-1 {
  display: flex;
  justify-content: center; /* Align items at the center */
  align-items: center; /* Ensures vertical alignment */
  gap: 90px; /* Set a fixed gap between the cards */
  width: 100%;
}

.flex-row-2 {
  display: flex;
  justify-content: center; /* Align items at the center */
  align-items: center; /* Ensures vertical alignment */
  gap: 90px; /* Set a fixed gap between the cards */
  width: 100%;
  margin-bottom: 80px;
}

.section-title-main {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
}



.section-title {
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0rem;
  width: 100%;
  max-width: 500px; /* Control the max width of content */
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px 10px; /* Reduced horizontal margin to bring them closer */
  max-width: 400px; /* Control the max width of content */
  
}

.card.remember {
  margin-right: 0px; /* Smaller margin on the right for 'Remember' */
}

.card.profile {
  margin-left: 0px;  /* Smaller margin on the left for 'Profile' */
}

.input-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.segment-button {
  width: 150px; /* Example width, adjust as necessary */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.segment-button:hover {
  background-color: #F8740C;
  transform: translateY(-2px);
}
</style>


<script>

export default {
  components: {
  },
  name: 'NewSegment',
  methods: {
    selectSegment(segment) {
      console.log('Navigating to:', segment);
    }
  }
}
</script>