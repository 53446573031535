<template>
  <div>
    <!-- Toggle Button for the Sidebar -->
    <button @click="toggleSidebar"
        class="absolute top-16 left-1 z-50 inline-flex items-center p-2 text-white rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        :aria-expanded="sidebarOpen.toString()">
    <span class="sr-only">Toggle Sidebar</span>
    <!-- Hamburger Icon when closed -->
    <svg v-if="!sidebarOpen" class="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3 5h14a1 1 0 110 2H3a1 1 0 010-2zm0 6h14a1 1 0 110 2H3a1 1 0 010-2zm0 6h14a1 1 0 110 2H3a1 1 0 010-2z"/>
    </svg>
    <!-- Times Icon when open -->
    <i v-else class="pi pi-times w-6 h-6"></i>
  </button>
    
    <!-- Sidebar -->
    <aside :class="{'translate-x-0': sidebarOpen, '-translate-x-full': !sidebarOpen}"
       class="fixed z-40 top-19 left-0 w-64 bg-gray-500 dark:bg-gray-300 transition-transform duration-300 transform overflow-y-auto
              rounded-tr-3xl rounded-br-3xl">
      <div class="px-8 py-4 overflow-y-auto">
        <!-- Navigation Links with Icons -->
        <ul class="space-y-4">


          <li>
            <router-link to="/NewSegment" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Survey Map</span>
            </router-link>
          </li>
          

          <li>
            <router-link to="/Panel_MustSurvey" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Must Survey</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_DateBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Date Buddy</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_CoffeeBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Coffee Buddy</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_StudyBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Study Buddy</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_DEzelBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Ezel</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_FriendsBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Friends</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_LeylaIleMecnunBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Leyla İle Mecnun</span>
            </router-link>
          </li>

          <li>
            <router-link to="/Panel_YediTepeBuddy" class="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700">
              <i class="pi pi-barcode mr-2"></i> <!-- PrimeVue Icon for Alle -->
              <span>Yedi Tepe İstanbul</span>
            </router-link>
          </li>



        </ul>
      </div> 
    </aside>
  </div>
</template>

<script>
import 'primeicons/primeicons.css'


export default {
  name: 'SidebarMatch',
  data() {
    return {
      sidebarOpen: false  // Default state of the sidebar
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }
}
</script>

<style scoped>
/* Styles for the button */
button {
  position: fixed; /* Ensuring button is positioned relative to the viewport */
  top: 1rem; /* Adjust based on your actual navbar height */
  left: 1rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none; /* Removes bullet points */
}

/* Improved hover effect */
button:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.router-link-icon {
  margin-right: 8px; /* Space between icon and text */
}
</style>

