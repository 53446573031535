<template>
  <div class="flex min-h-screen bg-gray-100 text-gray-800">
    <!-- Sidebar -->
    <SidebarQuiz/>
  <!-- Container for the entire matchmaking survey component -->
  <div class="container mx-auto px-4 bg-gray-100">
    <!-- Header displaying the title of the survey -->
    <header class="text-center py-5">
      <h1 class="text-2xl font-bold">Matchmaking Survey</h1>
      <h1 class="text-l font-bold mb-2">Survey B</h1>
      <router-link to="/Survey_DateBuddy_A" class="survey-A-have-want-matters-options bg-orange-500 mr-1">A</router-link>
      <router-link to="/Survey_DateBuddy_B" class="survey-A-have-want-matters-options bg-orange-500 mr-1">B</router-link>
      <router-link to="/Survey_DateBuddy_C" class="survey-A-have-want-matters-options bg-orange-500 mr-1">C</router-link>
      <router-link to="/Survey_DateBuddy_D" class="survey-A-have-want-matters-options bg-orange-500 mr-1">D</router-link>
    </header>
    <!-- Conditional rendering based on the loading state -->
    <div v-if="isLoading" class="text-center">Loading...</div>
    <!-- Display error message if any errors occur during data fetching or processing -->
    <div v-if="error" class="text-red-500 text-center">{{ error }}</div>

    <!-- Main content area, displayed only when not loading and no errors, and questions are available -->
    <div v-if="!isLoading && !error && questions.length > 0" class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4">
      <!-- Displays the current question from the survey -->
      <h2 class="text-lg text-center font-bold mb-4">{{ questions[currentIndex].question }}</h2>

      <!-- Dynamic rendering of 'have' and 'want' options for the current question -->
        <div class="mb-6 flex justify-center space-x-4">
          <div v-for="type in ['have', 'want']" :key="type" class="have-want-containers">
            <h3 class="font-bold mb-2">{{ type.toUpperCase() }}</h3>
            <!-- Buttons for each option in the current question for either 'have' or 'want' -->
            <div class="flex flex-col gap-2">
              <button v-for="option in questions[currentIndex][type]" :key="option"
                      class="survey-A-have-want-options"
                      :class="{ 'have-want-options-on-click': selected[type] && selected[type].includes(option) }"
                      @click="() => selectOption(type, option)">
                {{ option }}
              </button>
            </div>
            <!-- Display importance level selection for each 'have' or 'want' -->
            <div class="mt-4 text-center">
              <span class="font-medium text-sm">{{ type.toUpperCase() }} MATTERS</span>
              <div class="flex justify-center gap-1">
                <button v-for="i in 5" :key="`${type}-${i}`"
                        class="survey-A-have-want-matters-options"
                        :class="{ 'bg-orange-500': Array.isArray(importance[type]) ? importance[type].includes(i) : importance[type] === i }"
                        @click="() => selectImportance(type, i)">
                  {{ i }}
                </button>
              </div>
            </div>
          </div>
        </div>

      <!-- Button to submit updates for the current question -->
      <div class="text-center mb-2">
        <button class="survey-update-button bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-10 rounded"
                @click="updateSegmentQuestions">Submit</button>
      </div>
      <!-- Notification for successful update operation -->
      <div v-if="updateSuccess" class="text-green-500 text-center">{{ updateMessage }}</div>
      <!-- Displays current progress through the survey -->
      <div class="flex flex-col items-center justify-center text-center">
        <div><strong>Current Question:</strong> {{ barcode }}</div>
            <div><strong>Total Questions:</strong> {{ totalQuestions }}</div>
        </div>
        <!-- Navigation buttons for previous and next questions centered and fixed-size -->
      <div class="survey-prev-next-container">
        <button class="survey-prev"
                @click="prevQuestion">Previous Question</button>
        <button class="survey-next"
                @click="updateAndNext">Next Question</button>
      </div>
    </div>
    <!-- Button to fetch the final answer based on all selections -->
    <div class="flex justify-center text-center mb-2 gap-4">
    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="goRouter">Router</button>

    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="fetchAnswer">Get My Answer</button>
    
    <button class="survey-get-go-button "
            style="width: 150px; height: 50px;" @click="goManagement">Manager</button>
    </div>
    <!-- Display the final fetched answer -->
    <div v-if="fetchedAnswer" class="bg-white shadow-md rounded-3xl px-8 pt-6 pb-8 mb-4">
        <div class="text-center">
            <div class="text-lg font-bold">Your Answer:</div>
        </div>
        <div class="mt-2">
            <p><strong>Have:</strong> {{ fetchedAnswer.have }}</p>
            <p><strong>Importance of Have:</strong> {{ fetchedAnswer.have_matters }}</p>
            <p><strong>Want:</strong> {{ fetchedAnswer.want }}</p>
            <p><strong>Importance of Want:</strong> {{ fetchedAnswer.want_matters }}</p>
        </div>
    </div>
  </div>
</div>
</template>




<style scoped>
@media (max-width: 768px) {
  /* General container adjustments */
  .container {
    width: 100%; /* Full width to avoid horizontal scrolling */
    padding: 0 15px; /* Reduce padding */
    box-sizing: border-box; /* Include padding in width calculation */
  }

  /* Specific adjustments for survey containers */
  .have-want-containers {
    width: 80%; /* Full width containers */
    margin: 0 auto; /* Center aligning */
    padding: 10px; /* Padding for internal spacing */
  }

  /* Buttons within containers */
  .survey-A-have-want-options, .survey-A-have-want-matters-options {
    width: 100%; /* Full width for easy tapping */
    margin: 5px 0; /* Margin for spacing */
    font-size: x-small;
  }

  /* Header and titles */
  h1, h2 {
    font-size: 1.2rem; /* Smaller font size for headers */
    padding: 10px 0; /* Padding around headers */
    text-align: center; /* Center alignment */
  }

  /* Adjust the size of buttons for importance */
  button {
    padding: 8px; /* Smaller padding for buttons */
    font-size: 0.875rem; /* Smaller font size for button text */
  }
}
</style>






<script>
// fetchAnswer: Fetches a specific answer based on the current segment and barcode.
// fetchTotalQuestions: Retrieves the total number of questions available for the current segment.
// fetchQuestions: Loads questions based on the current barcode and segment.
// resetSelections: Resets user selections for the current question.
// nextQuestion: Moves to the next question, fetching new questions if necessary.
// prevQuestion: Moves to the previous question, fetching new questions if necessary.
// selectOption: Selects or deselects an option for 'have' or 'want'.
// selectImportance: Sets the importance level for a selected option.
// updateSegmentQuestions: Submits updates to the server for the current selections and loads the updated response.
// Import necessary helpers from 'vue', HTTP client 'axios', and configuration settings
import { ref, onMounted } from 'vue';
import axios from 'axios';
import config from '@/config';
import router from '@/router'; 

import SidebarQuiz from '@/components/Default/SidebarQuiz.vue'; // Update the path as necessary

export default {
  methods: {
    hasSelections() {
    return this.selected.have.length > 0 || this.selected.want.length > 0;
  },
  async updateAndNext() {
    if (this.hasSelections()) {
      try {
        await this.updateSegmentQuestions();
        this.nextQuestion();
      } catch (error) {
        console.error("Failed to update:", error);
        // Optionally handle the error, e.g., notify the user
      }
    } else {
      console.log("No selections made; moving to next question without updating.");
      this.nextQuestion();
    }
  },
}, 
  components: {
    SidebarQuiz
  },
  // setup function is the entry point for using the Composition API
  setup() {
    // Define reactive state variables using 'ref'. These will store the component's state and update the DOM reactively when their values change.
    const questions = ref([]); // holds an array of questions
    const isLoading = ref(true); // manages loading state of the component
    const error = ref(null); // holds error messages, if any
    const updateSuccess = ref(false); // indicates whether an update operation was successful
    const updateMessage = ref(""); // stores the message related to the last update operation
    const backendUrl = ref(config.apiBaseUrl); // backend URL from configuration
    const segment = ref('DateBuddy'); // current segment identifier
    const barcode = ref('1'); // current barcode value
    const token = localStorage.getItem('token'); // fetch auth token from localStorage
    const currentIndex = ref(1); // index of the currently displayed question

    // Selected options and their importance levels for the survey
    const selected = ref({
      have: [], 
      want: []  // Initialize both as arrays for multiple selections
    }); 

    const importance = ref({
      have: 0, 
      want: []  // 'want' importance levels, also an array for multiple selections
    });

    const totalQuestions = ref(0); // total number of questions in the current segment
    const fetchedAnswer = ref(null); // stores the fetched answer from the backend

    const goRouter = () => {
      router.push('/Welcome_Router_DateBuddy');
    };


    const goManagement = () => {
      router.push('/Activity_Match_DateBuddy');
    };

    // Async function to fetch the final answer based on user selections
    const fetchAnswer = async () => { 
      try {
        // Parameters for the GET request
        const params = { 
          segment: segment.value, 
          barcode: barcode.value.toString()  // Convert barcode to string to ensure correct parameter format
        };
        // Axios GET request to fetch the answer
        const response = await axios.get(`${backendUrl.value}/get_own_answer_DateBuddy_B/`, {
          params,
          headers: { Authorization: `Bearer ${token}` }  // Authorization header
        });
        // Update the fetchedAnswer state with the response data
        fetchedAnswer.value = response.data;
      } catch (err) {
        console.error("Failed to fetch answer:", err);
        // Handle errors by setting a comprehensive error state
        fetchedAnswer.value = { have: "Error", have_matters: "Error", want: "Error", want_matters: "Error" };
      }
    };

      // Async function to fetch the total number of questions
      const fetchTotalQuestions = async () => {
      try {
        // Axios GET request to fetch the total number of questions for the current segment
        const response = await axios.get(`${backendUrl.value}/number_of_questions_DateBuddy_B/`, {
            params: { segment: segment.value },
            headers: { Authorization: `Bearer ${token}` }
        });
        // Update the totalQuestions state with the response data
        totalQuestions.value = response.data;
      } catch (err) {
        console.error("Error fetching total number of questions:", err);
      }
    };

    // Async function to fetch questions for the current segment and barcode
    const fetchQuestions = async () => {
      isLoading.value = true;  // Set loading state to true at the start
      let currentBarcode = parseInt(barcode.value); // Convert barcode to integer for validation
      if (isNaN(currentBarcode)) {  // Check if barcode is a valid number
        console.error("Invalid barcode value:", barcode.value);
        currentBarcode = 0;  // Default to 0 if barcode is not a number
      }

      let params = { segment: segment.value, barcode: currentBarcode.toString() };
      try {
        const response = await axios.get(`${backendUrl.value}/retrieve_DateBuddy_B/`, {
            params: params,
            headers: { Authorization: `Bearer ${token}` }
        });
        if (response.data.length > 0) {
          questions.value = response.data;  // Update questions state with the response data
          currentIndex.value = 0;  // Reset the current index to the start
          error.value = "";  // Clear any existing error messages
          isLoading.value = false;  // Set loading state to false after data is loaded
          return true;  // Return true to indicate successful data fetch
        } else {
          error.value = "No questions available at this barcode.";
          isLoading.value = false;  // Set loading state to false if no data is available
          return false;  // Return false to indicate no data was fetched
        }
      } catch (err) {
        error.value = "Failed to load questions.";  // Update error state with a message
        console.error("Error fetching the questions:", err);
        isLoading.value = false;  // Set loading state to false on error
        return false;  // Return false to indicate fetching failed
      }
    };


    onMounted(() => {
      fetchQuestions();  // Call fetchQuestions when component mounts
      fetchTotalQuestions();  // Also fetch the total number of questions
    });
    
    
    
    // Function to reset selections and importance ratings for both 'have' and 'want'
    const resetSelections = () => {
      selected.value = { have: [], want: [] }; // Resets 'have' to null and 'want' to an empty array
      importance.value = { have: 0, want: [] }; // Resets importance of 'have' to 0 and 'want' to an empty array
      fetchedAnswer.value = null; // Clears any previously fetched answer
    };

    // Function to navigate to the next question
    const nextQuestion = () => {
      console.log("Current index before increment:", currentIndex.value); // Logs the current question index
      if (currentIndex.value < questions.value.length - 1) { // Check if the current index is not the last question
        currentIndex.value++; // Increment the current index to move to the next question
        resetSelections(); // Reset selections when moving to the next question
        console.log("Moved to next question:", currentIndex.value); // Log the new question index
      } else { // If it is the last question, fetch new questions from the server
        console.log("Fetching next question from server...");
        barcode.value = (parseInt(barcode.value) % totalQuestions.value) + 1; // Calculate the next barcode by wrapping around
        fetchQuestions().then(() => { // Fetch new questions for the new barcode
          resetSelections(); // Reset selections after fetching new questions
          console.log("New questions loaded for barcode:", barcode.value); // Log the new barcode
        });
      }
    };

    // Function to navigate to the previous question
    const prevQuestion = () => {
      console.log("Current index before decrement:", currentIndex.value); // Logs the current question index
      if (currentIndex.value > 0) { // Check if the current index is not the first question
        currentIndex.value--; // Decrement the current index to move to the previous question
        resetSelections(); // Reset selections when moving to the previous question
        console.log("Moved to previous question:", currentIndex.value); // Log the new question index
      } else { // If it is the first question, wrap around to the last question
        console.log("Fetching previous question from server...");
        barcode.value = (parseInt(barcode.value) - 1 + totalQuestions.value) % totalQuestions.value; // Calculate the previous barcode by wrapping around
        if (barcode.value === 0) barcode.value = totalQuestions.value; // Ensure the barcode wraps around correctly
        fetchQuestions().then(() => { // Fetch new questions for the new barcode
          resetSelections(); // Reset selections after fetching new questions
          console.log("New questions loaded for barcode:", barcode.value); // Log the new barcode
        });
      }
    };

    // Function to handle option selection for both 'have' and 'want'
    const selectOption = (type, option) => {
      console.log(`Before change: ${type}`, selected.value[type]); // Log the current state before the change
      const index = selected.value[type].indexOf(option); // Find the index of the option in the array
      if (index > -1) {
        selected.value[type].splice(index, 1); // Remove the option if it is already selected
      } else {
        selected.value[type].push(option); // Add the option if it is not already selected
      }
      console.log(`After change: ${type}`, selected.value[type]); // Log the updated state after the change
    };

    // Function to handle importance selection for both 'have' and 'want'
    const selectImportance = (type, value) => {
      if (type === 'want') { // Check if the type is 'want'
        const index = importance.value[type].indexOf(value); // Find the index of the value in the 'want' importance array
        if (index > -1) {
          importance.value[type].splice(index, 1); // Remove the importance if it is already selected
        } else {
          importance.value[type].push(value); // Add the importance if it is not already selected
        }
      } else { // For 'have', toggle the importance selection
        importance.value[type] = importance.value[type] === value ? 0 : value; // Toggle the importance state
      }
    };

    // Function to update segment questions and fetch updated answers
    const updateSegmentQuestions = async () => {
      if (questions.value.length > 0 && currentIndex.value >= 0) { // Ensure there are questions and a valid index
        const currentQuestion = questions.value[currentIndex.value]; // Get the current question
        const payload = { // Prepare the payload for the update request
          question: currentQuestion.question,
          selectedHave: selected.value.have,
          selectedWant: selected.value.want,
          selectedHaveMatters: importance.value.have,
          selectedWantMatters: importance.value.want,
          segment: segment.value,
          barcode: barcode.value
        };

        try {
          const response = await axios.post(`${backendUrl.value}/update_questions_DateBuddy_B/`, payload, {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (response.status === 200) {
            console.log("Update successful, fetching updated answer...");
            setTimeout(() => { // Use a slight delay to allow for database updates
              fetchAnswer(); // Fetch the updated answer after a delay
            }, 500);
          } else {
            console.error("Update failed with status code:", response.status, response.data);
            updateMessage.value = "Failed to update. Please try again.";
          }
        } catch (error) {
          console.error("An error occurred during the update:", error.response ? error.response.data : error.message);
          updateMessage.value = "Update error: " + (error.response ? error.response.data.detail : error.message);
        }
      } else {
        console.error("No question selected or invalid index");
      }
    };



    // Return all the reactive states and functions, making them accessible in the template
    return {
      questions, isLoading, error, selected, importance, selectOption, selectImportance, updateSegmentQuestions, 
      currentIndex, nextQuestion, prevQuestion, 
      updateSuccess, updateMessage, barcode, totalQuestions, 
      fetchedAnswer,
      fetchAnswer,
      goManagement,
      goRouter
    };
  }
};
</script>