<template>
    <div class="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-lg">
      <h1 class="text-2xl font-semibold mb-6 text-center">Create Product</h1>
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-4 items-center">
        <input
          v-model="productName"
          type="text"
          placeholder="Product Name"
          class="mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
          required
        />
        <input
          v-model.number="productPrice"
          type="number"
          placeholder="Product Price"
          class="mt-1 block w-full px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
          required
        />
        <button type="submit" class="px-4 py-2 bg-black text-white rounded-md hover:bg-blue-700">
          Create Product
        </button>
      </form>
      <div v-if="message" class="mt-4 text-center">
        <p>{{ message }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import axios from 'axios';
  import config from '@/config'; 
  
  export default {
    setup() {
      const productName = ref('');
      const productPrice = ref('');
      const message = ref('');
      const backendUrl = ref(config.apiBaseUrl); 
      console.log("Environment VUE BACKEND URL:", backendUrl.value);
  
      const handleSubmit = async () => {
        try {
          const product = {
            product_name: productName.value,
            product_price: productPrice.value, // Vue automatically handles conversion to number due to v-model.number
          };
          // print console vue app backend url
          console.log(backendUrl.value); 
  
          const response = await axios.post(`${backendUrl.value}/product_create/`, product, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // If your API requires authentication
            },
          });
  
          message.value = "Product created successfully."; // Show success message
          console.log(response.data.message); // Log server response message
          setTimeout(() => {
            window.location.reload();
          }, 1000); // Adjust the delay as needed
          
          productName.value = '';
          productPrice.value = '';
        } catch (error) {
          console.error('Error creating product:', error.response?.data?.detail || 'Unknown error');
          message.value = error.response?.data?.detail || 'An error occurred. Please try again.';
        }
      };
  
      return { productName, productPrice, message, handleSubmit };
    },
  };
  </script>
  
  <style scoped>
  /* Styles specific to this component */
  </style>
  