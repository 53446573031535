<template>
  <div class="flex bg-gray-100">
    <!-- Sidebar -->
    <Sidebar/>

    <div class="card h-full content-grid">
      <h1 class="section-title">Update University</h1>

      <!-- University Selection Group -->
      <div class="input-group">
        <label for="university" class="block text-gray-700 text-sm font-bold mb-2">Select Your University:</label>
        <select id="university" v-model="selectedUniversity" class="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option value="METU_Ankara">METU Ankara</option>
          <option value="METU_Cybrus">METU NCC</option>
        </select>
      </div>

      <!-- Submit Button -->
      <div class="flex justify-center">
      <button class="btn bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" @click="updateUniversity">Update</button>
    </div>
    </div>
  </div>
</template>
<style scoped>
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh; /* Adjust height as needed */
  }

.content-grid {
display: grid;
grid-template-columns: 1fr; /* Keep it single column for stacking */
gap: 0rem;
width: 100%;
max-width: 500px; /* Control the max width of the content grid */
margin: 0 auto; /* Center the grid horizontally */
}


.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 50px auto;
}

.section-title {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group select, .input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.btn {
  display: block;
  width: 30%;
  padding: 10px;
  background-color: #e47206;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #b66515;
}
</style>

  <script>
  import { ref } from 'vue';
  import axios from 'axios';
  import config from '@/config';
  import Sidebar from '@/components/Default/Sidebar.vue';
  
  export default {
    components: {
      Sidebar
    },
    setup() {
      const selectedUniversity = ref('METU_Ankara'); // Default selected university
      const backendUrl = ref(config.apiBaseUrl);
  
      const updateUniversity = async () => {
        try {
          const url = `${backendUrl.value}/update_profile_university/`;
          const token = localStorage.getItem('token');
          const headers = {
            'Authorization': `Bearer ${token}`
          };
          const payload = {
            person_ID: "current_user_ID",  // Placeholder, replace with actual ID management
            university_DB: [selectedUniversity.value]
          };
          const response = await axios.put(url, payload, { headers });
          alert(response.data.message);
        } catch (error) {
          console.error('Failed to update university:', error);
          alert('Error: ' + (error.response?.data.detail || 'Failed to update university'));
        }
      };
  
      return { selectedUniversity, updateUniversity };
    },
  };
  </script>
  