<template>
  <div class="flex">
    <!-- Sidebar -->
    <Sidebar/>

    <!-- Main Content -->
    <div class="flex-1">
      
      <div class="card">
        <h1 class="section-title">Graduate Verification</h1>
        
        <div class="input-group">
          <label for="email">Enter your alumni email</label>
        
          <input type="email" id="email" v-model="email" placeholder="hocamsu@alumni.metu.edu.tr">
          <p class="text-xs text-gray-600">It is not allowed to use eXXXXXXX@alumni.metu.edu.tr but use named one.</p>
          <button class="btn mt-4" @click="sendOTP">Send One Time Password (OTP)</button>
        </div>
        <div class="input-group">
          <label for="otp">Enter OTP</label>
          <input type="text" id="otp" v-model="otp" placeholder="One-Time Password">
          <button class="btn mt-4" @click="verifyOTP">Verify One Time Password (OTP)</button>
        </div>
        <div class="flex justify-center">
      <p :class="getEDUVerify_StatusClass"><strong>Status: </strong>{{ EDUVerify_StatusStatus }}</p>
    </div>
      </div>

      <div class="flex justify-center">
      <router-link :to="'./EDUVerify'" class="verify-route-button text-center mb-10" :class="getMustSurvey_StatusClass">Student</router-link>  
    </div>
      
    </div>
    
  </div>
</template>


<script>
import { ref } from 'vue';
import axios from 'axios';
import config from '@/config'; 

import Sidebar from '@/components/Default/Sidebar.vue'; 

export default {
  components: {
    Sidebar
  },
  data() {
    return {
      EDUVerify_StatusStatus: 'Loading...',
      email: '',
      otp: '',
      otpValid: true,
      backendUrl: config.apiBaseUrl // Initialize backendUrl here
    };
  },
  methods: {
    async fetchStatus() {
      const token = localStorage.getItem('token');
      // Fetch EDU Verification Status
      await axios.get(`${this.backendUrl}/status_current_EDU_Verification_graduate/`, {
        headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
        this.EDUVerify_StatusStatus = response.data.verification_status || 'Not Verified';
      }).catch(error => {
        console.error('Error fetching EDU Verification status:', error);
        this.EDUVerify_StatusStatus = 'Not Verified';
      });
    },
  },
  mounted() {
    this.fetchStatus();
  },
  setup() {
    const email = ref('');
    const otp = ref('');
    const otpValid = ref(true); // New ref to track if OTP is still valid
    const backendUrl = ref(config.apiBaseUrl); // Reference your API base URL from config
    console.log("Using backend URL:", backendUrl.value);

    const sendOTP = async () => {
      try {
        const url = `${backendUrl.value}/send-otp-to-edu-graduate/`;
        const token = localStorage.getItem('token');  // Assuming token is stored in localStorage
        const headers = {
            'Authorization': `Bearer ${token}`
        };
        console.log("Sending OTP to URL:", url, "with token", token);
        const response = await axios.post(url, { edu_email: email.value }, { headers: headers });
        alert(response.data.message);
        
        // Set OTP validity timer
        setTimeout(() => {
          otpValid.value = false;
          alert('OTP has expired, please request a new one.');
        }, 180000); // 180,000 milliseconds = 3 minutes
      } catch (error) {
        console.error('Failed to send OTP:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to send OTP'));
      }
    };

    const verifyOTP = async () => {
      if (!otpValid.value) {
        alert('OTP has expired. Please request a new OTP.');
        return;
      }
      try {
        const url = `${backendUrl.value}/verify-otp-from-edu-graduate/`;
        const token = localStorage.getItem('token'); // Retrieve token from localStorage
        const headers = {
          'Authorization': `Bearer ${token}` // Use the token in the Authorization header
        };
        console.log("Verifying OTP at URL:", url, "with token", token);
        const response = await axios.post(url, { otp: otp.value }, { headers: headers });
        alert(response.data.message);
      } catch (error) {
        console.error('Failed to verify OTP:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to verify OTP'));
      }
    };

    return { email, otp, sendOTP, verifyOTP, otpValid };
  }
}
</script>


<style scoped>

.section-title {
  font-weight: 700;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  margin: 50px auto;
}
.input-group {
  margin-bottom: 20px;
}
.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #dd7c13;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.btn:hover {
  background-color: #854709;
}

.verify-route-button {
  display: block;
  width: 90px;
  align-content: center;
  font-bold: 700; 
  height: 40px;
  padding: 10px;
  background-color: #dd7c13;
  color: white;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.verify-route-button:hover {
  background-color: #854709;
}

</style>
