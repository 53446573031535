<template>
  <nav class="navbar-custom-1 p-4">
    <div class="max-w-screen-xl mx-auto flex justify-between items-center">
      <!-- Main Links -->
      <div class="flex space-x-4">
        <img src="@/assets/Logos/logoSenlikBuddy.png" alt="Survey 1 Logo" class="segment-image hide-item">
        <router-link to="/" class="text-white text-xl font-semibold ml-30 hide-item">Tam Senlik! (BETA)</router-link>

        <!-- 
        <router-link to="/Test" class="text-white text-l font-semibold">Test</router-link>
        -->
      </div>

      <!-- Conditional Render Based on Login Status and Profile Dropdowns -->
      <div class="flex items-center space-x-7">
        <!-- Conditional Visibility for Logged-in User -->
        <template v-if="isLoggedIn">
          <!-- Welcome Router Link -->

          
          <router-link to="/Main" class="buddy-item">
            <img src="@/assets/Navigation/homeWhite.png" alt="Survey 1 Logo" class="segment-image">
          </router-link>
          
            <!-- Match Dropdown -->
            <div class="relative group">
            <button @click="toggleMatchMenu2" class="buddy-item">
              <img src="@/assets/Navigation/layersWhite.png" alt="Date Buddy Logo" class="segment-image">
              </button>
              <div v-show="showMatchMenu2"  @mouseleave="closeDropdowns" class="navbar-custom absolute text-center right-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-lg">
                <ul class="text-sm rounded-lg">
                  <li class="hover:bg-gray-700"><router-link to="/NewSegment" class="block px-4 py-2 text-white">All Surveys</router-link></li>
                  <li class="hover:bg-gray-700"><router-link to="/Management_Map" class="block px-4 py-2 text-white">Manage Activity</router-link></li>
                  
                  <li class="hover:bg-gray-700"><router-link to="/Communication" class="block px-4 py-2 text-white">News</router-link></li>
                  <li class="hover:bg-gray-700"><router-link to="/Welcome_Router_Feedback" class="block px-4 py-2 text-white">Feedback</router-link></li>
                  <!-- 
                  <li class="hover:bg-gray-700"><router-link to="/NewsDisplayer" class="block px-4 py-2 text-white">News</router-link></li>
                  --> 
                  <li class="hover:bg-gray-700"><router-link to="/Vote" class="block px-4 py-2 text-white">Vote</router-link></li>
                </ul>
              </div>
            </div>
          
          <!-- Profile Dropdown -->
          <div class="relative group">
            <button @click="toggleMatchMenu" class="buddy-item">
              <img src="@/assets/Navigation/profileWhite.png" alt="Date Buddy Logo" class="segment-image">
            </button>
            <div v-show="showMatchMenu"  @mouseleave="closeDropdowns" class="navbar-custom absolute text-center right-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-lg">
              <ul class="text-sm rounded-lg">
                <li class="hover:bg-gray-700"><router-link to="/ProfileDisplayer" class="block px-4 py-2 text-white">Settings</router-link></li> 
                <li class="hover:bg-gray-700"><button @click="handleLogout" class="block px-4 py-2 text-white">Logout</button></li>
              </ul>
            </div>


          </div>
        
        </template>

        
      </div>
    </div>
  </nav>
</template>

<style scoped >
.navbar-custom-1 {
    padding: 1rem; /* Equivalent to Tailwind's p-4 */
    background-color: #272b30; /* Equivalent to Tailwind's bg-gray-700 */
    
}

</style>


<style scoped>
@media (max-width: 768px) {

  .hide-item {
    display: none; /* Hide the logo and title on mobile */
  } 
  .segment-image-news {
    display: none; /* Hide the news button on mobile */

    width: 1.5rem; /* Smaller icons */
    height: 1.5rem; /* Smaller icons */
  }

  .navbar-custom-1 .segment-image {
    width: 1.5rem; /* Adjust the icon size */
    height: 1.5rem; /* Adjust the icon size */
  }

  .navbar-custom-1 .text-xl,
  .navbar-custom-1 .text-l {
    font-size: 0.875rem; /* Reduce font size for text */
  }
}
  .navbar-custom-1 {
    padding: 0.5rem; /* Smaller padding */
    font-size: 0.875rem; /* Smaller font size */
  }
  .segment-image {
    width: 1.5rem; /* Smaller icons */
    height: 1.5rem; /* Smaller icons */
  }

  .text-xl {
    font-size: 1rem; /* Smaller main text */
  }

  .buddy-item {
    width: 2.5rem;
    height: 2.5rem;
  }
  
  /* Adjust dropdown menu size */
  .navbar-custom ul {
    width: 100%; /* Full width for easier mobile use */
  }
</style>

<style scoped>
.navbar-custom ul {
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensure the ul covers the full width of its parent */
}

.navbar-custom a, .navbar-custom button {
  display: block; /* Ensures full width and easier center alignment */
  text-align: center; /* Center the text */
  margin-left: 0; /* Override previous margin if necessary */
  width: 100%; /* Optional, makes the click area larger */
}

/* Ensures rounded corners on hover for each dropdown option */
.navbar-custom ul li:hover, .navbar-custom ul li router-link:hover {
  border-radius: 0.5rem; /* This ensures rounded corners */
}



.buddy-item {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  transition: transform 0.3s ease-in-out;
}
  .buddy-item:hover {
    transform: translateY(-2px);
  }
  .segment-image {
    width: 2rem;
    height: 2rem;
  }
  .segment-image-news {
    width: 2rem;
    height: 2rem;
  }
  </style>


<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const isLoggedIn = computed(() => store.state.isLoggedIn);
    const showMatchMenu = ref(false);
    const showMatchMenu2 = ref(false);
    

    const toggleMatchMenu = (event) => {
    console.log("Toggling Profile Menu");
    event.stopPropagation(); // Stop event from bubbling up
    showMatchMenu.value = !showMatchMenu.value;
    showMatchMenu2.value = false;
  };

  const toggleMatchMenu2 = (event) => {
    console.log("Toggling Match Menu");
    event.stopPropagation(); // Stop event from bubbling up
    showMatchMenu2.value = !showMatchMenu2.value;
    showMatchMenu.value = false;
  };




    const handleLogout = () => {
      store.dispatch('logout');
      router.push('/');
    };

    const navigateTo = (path) => {
      router.push(path);
    };

    const closeDropdowns = () => {
      showMatchMenu.value = false;
      showMatchMenu2.value = false;
    };
    
    return { isLoggedIn, showMatchMenu, toggleMatchMenu, showMatchMenu2, toggleMatchMenu2, handleLogout, navigateTo, closeDropdowns };
  }
};
// <li class="hover:bg-gray-700"><router-link to="/ChatInfo_MustSurvey" class="block px-4 py-2 text-white">ChatInfo_MustSurvey</router-link></li>
//<li class="hover:bg-gray-700"><router-link to="/ToggleMenu_MustSurvey" class="block px-4 py-2 text-white">ToggleMenu_MustSurvey</router-link></li>
//<li class="hover:bg-gray-700"><router-link to="/Dynamic_Panel" class="block px-4 py-2 text-white">Dynamic_Panel</router-link></li>
//<li class="hover:bg-gray-700"><router-link to="/Game21_MustSurvey" class="block px-4 py-2 text-white">Game21_MustSurvey</router-link></li>
//<li class="hover:bg-gray-700"><router-link to="/WingBuddyAI_MustSurvey" class="block px-4 py-2 text-white">WingBuddyAI_MustSurvey</router-link></li>
</script>
