<template>
    <div>
      <ProductDisplayer />
  
      <div class="flex flex-row justify-center space-x-4 mx-auto py-4">
        <div class="flex-1 max-w-xs px-4">
          <!-- Adjust the max-width as per your design -->
          <ProductCreate />
        </div>
        <div class="flex-1 max-w-xs px-4">
          <!-- Adjust the max-width as per your design -->
          <ProductDelete />
        </div>
        <div class="flex-1 max-w-xs px-4">
          <!-- Adjust the max-width as per your design -->
          <ProductUpdate />
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import ProductDisplayer from './ProductDisplayer.vue';
  import ProductCreate from './ProductCreate.vue';
  import ProductDelete from './ProductDelete.vue';
  import ProductUpdate from './ProductUpdate.vue';
  
  export default {
    components: {
      ProductDisplayer,
      ProductCreate,
      ProductDelete,
      ProductUpdate,
    },
  };
  </script>
  
  <style>
  /* Global styles can be placed here */
  </style>
  