<template>
  <div class="bg-white py-12">
    <div class="overflow-x-auto">
      <table class="mx-auto w-full lg:w-4/5 text-left border-collapse rounded-lg overflow-hidden">
        <thead>
          <tr>
            <th class="bg-gray-200 border text-center p-3">Date</th>
            <th class="bg-gray-200 border text-center p-3">Time</th>
            <th class="bg-gray-200 border text-center p-3">Subject</th>
            <th class="bg-gray-200 border text-center p-3">Announcement</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in newsItems" :key="item.id">
            <td class="border p-3">{{ item.date }}</td>
            <td class="border p-3">{{ item.Time }}</td>
            <td class="border p-3">{{ item.Subject }}</td>
            <td class="border text p-3">{{ item.Announcement }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex flex-wrap justify-center mt-5 gap-5">
      <router-link to="/ProfileDisplayer" class="buddy-item-welcome-help flex flex-col items-center">
        <img src="@/assets/Welcome/profile.png" alt="Date Buddy Logo" class="segment-image-help w-10 h-10 ">
        <h2 class="segment-logo-title-help text-gray-700 text-center mt-2">Profile</h2>
      </router-link>

      <router-link to="/Panel_MustSurvey" class="buddy-item-welcome-help flex flex-col items-center">
        <img src="@/assets/Main/surveyMain.png" alt="Date Buddy Logo" class="segment-image-help w-10 h-10 ">
        <h2 class="segment-logo-title-help text-gray-700 text-center mt-2">Meet Buddy</h2>
      </router-link>

      <router-link to="/Management_Map" class="buddy-item-welcome-help flex flex-col items-center">
        <img src="@/assets/Main/doorIn.png" alt="Date Buddy Logo" class="segment-image-help w-10 h-10 ">
        <h2 class="segment-logo-title-help text-gray-700 text-center mt-2">Matches</h2>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsTable',
  data() {
    return {
      newsItems: [
        {
          id: 1, date: '28-05-2024', Time: '03:45', 
          Subject: "Dev-Notes", 
          Announcement: 'Hello METU!'
        },
        {
          id: 2, date: '28-05-2024', Time: '18:45', 
          Subject: "Dev-Notes", 
          Announcement: 'Now you can fill the survey. Meet Buddy and METU Fest 2024 are ready. Surveys are waiting for you.'
        },
      ]
    }
  }
}
</script>

<style scoped>
/* Ensure the table, th, and td have a unified style */
table {
  border-collapse: separate; /* Important for spacing between rows when border radius is used */
  border-spacing: 0; /* Removes default spacing */
}

thead tr:first-child th:first-child {
  border-top-left-radius: 0.5rem; /* Rounded corners for the first header */
}

thead tr:first-child th:last-child {
  border-top-right-radius: 0.5rem; /* Rounded corners for the last header */
}

tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem; /* Rounded corners for the bottom left cell */
}

tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem; /* Rounded corners for the bottom right cell */
}

.segment-image-help {
  max-width: 100%;
  height: auto;
}
</style>
