// src/store/store.js
// #F8740C
// #282c34
import { createStore } from 'vuex';
import axios from 'axios';
import router from '../router';

export default createStore({
  state() {
    return {
      isLoggedIn: !!localStorage.getItem('token'),
      acceptedTerms: localStorage.getItem('acceptedTerms') === 'true',
    };
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
      if (isLoggedIn) {
        localStorage.setItem('isLoggedIn', true);
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('acceptedTerms'); // Clear terms state on logout
      }
    },
    setTermsState(state, accepted) {
      state.acceptedTerms = accepted;
      localStorage.setItem('acceptedTerms', accepted); // Persist terms acceptance state
    }
  },
  actions: {
    login({ commit }, { token, termsofservice_status }) {
      localStorage.setItem('token', token);
      commit('setLoginState', true);
      commit('setTermsState', termsofservice_status === "verified");
    },
    logout({ commit }) {
      return Promise.resolve().then(() => {
        commit('setLoginState', false);
        commit('setTermsState', false);
        localStorage.clear(); // Clear all local storage
        router.push('/login');
      });
    },
    fetchUserData({ commit }) {
      axios.get('/api/user').then(response => {
        commit('setLoginState', true);
        commit('setTermsState', response.data.termsAccepted);
      }).catch(error => {
        console.error("Error fetching user data:", error);
      });
    }
  },
});
