<template>
    <div class="dynamic-panel-container flex justify-center px-4 sm:px-0">
      <div class="dynamic-panel-window bg-white w-full max-w-lg rounded-lg shadow-lg h-full">
        <ToggleButtons @toggle="toggleContent" :activeContent="activeContent" />
        <div class="flex-grow overflow-auto p-4">
          <div v-if="loading" class="dynamic-panel-loading-container">
            <!-- Loading spinner -->
          </div>
          <div v-else class="flex flex-col h-full">
            <div v-if="activeContent === 'yours'" class="dynamic-panel-content-wrapper flex-grow">
              <slot name="yours" />
            </div>
            <div v-if="activeContent === 'partner'" class="dynamic-panel-content-wrapper flex-grow">
              <slot name="partner" />
            </div>
            

            <div class="flex justify-center gap-5 mt-4">
              <div class="dynamic-panel-button-wrapper">
                <button class="dynamic-panel-button" :disabled="isGetResultsDisabled" @click="fetchComparison">
                  Get Results
                </button>
              </div>
            </div>

            <div v-if="activeContent === 'aibot'" class="dynamic-panel-content-wrapper flex-grow">
              <slot name="aibot" />
            </div>

            <div v-if="isGetResultsDisabled" class="countdown-dynamic-panel">
              Get Results button will be enabled in {{ getResultsCountdown }} seconds
            </div>
            <div v-if="isComparisonDisabled" class="countdown-dynamic-panel">
              Buttons will be enabled in {{ comparisonCountdown }} seconds
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import config from '@/config';
  import ToggleButtons from '../TrueFalse/ToggleButtons.vue';
  
  export default {
    name: 'DynamicPanel',
    components: {
      ToggleButtons,
    },
    setup() {
      const store = useStore();
      const backendUrl = ref(config.apiBaseUrl);
      const gameRoomID = ref(null);
      const comparisonResults = ref({});
      const token = localStorage.getItem('token');
  
      const getResultsCountdown = ref(10);
      const comparisonCountdown = ref(15);
      const activeContent = ref('yours');
      const loading = ref(true);
      const isGetResultsDisabled = ref(false);
      const isComparisonDisabled = ref(false);
  
      const startGetResultsCountdown = () => {
        const interval = setInterval(() => {
          getResultsCountdown.value--;
          if (getResultsCountdown.value <= 0) {
            clearInterval(interval);
            isGetResultsDisabled.value = false;
          }
        }, 1000);
      };
  
      const startComparisonCountdown = () => {
        comparisonCountdown.value = 15;
        isComparisonDisabled.value = true;
        const interval = setInterval(() => {
          comparisonCountdown.value--;
          if (comparisonCountdown.value <= 0) {
            clearInterval(interval);
            isComparisonDisabled.value = false;
          }
        }, 1000);
      };
  
      const fetchComparison = async () => {
        try {
          const response = await axios.get(`${backendUrl.value}/compare_answers/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          comparisonResults.value = response.data;
          startComparisonCountdown();
        } catch (error) {
          console.error('Error fetching comparison data:', error);
          comparisonResults.value = {};
        }
      };
  
      const getGameRoomID = async () => {
        try {
          const response = await axios.get(`${backendUrl.value}/get_game_room_ID/`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          return response.data.game_room_ID;
        } catch (error) {
          console.error('Error fetching game room ID:', error);
          return null;
        }
      };
  
      onMounted(async () => {
        loading.value = true;
        gameRoomID.value = await getGameRoomID();
        if (!gameRoomID.value) {
          console.error('No game room ID provided.');
        }
        loading.value = false;
        startGetResultsCountdown();
      });
  
      return {
        comparisonResults,
        getResultsCountdown,
        comparisonCountdown,
        activeContent,
        loading,
        isGetResultsDisabled,
        isComparisonDisabled,
        fetchComparison,
        toggleContent(content) {
          activeContent.value = content;
        }
      };
    },
  };
  </script>
  
  <style scoped>
  .dynamic-panel-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .dynamic-panel-container {
    width: 400px;
    height: 520px;
  }
  
  .dynamic-panel-window {
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-radius: 8px;
  }
  
  .dynamic-panel-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    gap: 16px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }
  </style>
  