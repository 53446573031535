<template>
    <div class="bg-gray-100 min-h-screen"> 
      <div class="flex flex-col items-center justify-center" style="min-height: calc(70vh - 4rem);">
        <div v-if="!emailVerified" class="max-w-md w-full mx-auto mt-2">
          <div class="px-4 py-5 sm:px-6 text-center">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Sign In with Google</h3>
          </div>
          <div class="border-t border-gray-200 text-center py-5">
            <div id="g_id_signin"></div>
          </div>
        </div>
        <div v-else class="max-w-md w-full mx-auto mt-2">
          <div class="bg-white overflow-hidden shadow rounded-lg border">
            <div class="px-4 py-5 sm:px-6 text-center">
              <h1 class="text-lg leading-6 font-medium text-gray-900">Email Verification</h1>
            </div>
            <form @submit.prevent="handleSubmit" class="border-t border-gray-200 px-4 py-5 sm:p-6">
              <div class="py-3 sm:py-5 grid gap-4">
                <div class="text-sm font-medium text-gray-500 block text-center">
                  Email:
                  <input type="email" v-model="email" required class="form-input mt-1 block w-3/4 mx-auto text-sm text-gray-900 border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                </div>
                <div class="text-sm font-medium text-gray-500 block text-center">
                  OTP:
                  <input type="text" v-model="otp" required class="form-input mt-1 block w-3/4 mx-auto text-sm text-gray-900 border border-black focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent" />
                </div>
              </div>
              <div v-if="message" class="text-center text-sm font-medium text-red-500 mb-4">{{ message }}</div>
              <div class="flex justify-center">
                <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors">Verify</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div> 
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import axios from 'axios';
  import config from '@/config';
  
  export default {
    setup() {
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const backendUrl = ref(config.apiBaseUrl); 
      const email = ref(route.query.email || '');
      const otp = ref(route.query.otp || '');
      const emailVerified = ref(false);
      const message = ref('');
  
      onMounted(() => {
        if (email.value && otp.value) {
          emailVerified.value = true;
          handleSubmit();
        } else if (window.google) {
          window.google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: handleCredentialResponse,
          });
          window.google.accounts.id.renderButton(
            document.getElementById("g_id_signin"),
            { theme: "outline", size: "large" }
          );
        }
      });
  
      const handleCredentialResponse = (response) => {
        email.value = response.email;
        emailVerified.value = true;
      };
  
      const handleSubmit = async () => {
        try {
          const response = await axios.post(`${backendUrl.value}/registerC1Verify/`, {
            email: email.value,
            number: parseInt(otp.value, 10),
          });
          if (response.data.token) {
            store.dispatch('login', { token: response.data.token });
            message.value = 'Verification successful! You are now logged in.';
            if (response.data.termsofservice_status === "needed") {
              router.push('/TermsOfService');
            } else {
              router.push('/Welcome_Router_Main');
            }
          } else {
            message.value = 'Verification failed. Please check the code and try again.';
          }
        } catch (error) {
          console.error('Error during verification:', error);
          message.value = 'An error occurred during verification. Please try again.';
        }
      };
  
      return { email, otp, handleSubmit, emailVerified, message };
    },
  };
  </script>
  