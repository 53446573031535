<template>
    <div class="bg-white overflow-hidden shadow rounded-lg container mx-auto p-8 max-w-lg">
      <h1 class="text-2xl font-semibold mb-6 text-center">Update Product</h1>
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-4 items-center">
        <input
          v-model="targetProductID"
          type="text"
          id="targetProductID"
          placeholder="Product ID"
          class="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
        />
        <select
          v-model="targetField"
          id="targetField"
          class="mt-1 block w-3/4 pl-3 pr-10 bg-gray-200 py-2 text-gray-700 border-gray-300 rounded-md"
        >
          <option value="product_name">Product Name</option>
          <option value="product_price">Product Price</option>
        </select>
        <input
          v-model="newValue"
          type="text"
          id="newField"
          placeholder="New Value"
          class="mt-1 block w-3/4 px-3 py-2 bg-gray-200 border border-gray-300 rounded-md"
        />
        <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700">
          Update
        </button>
      </form>
      <div v-if="message" class="mt-4 text-center">
        <p>{{ message }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import config from '@/config'; 
  import { ref } from 'vue';
  
  export default {
    setup() {
      const targetProductID = ref('');
      const targetField = ref('email_DB');
      const newValue = ref('');
      const message = ref('');
      const backendUrl = ref(config.apiBaseUrl); 
      console.log("Environment VUE BACKEND URL:", backendUrl.value);

  
      const handleSubmit = async () => {
        try {
          const response = await axios.post(`${backendUrl.value}/update_product/`, {
            product_id: targetProductID.value,
            target_Field: targetField.value,
            new_Value: newValue.value,
          }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          alert("Product updated successfully."); // Consider using Vue to handle messages more gracefully
          window.location.reload(); // Consider using Vue Router for a more SPA-friendly navigation
  
          message.value = response.data.message;
        } catch (error) {
          console.error('Error updating product:', error.response?.data?.detail || 'Unknown error');
          message.value = error.response?.data?.detail || 'An error occurred. Please try again.';
        }
      };
  
      return {
        targetProductID,
        targetField,
        newValue,
        message,
        handleSubmit,
      };
    },
  };
  </script>
  
  <style scoped>
  /* Styles specific to this component */
  </style>
  