<template>
  <div class="flex items-start justify-center min-h-screen pt-20">
    <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
      <h2 class="text-2xl font-bold mb-6 text-center">Change Password</h2>
      <div class="mb-4">
        <input
          type="password"
          placeholder="Current Password"
          v-model="currentPassword"
          class="w-full p-3 border border-gray-300 rounded-lg mb-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
        <input
          type="password"
          placeholder="New Password"
          v-model="newPassword"
          class="w-full p-3 border border-gray-300 rounded-lg mb-3 focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
        <p v-if="message" class="mb-3 text-center text-sm" :class="{'text-green-500': success, 'text-red-500': !success}">
          {{ message }}
        </p>
        <button
          @click="changePassword"
          class="w-full p-3 bg-orange-500 text-white rounded-full hover:bg-orange-600"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config';
import { ref } from 'vue';

export default {
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      message: '',
      success: false
    };
  },
  methods: {
    async changePassword() {
      if (this.newPassword.length < 8) {
        this.message = 'Password must be at least 8 characters long.';
        this.success = false;
        return;
      }
      try {
        const response = await axios.post(`${config.apiBaseUrl}/change_password/`, {
          old_password: this.currentPassword,
          new_password: this.newPassword
        }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        this.message = 'Password changed successfully!';
        this.success = true;
      } catch (error) {
        this.message = 'Failed to change password. Please check your current password and try again.';
        this.success = false;
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}
</style>
