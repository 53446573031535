<template>
  <!-- Container with a maximum width, centered on the page -->
  <div class="max-w-2xl mx-auto mt-10">
    <!-- Card with a shadow effect and rounded borders -->
    <div class="shadow-lg rounded-lg overflow-hidden ">
      <h1 class="section-title text-gray-800 text-center text-3xl mb-10 font-bold">Match Management Map</h1>
      <!-- Table for displaying statuses -->
      <table class="w-full">
        <!-- Table header section -->
        <thead class="bg-gray-100 text-center">
          <tr>
            <th class="py-3 px-4 font-semibold text-gray-600 text-center">Match Name</th>
            <th class="py-3 px-4 font-semibold text-gray-600 text-center">Status</th>
            <th class="py-3 px-4 font-semibold text-gray-600 text-center">Change</th>
            <th class="py-3 px-4 font-semibold text-gray-600 text-center">Date</th>
            <th class="py-3 px-4 font-semibold text-gray-600 text-center">Router</th>
          </tr>
        </thead>

        <tbody>
          


          <tr>
            <td class="py-2 px-4 text-center font-bold font-bold">Must Buddy</td>
            <td :class="getMustBuddyClass">{{ MustBuddyStatus }}</td>

            <td class="text-center"><button class="toggle-switch" @click="toggleMustBuddyStatus" :class="getMustBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">30th 18:45 Türkiye</td>
            <td class="text-center"><router-link to="/Panel_MustSurvey" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>


          <tr>
            <td class="py-2 px-4 text-center font-bold">METU Fest 2024</td>
            <td :class="getMETUFestClass">{{ METUFestStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleMETUFestStatus" :class="getMETUFestClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">30th 20:45</td>
            <td class="text-center"><router-link to="/Panel_METUFest2024Buddy" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>







          <tr>
            <td class="py-2 px-4 text-center font-bold">Study Buddy</td>
            <td :class="getStudyBuddyClass">{{ StudyBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleStudyBuddyStatus" :class="getStudyBuddyClass"> <div class="switch"></div></button>   </td>
            
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>

          
          <tr>
            <td class="py-2 px-4 text-center font-bold">Date Buddy</td>
            <td :class="getDateBuddyClass">{{ DateBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleDateBuddyStatus" :class="getDateBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>

          <tr class="bg-gray-50 text-center">
            <td class="py-2 px-4 text-center font-bold">Coffee Buddy</td>
            <td :class="getCoffeeBuddyClass">{{ CoffeeBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleCoffeeBuddyStatus" :class="getCoffeeBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>


          <tr>
            <td class="py-2 px-4 text-center font-bold">Ezel</td>
            <td :class="getEzelBuddyClass">{{ EzelBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleEzelBuddyStatus" :class="getEzelBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>

          <tr>
            <td class="py-2 px-4 text-center font-bold">Friends</td>
            <td :class="getFriendsBuddyClass">{{ FriendsBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleFriendsBuddyStatus" :class="getFriendsBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>

          <tr>
            <td class="py-2 px-4 text-center font-bold">Leyla İle Mecnun</td>
            <td :class="getLeylaIleMecnunBuddyClass">{{ LeylaIleMecnunBuddyStatus }}</td>
            <td class="text-center"><button class="toggle-switch" @click="toggleLeylaIleMecnunBuddyStatus" :class="getLeylaIleMecnunBuddyClass"> <div class="switch"></div></button>   </td>
            <td class="py-2 px-4 text-center">Collecting Questions</td>
            <td class="text-center"><router-link to="/Management_Map" class="match-manage-manage-button px-3 py-2">Manage</router-link></td> 
          </tr>

        </tbody>

      </table>
      
    </div>
    <div class=" mt-5 flex justify-center">
        <router-link to="/Welcome_Main" custom v-slot="{ navigate }">
          <button class="match-manage-home-button mobile-mt" @click="navigate">
            Home Map
          </button>
        </router-link> 
      </div>
  </div>
</template>

<style>
@media (max-width: 768px) {
  .mobile-mt{
    margin-bottom: 20px;
  }
  .buddy-status-container {
    max-width: 100%; /* Allow the container to use the full width */
    padding: 0 10px; /* Add padding to not touch the screen borders */
  }

  table {
    width: 100%;
  }

  th, td {
    padding: 4px; /* Reduce padding to save space */
    font-size: 0.875rem; /* Reduce font size for better readability on small screens */
  }

  button {
    font-size: 0.75rem; /* Smaller buttons to fit more content */
    padding: 6px 12px; /* Adjust padding to make the buttons easier to tap */
  }

  .home-map {
    padding: 8px; /* Slightly less padding */
  }
}
</style>


<style scoped>
.toggle-switch {
  width: 50px;  /* Width of the outer container */
  height: 25px; /* Height of the outer container */
  background-color: #ccc; /* Default background color */
  border-radius: 25px; /* Rounded edges for a pill shape */
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.switch {
  position: absolute;
  top: 2px; /* Margin from top to center vertically */
  left: 2px; /* Start from left when off */
  width: 21px; /* Width of the switch circle */
  height: 21px; /* Height of the switch circle */
  background-color: white; /* Color of the switch */
  border-radius: 50%; /* Circle shape */
  transition: transform 0.3s ease;
}
.toggle-switch.active {
  background-color: #4CAF50; /* Green background when "on" */
}

.toggle-switch.active .switch {
  transform: translateX(25px); /* Move right when "on" */
}
</style>


<style scoped>
.buddy-status-container {
  width: 100%;
  max-width: 600px;
  margin: auto;
  font-family: Arial, sans-serif;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: center;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f8f8f8;
}

button {
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.active-row {
  font-weight: bold;
}

.home-map {
  background-color: red;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}
</style>





<script>
import axios from 'axios';
import config from '@/config';
import { mapActions } from 'vuex';  // Assuming you might be using Vuex for state management

export default {
  // Data properties to hold the status of each buddy
  data() {
    return {

      MustBuddyStatus: 'Loading...', 
      
      DateBuddyStatus: 'Loading...',
      CoffeeBuddyStatus: 'Loading...',
      StudyBuddyStatus: 'Loading...',


      METUFestStatus: 'Loading...',
      

      EzelBuddyStatus: 'Loading...',
      FriendsBuddyStatus: 'Loading...',
      LeylaIleMecnunBuddyStatus: 'Loading...',
    };
  },
  // Computed properties to dynamically assign CSS classes based on status
  computed: {

    getMustBuddyClass() {
      return this.MustBuddyStatus === 'Active' ? 'active' : 'inactive';
    },


    getStudyBuddyClass() {
      return this.StudyBuddyStatus === 'Active' ? 'active' : 'inactive';
    },
    getDateBuddyClass() {
      return this.DateBuddyStatus === 'Active' ? 'active' : 'inactive';
    },
    getCoffeeBuddyClass() {
      return this.CoffeeBuddyStatus === 'Active' ? 'active' : 'inactive';
    },



    getMETUFestClass() {
      return this.METUFestStatus === 'Active' ? 'active' : 'inactive';
    },



    getEzelBuddyClass() {
      return this.EzelBuddyStatus === 'Active' ? 'active' : 'inactive';
    },   
    getFriendsBuddyClass() {
      return this.FriendsBuddyStatus === 'Active' ? 'active' : 'inactive';
    },   
    getLeylaIleMecnunBuddyClass() {
      return this.LeylaIleMecnunBuddyStatus === 'Active' ? 'active' : 'inactive';
    },
    
  },
  // Methods for fetching and toggling status
  methods: {
    ...mapActions(['fetchStatusFromAPI']), // Example of using Vuex actions, adjust according to actual usage

    // Fetches the initial status for each buddy from the server
    fetchStatus() {
      const endpoints = {

        MustBuddy: `${config.apiBaseUrl}/status_current_MustBuddy/`,


        StudyBuddy: `${config.apiBaseUrl}/status_current_StudyBuddy/`, 
        DateBuddy: `${config.apiBaseUrl}/status_current_DateBuddy/`,   
        CoffeeBuddy: `${config.apiBaseUrl}/status_current_CoffeeBuddy/`,
        METUFest: `${config.apiBaseUrl}/status_current_METUFest2024/`,

        EzelBuddy: `${config.apiBaseUrl}/status_current_EzelBuddy/`,
        FriendsBuddy: `${config.apiBaseUrl}/status_current_FriendsBuddy/`,
        LeylaIleMecnunBuddy: `${config.apiBaseUrl}/status_current_LeylaIleMecnunBuddy/`,
      };

      const token = localStorage.getItem('token');
      const authHeaders = { headers: { Authorization: `Bearer ${token}` } };

      axios.get(endpoints.MustBuddy, authHeaders).then(response => {
        this.MustBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Must Buddy status:', error);
      });





      axios.get(endpoints.StudyBuddy, authHeaders).then(response => {
        this.StudyBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Study Buddy status:', error);
      });

      axios.get(endpoints.DateBuddy, authHeaders).then(response => {
        this.DateBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Coffee Buddy status:', error);
      });

      axios.get(endpoints.CoffeeBuddy, authHeaders).then(response => {
        this.CoffeeBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Coffee Buddy status:', error);
      });

      axios.get(endpoints.METUFest, authHeaders).then(response => {
        this.METUFestStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching METUFest status:', error);
      });





      axios.get(endpoints.EzelBuddy, authHeaders).then(response => {
        this.EzelBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Ezel Buddy status:', error);
      });

      axios.get(endpoints.FriendsBuddy, authHeaders).then(response => {
        this.FriendsBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching Friends Buddy status:', error);
      });

      axios.get(endpoints.LeylaIleMecnunBuddy, authHeaders).then(response => {
        this.LeylaIleMecnunBuddyStatus = response.data.status_DB;
      }).catch(error => {
        console.error('Error fetching LeylaIleMecnun Buddy status:', error);
      });

    },
    // Toggles the status of the specified buddy on the server
    toggleStatus(buddy) {
      const status = this[buddy + 'Status'] === 'Active' ? 'Inactive' : 'Active';
      const endpoint = `${config.apiBaseUrl}/status_update_${buddy}/`;
      const token = localStorage.getItem('token');
      
      axios.put(endpoint, { status }, {
          headers: { Authorization: `Bearer ${token}` }
      }).then(response => {
          // Directly fetches the latest status after updating
          this.fetchStatus();  // Fetches the status of all buddies again to ensure synchronization with the backend
      }).catch(error => {
          console.error(`Error updating ${buddy} status:`, error);
      });
    },

    toggleMustBuddyStatus() {
      this.toggleStatus('MustBuddy');
    },


  

    // Individual toggle methods for each buddy
    toggleStudyBuddyStatus() {
      this.toggleStatus('StudyBuddy');
    },
    toggleDateBuddyStatus() {
      this.toggleStatus('DateBuddy');
    },
    toggleCoffeeBuddyStatus() {
      this.toggleStatus('CoffeeBuddy');
    },



    toggleMETUFestStatus() {
      this.toggleStatus('METUFest2024');
    },



    toggleEzelBuddyStatus() {
      this.toggleStatus('EzelBuddy');
    },   
    toggleFriendsBuddyStatus() {
      this.toggleStatus('FriendsBuddy');
    },
    toggleLeylaIleMecnunBuddyStatus() {
      this.toggleStatus('LeylaIleMecnunBuddy');
    }, 
  },
  // Called after the component is mounted to the DOM
  mounted() {
    this.fetchStatus();  // Initial fetch of the statuses when the component mounts
  }
};
</script>
