<template>
  <div>
    <Sidebar/> 
    <h1 class="activity-match-survey-title mt-5 mb-5">Management Panel</h1>
  
    <div class="activity-match-level-1">
      <div class="activity-match-level-2">
        <div class="activity-match-level-3">
            
          <!-- Container 1-->
          <div v-if="match_current" class="activity-match-level-4">
            <h1 class="activity-match-survey-title">Survey</h1>

            <!-- Centering the button -->
            <div class="activity-match-survey-row">
              <router-link to="/Survey_FriendsBuddy_A" class="buddy-item-survey">
                <img src="@/assets/Panel/survey1.png" alt="Survey 1 Logo" class="segment-image-survey">
                <h2 class="segment-logo-title">Survey A</h2>
              </router-link>

            </div>

            <!-- Display MustSurvey Status -->
            <div class="status-display text-center mt-3">
                <p><strong>Survey Compleation:</strong> {{ FriendsBuddy_Status }}</p>
              </div>

            <h1 class="activity-match-status-title mt-5">Matching</h1>
              <div class="activity-match-level-5">
                <div v-if="match_current.status_DB" class="status-container">
                  <p class="status-text"><strong>Status:</strong> {{ match_current.status_DB }}</p>
                  <button @click="toggleStatus" class="activity-match-toggle-button">Switch</button>
              </div>

              
            </div>
          </div> 
          
          <!-- Container 2-->
          <div class="activity-match-level-4">
            <h2 class="text-center font-bold text-xl">Friends Buddy</h2>
            <div class="buddy-cards-container">
                <!-- METU Fest 2024 -->
                <router-link to="/Panel_FriendsBuddy" class="buddy-item-buddy">
                  <img src="@/assets/Logos/logoSenlikBuddy.png" alt="Friends Buddy Logo" class="segment-image-buddy">
                  
                </router-link>
                </div>
                <div class="buddy-cards-container">
                
                <router-link to="/Help" class="buddy-item">
                  <img src="@/assets/Panel/help.png" alt="Friends Buddy Logo" class="segment-image-buddy">
                  <h2 class="segment-logo-title">Help</h2>
                </router-link>
                <router-link to="/Brief_FriendsBuddy" class="buddy-item">
                  <img src="@/assets/Panel/info.png" alt="Friends Buddy Logo" class="segment-image-buddy">
                  <h2 class="segment-logo-title">Information</h2>
                </router-link>

                
                </div>
          </div> 

        <!-- Container 1-->
        <div class="activity-match-level-4">
              <h1 class="activity-match-status-title">Match When?</h1>
               <div class="buddy-cards-container">

                

                <!-- METU Fest 2024 -->
                <router-link to="/Panel_FriendsBuddy" class="buddy-item-when">
                  <img src="@/assets/Panel/date.png" alt="Friends Buddy Logo" class="segment-image-survey">
                  <h2 class="segment-logo-title text-center">28th of Each Month</h2>
                </router-link>
                <router-link to="/Panel_FriendsBuddy" class="buddy-item-when">
                  <img src="@/assets/Panel/time.png" alt="Friends Buddy Logo" class="segment-image-survey">
                  <h2 class="segment-logo-title text-center">18:45 (Türkiye)</h2>
                </router-link>


                
                

              </div>
        </div> 
        <div class="activity-match-level-4">
            <h1 class="activity-match-status-title">Your Match</h1>
            

            

          <div class="buddy-cards-container">
          <router-link to="/ChatBox_FriendsBuddy" class="buddy-item-chat">
                  <img src="@/assets/Panel/chat.png" alt="Friends Buddy Logo" class="segment-image-chat">
                  <h2 class="segment-logo-title text-xs text-center mt-2">Chat Room</h2>
                </router-link>
                <div v-if="loading2" class="text-sm">
                  Loading...
                </div>
                <div v-else class="text-sm text-center">
                  <p class="text-left"><strong>Match Found Status:</strong> {{ result.match_status }}</p>
                  
              </div>

          </div> 

        </div> 
        </div> 
      </div>
    </div>
  </div>
  </template>
  
  
  
  <script>
  import { ref, onMounted } from 'vue';
  import axios from 'axios';
  import config from '@/config';
  import { useRouter } from 'vue-router';
  
  import Sidebar         from '../Leftside/Sidebar.vue';

  export default {
    data() {
    return {
      FriendsBuddy_Status: 'Loading...', // Initial default status
    };
  },

    methods: {
      
    }, 



    
    setup() {
      const match_current = ref(null);  // Initialize to null
      const loading = ref(true);  // Track loading state
      const backendUrl = ref(config.apiBaseUrl);
      const router = useRouter();
  
      // Route functions
      const goButton1 = () => router.push('/Panel_FriendsBuddy');


      onMounted(async () => {
        try {
          const response = await axios.get(`${backendUrl.value}/status_current_FriendsBuddy/`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          if (response.data && response.data.status_DB) {
            match_current.value = { status_DB: response.data.status_DB };
          } else {
            match_current.value = { status_DB: 'Inactive' };  // Default if undefined
          }
        } catch (error) {
          console.error('Error fetching match_current:', error);
          match_current.value = { status_DB: 'Inactive' };  // Default on error
        } finally {
          loading.value = false;  // Set loading to false regardless of the outcome
        }
      });


      const toggleStatus = async () => {
        try {
          const response = await axios.put(`${backendUrl.value}/status_update_FriendsBuddy/`, {}, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          if (response.data.message === "User updated successfully") {
            match_current.value.status_DB = response.data.new_status;
          }
        } catch (error) {
          console.error('Error updating status:', error);
        }
      };




    // Match Result Fetching 
    const result = ref(null);
    const loading2 = ref(true);


      async function fetchResults() {
      try {
        const url = `${config.apiBaseUrl}/result_FriendsBuddy/`;
        const token = localStorage.getItem('token'); // Fetch the auth token from localStorage
        const headers = {
          'Authorization': `Bearer ${token}` // Use the token for authorization header
        };
        console.log("Fetching results from URL:", url, "with token", token);
        const response = await axios.post(url, {}, { headers: headers });
        result.value = response.data;
        loading2.value = false;
      } catch (error) {
        console.error('There was an error fetching the results:', error);
        alert('Error: ' + (error.response?.data.detail || 'Failed to fetch results'));
        loading2.value = false;
      }
    }




    const FriendsBuddy_Status = ref('Loading...'); // Use ref for reactive data

    const fetchStatus = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('No token found in localStorage');
        return;
      }
      try {
        const response = await axios.get(`${config.apiBaseUrl}/complete_status_current_FriendsBuddy/`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        FriendsBuddy_Status.value = response.data.FriendsBuddy_Status || 'Error2';
      } catch (error) {
        console.error('Error fetching MustSurvey status:', error);
        FriendsBuddy_Status.value = 'Error';
      }
    };


    onMounted(() => {
      fetchResults();
      fetchStatus();  
    });
  
      return { match_current, loading,  toggleStatus, 
        loading2, result, 
        goButton1,
        FriendsBuddy_Status,
      fetchStatus,

    };
    },
  };
  </script>
  



  <style scoped>
  .buddy-cards-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap in new rows */
    gap: 2rem;
    justify-content: center; /* Center cards within the main content area */
  }

  .buddy-item-chat {
    justify-content: center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-chat:hover {
    transform: translateY(-10px);
  }
  .segment-image-chat {
    width: 4rem;
    height: 4rem;
    
    
  }





  .buddy-item {
    justify-content: center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 8rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item:hover {
    transform: translateY(-10px);
  }
  .segment-image {
    width: 4rem;
    height: 4rem;
    margin-top: 20px;
    
  }
  .buddy-item-survey {
    justify-content: center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-survey:hover {
    transform: translateY(-10px);
  }

  .segment-image-survey {
    width: 3rem;
    height: 3rem;
    margin-top: 20px;
    
  }
  
  .segment-logo-title {
    font-size: 1rem;
    font-weight: 700;
  }



  .buddy-item-buddy {
    justify-content: center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 4rem;
    height: 5rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-buddy:hover {
    transform: translateY(-0px);
  }
  .segment-image-buddy {
    width: 4rem;
    height: 4rem;
    margin-top: 20px;
    
  }
  

  .buddy-item-when {
    justify-content: center ;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .buddy-item-when:hover {
    transform: translateY(-10px);
  }


  </style>
  
  <style scoped>
  .activity-match-status-title {
    font-size: 1.5rem; /* Increase font size */
    font-weight: bold; /* Set font weight to bold */
    margin-bottom: 1rem; /* Add a bottom margin of 1rem */
    text-align: center; /* Center text horizontally */
  }
  .activity-match-survey-title {
    font-size: 1.5rem; /* Increase font size */
    font-weight: bold; /* Set font weight to bold */
    text-align: center; /* Center text horizontally */
  }
  
  
  .status-container {
      display: flex;
      justify-content: center; /* Center the items horizontally */
      align-items: center; /* Align items vertically */
      gap: 1rem; /* Spacing between items */
  }
  
  .activity-match-toggle-button {
      background-color: #f97316; /* Orange button */
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 5px; /* Adds a 5px gap on the left of the button */
      display: flex; /* Use flexbox to center the content */
      justify-content: center; /* Center horizontally */
      align-items: center; /* Center vertically */
      text-align: center; /* Center text alignment */
      width: auto; /* Adjust width to content size, or set a fixed width if preferred */
  }
  
  
  .status-text {
      margin-right: 5px; /* Adds a 5px gap on the right */
      font-size: 1rem;
  }
  
  .activity-match-level-5 {
      display: flex;
      justify-content: center; /* Center the grid itself */
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;
  }
  
  
  .activity-match-level-1 {
    display: flex; /* Ensures flexbox layout */
    justify-content: center; /* Centers children horizontally */
    align-items: center; /* Centers children vertically */
    width: 100%; /* Full width to accommoFriends both containers */
    margin-top: 10px;
  }
  
  
  .activity-match-level-2 {
    display: flex; /* Use flex to arrange children */
    flex-direction: row; /* Align children horizontally */
    gap: 20px; /* Space between containers */
    justify-content: center; /* Center the containers horizontally */
    align-items: center; /* Align items vertically */
    width: auto; /* Adjust width based on content */
  }
  .activity-match-level-3 {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    gap: 2rem;
    width: 100%;
    max-width: 1000px; /* Adjust based on the parent container or viewport size */
    margin: 0 auto; /* Center the grid horizontally */
  }
  
  
  .activity-match-level-4 {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
    width: 100%; /* Ensure it fills the grid column */
  }
  
  
  .activity-match-level-4-chat {
    padding: 1.5rem;
    border-radius: 0.75rem;
    width: 100%; /* Ensure it fills the grid column */
  }
  
  
  .activity-match-survey-row {
    display: flex; /* Flex container */
    justify-content: center; /* Center items along the main axis */
    align-items: center; /* Center items along the cross axis */
    gap: 1rem; /* Spacing between items */
  }
  
  
  .activity-match-toggle-button {
      background-color: #f97316; /* bg-orange-500 */
      color: #fff; /* text-white */
      font-weight: bold; /* font-bold */
      border-radius: 1.5rem; /* rounded-3xl */
      /* Hover effect */
      transition: background-color 0.3s;
      font-size: 0.8rem;
      width: 140px;
      height: 30px;
    }
    
    .activity-match-toggle-button:hover {
      background-color: #dd6b20; /* A slightly darker orange for hover effect */
      transform: translateY(-5px);
    }
  
  
  .activity-match-survey-button-final {
      background-color: #f97316; /* bg-orange-500 */
      color: #fff; /* text-white */
      font-weight: bold; /* font-bold */
      border-radius: 1.5rem; /* rounded-3xl */
      /* Hover effect */
      transition: background-color 0.3s;
  
      width: 130px;
      height: 40px;
    }
    
    .activity-match-survey-button-final:hover {
      background-color: #dd6b20; /* A slightly darker orange for hover effect */
      transform: translateY(-5px);
    }
  
  
  </style>
  