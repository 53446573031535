<template>
  <div class="content-container bg-gray-100">
    <Sidebar/>
    <div class="main-content-area bg-gray-100">
      
      
      <div class="segment-main">
        <h1 class="section-title">Study Buddy</h1>
        <div class="buddy-cards-container">

          <!-- METU Fest 2024 -->
          <router-link to="/Chat_Match" class="buddy-item-2">
            <img src="@/assets/logo1.png" alt="Date Buddy Logo" class="segment-image-2">
            <h2 class="segment-logo-title">Chat Room</h2>
          </router-link>

        </div>
      </div>


  <!-- New Flex Container for horizontal arrangement -->
  <div class="flex-row-1 mt-5">
    <!-- Content 2: Remember Container -->
    <div class="card h-full content-grid "> 

      <h1 class="section-title">System</h1> 
      <p class="mb-2 text-center font-bold"> Survey </p> 
      <p class="mb-2 text-center">Answers = Study Buddy Survey</p> 

       <p class="mb-2 text-center font-bold"> Process </p> 
       <p class="mb-2 text-center">Currently not actively matching. We are collecting questions for it. 
       </p> 
      <p class="mb-2 text-left">   </p> 
      <p class="mb-2 text-left">   </p> 
      <div class="flex justify-center">
      <router-link to="/FormQuestion" class="text-center py-2 rounded-3xl text-white bg-orange-500 hover:bg-orange-700 " style="width: 100px; height: 40px;">
          Feedback
      </router-link>
    </div>
    </div>

    <div class="card h-full content-grid "> 

      <h1 class="section-title ">One Person</h1> 
      <p class="mb-2  text-center">Our one-on-one matching system at Senlik Buddy has been continuously refined and developed over the past 1.5 years. 
        Our algorithm has successfully paired over 400 individuals, many of whom have enjoyed relationships lasting several months at least.
        While it is true that we do not employ an image system, we are proud to say that none of our matches have resulted in a wasted date. 
        Your satisfaction is our top priority, so we greatly value your feedback. Please share your experiences with us. 
      </p> 
      <p class="mb-2 text-left">   </p> 
      <p class="mb-2 text-left">   </p> 

      </div>



    <!-- Profile Container -->
    <div class="card h-full content-grid"> 

      <h1 class="section-title">Icebreaker Questions</h1> 

      <p class="text-center">Both of you have three buttons above the chat box and you are free to find question from there.</p>
      <div class="navigation-buttons-sample mb-3 mt-3">
        <button class="icebreaker-button">Previous</button>
        <button class="icebreaker-button-center" >1</button>
        <button class="icebreaker-button">Next</button>
      </div>

      <p class="text-center">Select question, and say other to click the number. Then both of the questions will be scynchronized.</p>

    </div>


  </div>



    </div>
  </div>
</template>




<style scoped>


html, body {
height: 100%;
margin: 0;
padding: 0;
}

.content-container {
display: flex;
flex-direction: row; /* Aligns children (sidebar and main content) in a row */
min-height: 100vh;
width: 100%;
}

.main-content-area {
flex-grow: 1; /* Takes remaining space */
display: flex;
flex-direction: column;
align-items: center;
margin: 0;
padding: 0;
}


</style>

<style scoped>

.segment-main {
max-width: 68rem;
margin: 0 auto; /* Reduced margin */
padding: 1rem;
display: flex;
flex-direction: column;
align-items: center; /* Centers the content vertically */
}

.buddy-cards-container {
display: flex;
flex-wrap: wrap; /* Allows items to wrap in new rows */
gap: 2rem;
justify-content: center; /* Center cards within the main content area */
}

.buddy-item {
display: flex;
flex-direction: column;
align-items: center;
width: 9rem;
height: 10rem;
background-color: #ffffff;
border-radius: 0.5rem;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease-in-out;
}

.buddy-item:hover {
transform: translateY(-10px);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.segment-image {
width: 5rem;
height: 5rem;
margin-top: 20px;

}

.segment-logo-title {
margin-top: 1rem;
font-size: 1rem;
font-weight: 700;
}


.section-title {
font-weight: 700;
font-size: 2.0rem;
text-align: center;
margin-bottom: 1rem;
}

</style>

<style scoped>
.buddy-item-2 {
display: flex;
flex-direction: column;
align-items: center;
width: 11rem;
height: 12rem;
background-color: #ffffff;
border-radius: 0.5rem;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease-in-out;
}

.buddy-item-2:hover {
transform: translateY(-10px);
box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.segment-image-2 {
width: 7rem;
height: 7rem;
margin-top: 20px;

}

</style>




<script>
import Sidebar         from '../Leftside/Sidebar.vue';


export default {
  components: {
    Sidebar
  },
  name: 'NewSegment',
  methods: {
    selectSegment(segment) {
      console.log('Navigating to:', segment);
    }
  }
}
</script>




<style scoped>
.flex {
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-around;
width: 100%; /* Ensures it takes full available width */
}

.flex-row-1 {
display: flex;
justify-content: center; /* Align items at the center */
align-items: center; /* Ensures vertical alignment */
gap: 10px; /* Set a fixed gap between the cards */
width: 100%;
}

.flex-row-2 {
display: flex;
justify-content: center; /* Align items at the center */
align-items: center; /* Ensures vertical alignment */
gap: 90px; /* Set a fixed gap between the cards */
width: 100%;
margin-bottom: 80px;
}

.section-title-main {
font-weight: 700;
font-size: 2.5rem;
text-align: center;
}



.section-title {
font-weight: 700;
font-size: 1.5rem;
text-align: center;
margin-bottom: 1rem;
}

.content-grid {
grid-template-columns: 1fr;
gap: 0rem;
width: 100%;
max-width: 500px; /* Control the max width of content */
}

.card {
background-color: white;
border-radius: 8px;
box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
padding: 20px;
margin: 10px 10px; /* Reduced horizontal margin to bring them closer */
max-width: 400px; /* Control the max width of content */

}

.card.remember {
margin-right: 0px; /* Smaller margin on the right for 'Remember' */
}

.card.profile {
margin-left: 0px;  /* Smaller margin on the left for 'Profile' */
}

.input-group {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: 5px;
}

.segment-button {
width: 150px; /* Example width, adjust as necessary */
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}

.segment-button:hover {
background-color: #b7560c;
transform: translateY(-2px);
}
</style>
