<template>
  <div id="main-wrapper" class="flex flex-col items-center justify-center bg-gray-100">
    <div class="text-center mt-10">
      <h1 class="text-3xl font-bold text-gray-900">Welcome Fest Buddy</h1>
      <h1 class="text-xl font-bold text-gray-900">Dating is not a competition but experience</h1>
      <h1 class="text-xl font-bold text-gray-900">Version = 13 Barcode: 39</h1>
      <h1 class="text-3xl font-bold text-gray-900">My current backend server: {{ backendUrl }}</h1>
      <p class="mt-3 text-lg text-gray-600">This is the starting point of our application.</p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import config from '@/config'; 

export default {
  setup() {
    const backendUrl = ref(config.apiBaseUrl);
    console.log("Environment VUE BACKEND URL:", backendUrl.value);
    
    onMounted(() => {
      document.body.style.backgroundColor = '#f3f4f6';
    });

    return { backendUrl };
  },
};
</script>
